// src/services/annotationApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from './baseQuery'
import { AnnotationDataBoundingBox, AnnotationGetResponse, AnnotationPostResponse } from '../types/annotation'
import { AnnotationStatisticsResp, OkResp, ListAnnotationsResp, QueryAnnotationsResp } from '../types/responses'
import { Filter } from '../types/filter'
import { CreateBBoxAnnotationReq } from '../types/requests'
import { addAnnotatedContentIds } from '../store/slices/annotationSlice'

export const annotationsApi = createApi({
  reducerPath: 'annotationsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Annotation'],

  endpoints: (builder) => ({
    listAnnotations: builder.query<
    ListAnnotationsResp,
    {
      projectId: string
      datasetId: string
      limit?: number
      page?: number
      sortKey?: string
      sortVal?: number
      source?: string[]
    }
    >({
      query: ({ projectId, datasetId, limit, page, sortKey, sortVal, source }) => ({
        url: '/annotations',
        params: {
          project_id: projectId,
          dataset_id: datasetId,
          limit,
          page,
          sort_key: sortKey,
          sort_val: sortVal,
          source,
        },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.annotations.map(({ id }) => ({ type: 'Annotation' as const, id })),
            { type: 'Annotation', id: 'LIST' },
          ]
          : [{ type: 'Annotation', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const contentIds = data.annotations.map((annotation) => annotation.contentid)
          dispatch(addAnnotatedContentIds(contentIds))
        } catch (error) {
          console.error('Error fetching annotations:', error)
        }
      },
    }),

    createAnnotation: builder.mutation<AnnotationPostResponse, CreateBBoxAnnotationReq>({
      query: (annotationData) => {
        const transformedData = {
          ...annotationData,
          metadata: annotationData.metadata && {
            ...annotationData.metadata,
            bounding_boxes: annotationData.metadata.bounding_boxes
              ? transformBoundingBoxes(annotationData.metadata.bounding_boxes)
              : undefined,
          },
        }
        return {
          url: '/annotations',
          method: 'POST',
          body: transformedData,
        }
      },

      invalidatesTags: [{ type: 'Annotation', id: 'LIST' }],

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (data.id) {
            dispatch(addAnnotatedContentIds([data.id]))
            dispatch(
              annotationsApi.util.updateQueryData(
                'listAnnotations',
                { projectId: arg.project_id, datasetId: arg.dataset_id },
                (draft) => {
                  const { tag_id, ...rest } = data
                  const newData: AnnotationGetResponse = {
                    ...rest,
                    tagids: tag_id, // Add tag_id values to tagids
                  }
                  draft.annotations.push(newData)
                },
              ),
            )
          }
        } catch (error) {
          console.error('Error creating annotation:', error)
        }
      },
    }),

    deleteAnnotations: builder.mutation<OkResp, string[]>({
      query: (ids) => ({
        url: '/annotations',
        method: 'DELETE',
        params: { ids },
      }),
      invalidatesTags: (result, error, ids) =>
        ids ? ids.map((id) => ({ type: 'Annotation' as const, id })) : [{ type: 'Annotation', id: 'LIST' }],
    }),

    queryAnnotations: builder.query<
    QueryAnnotationsResp,
    {
      filters: Filter[]
      limit?: number
      operator?: string
      page?: number
      sortKey?: string
      sortVal?: number
    }
    >({
      query: (queryData) => ({
        url: '/annotations/query',
        method: 'POST',
        body: { query: queryData },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.annotations.map(({ id }) => ({ type: 'Annotation' as const, id })),
            { type: 'Annotation', id: 'LIST' },
          ]
          : [{ type: 'Annotation', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          const contentIds = data.annotations.map((annotation) => annotation.contentid)
          dispatch(addAnnotatedContentIds(contentIds))
        } catch (error) {
          console.error('Error fetching annotations:', error)
        }
      },
    }),

    getAnnotationStatistics: builder.query<
    AnnotationStatisticsResp,
    {
      projectId: string
      datasetId: string
      stats?: string
      content_source?: string[]
    }
    >({
      query: ({ projectId, datasetId, stats, content_source }) => ({
        url: '/annotations/statistics',
        params: { project_id: projectId, dataset_id: datasetId, stats, content_source },
      }),
      providesTags: (result, error, ids) => [{ type: 'Annotation', id: 'LIST' }],
    }),

    getAnnotation: builder.query<AnnotationGetResponse, string>({
      query: (id) => `/annotations/${id}`,
      providesTags: (result, error, id) => [{ type: 'Annotation', id }],
    }),

    deleteAnnotation: builder.mutation<OkResp, string>({
      query: (id) => ({
        url: `/annotations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Annotation', id }],
    }),
  }),
})

function transformBoundingBoxes(
  boxes: AnnotationDataBoundingBox[],
): Omit<AnnotationDataBoundingBox, 'class_index' | 'confidence'>[] {
  return boxes.map(({ class_index, confidence, ...rest }) => rest)
}

export const {
  useListAnnotationsQuery,
  useCreateAnnotationMutation,
  useDeleteAnnotationsMutation,
  useQueryAnnotationsQuery,
  useGetAnnotationStatisticsQuery,
  useGetAnnotationQuery,
  useDeleteAnnotationMutation,
} = annotationsApi

export const {
  util: { invalidateTags },
} = annotationsApi
