// src/components/ErrorBoundary.tsx
import React, { ErrorInfo, ReactNode } from 'react'
import { useAuth } from '../hooks/useAuth'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback />
    }

    return this.props.children
  }
}

const ErrorFallback: React.FC = () => {
  const { logoutAndNavigate } = useAuth()

  React.useEffect(() => {
    logoutAndNavigate()
  }, [logoutAndNavigate])

  return <div>An error occurred. Redirecting to login...</div>
}

export default ErrorBoundary
