// src/services/datasetApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import { DatasetSplit, Dataset } from '../types/dataset'
import baseQueryWithReauth from './baseQuery'
import { PROJECTS_URL, DATASETS_URL } from '../utils'
import { FetchDatasetResp } from '../types/responses'

export const datasetApi = createApi({
  reducerPath: 'datasetApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Dataset'],
  endpoints: (builder) => ({
    fetchDataset: builder.query<FetchDatasetResp, string>({
      query: (datasetId) => `${DATASETS_URL}/${datasetId}`,
      providesTags: (result, error, id) => [{ type: 'Dataset', id }],
    }),

    createDataset: builder.mutation<Dataset, { projectId: string; datasetData: Partial<Dataset> }>({
      query: ({ projectId, datasetData }) => ({
        url: `${PROJECTS_URL}/${projectId}/datasets`,
        method: 'POST',
        body: datasetData,
      }),
      invalidatesTags: ['Dataset'],
    }),

    updateDataset: builder.mutation<FetchDatasetResp, { datasetId: string; datasetData: DatasetSplit }>({
      query: ({ datasetId, datasetData }) => ({
        url: `${DATASETS_URL}/${datasetId}`,
        method: 'PATCH',
        body: { split: datasetData },
      }),
      invalidatesTags: (result, error, { datasetId }) => [{ type: 'Dataset', id: datasetId }],
    }),

    deleteDataset: builder.mutation<void, { projectId: string; datasetId: string }>({
      query: ({ projectId, datasetId }) => ({
        url: `${PROJECTS_URL}/${projectId}/datasets/${datasetId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { datasetId }) => [{ type: 'Dataset', id: datasetId }],
    }),
  }),
})

export const { useFetchDatasetQuery, useCreateDatasetMutation, useUpdateDatasetMutation, useDeleteDatasetMutation } =
  datasetApi
