// src/components/TagPills/TagPills.tsx

import React from 'react'
import { AnnotationDataBoundingBox } from '../types/annotation'
import { getContrastYIQ } from '../utils'
import { useGetTagsQuery } from '../services/tagApi'
import { useAppSelector } from '../store/hooks'
import { selectSelectedProject } from '../store/slices/projectSlice'

interface BBoxPillsProps {
  bounding_boxes: AnnotationDataBoundingBox[]
  showTagCounts?: boolean
  showConfidence?: boolean
}

const BBoxPills: React.FC<BBoxPillsProps> = ({ bounding_boxes, showTagCounts = true, showConfidence = false }) => {
  const project = useAppSelector(selectSelectedProject)
  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const tagCounts = bounding_boxes?.reduce((acc, bbox) => {
    acc[bbox.name] = (acc[bbox.name] || 0) + 1
    return acc
  }, {} as Record<string, number>)

  const uniqueTags = Array.from(new Set(bounding_boxes?.map((bbox) => bbox.name)))

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
      {uniqueTags
        .sort((t1, t2) => (tagCounts?.[t2] || 0) - (tagCounts?.[t1] || 0))
        .map((tagId, index) => {
          const tag = tags?.tags.find((t) => t.id === tagId || t.name === tagId)
          if (!tag) return null

          const backgroundColor = `#${tag.color.toString(16).padStart(6, '0').slice(0, 6)}`
          const textColor = getContrastYIQ(backgroundColor)

          return (
            <div key={`${tag.id}-${index}`}>
              <div
                key={index}
                style={{
                  userSelect: 'none',
                  backgroundColor,
                  color: textColor,
                  padding: '0.25rem 0.5rem',
                  height: '1.2rem',
                  borderRadius: '9999px',
                  fontSize: '0.75rem',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span key={`tag-${index}`}>{tag.name}</span>
                {showTagCounts && tagCounts?.[tagId] && (
                  <span
                    key={`span-${index}`}
                    style={{
                      userSelect: 'none',
                      backgroundColor: textColor,
                      color: backgroundColor,
                      borderRadius: '50%',
                      width: '1.25rem',
                      height: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: '0.25rem',
                      fontSize: '0.7rem',
                    }}
                  >
                    {tagCounts[tagId]}
                  </span>
                )}
              </div>
              <div
                key={`div-${index}`}
                className="text-white small"
                style={{
                  userSelect: 'none',
                }}
              >
                {showConfidence && bounding_boxes?.[index]?.confidence && (
                  <>{parseFloat(bounding_boxes?.[index]?.confidence?.toFixed(2) || '') * 100}%</>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default BBoxPills
