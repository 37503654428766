import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import LOGO_IMAGE from '../assets/images/logo-transparent-blue-small.svg'

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="main-container">
      <Navbar fixed="top" bg="dark">
        <Container>
          <Navbar.Brand href="/">
            <img src={LOGO_IMAGE} className="header-logo" alt="React Bootstrap logo" />
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container className="static-content">
        <h4>AGILEVIEW INC PRIVACY POLICY</h4>
        <p>Last modified 10.20.2023</p>
        <p className="noindent">
          Your privacy is important to us. This Privacy Statement explains how AgileView Inc. and our subsidiaries
          (“AgileView” or “we”) collect, use, and share information about you via our websites and in the course of
          providing our products and services, and the rights and choices that may be available to you relating to your
          personal information.
        </p>
        <p className="noindent">
          Please read this Privacy Statement carefully to understand our policies and practices regarding your
          information and how we will treat it. By accessing or using any of our websites or services (including email
          communications), you agree to this Privacy Statement. This Privacy Statement may change from time to time, and
          these changes may affect how we use the information described below, so please check the Privacy Statement
          periodically for updates.
        </p>

        <h5>THE INFORMATION WE COLLECT</h5>
        <p className="noindent">
          “Personal information” is information that identifies, relates to, describes, is reasonably capable of being
          associated with, or could reasonably be linked, directly or indirectly, with you. Personal information does
          not include information that is publicly available, de-identified, or aggregated.
        </p>

        <h6>Sources of Information.</h6>
        <p className="noindent">
          We collect your personal information through various means, including when you directly provide information to
          us, automatically through your use of our website, and via other online and offline means. In some instances,
          you may be able to choose what information to provide, but sometimes we require certain information from you
          to use features of our website. We collect your personal information through various means, including when you
          directly provide information to us, automatically through your use of our website, and via other online and
          offline means. In some instances, you may be able to choose what information to provide, but sometimes we
          require certain information from you to use features of our website.
        </p>

        <p className="noindent">Information we obtain directly from you, as when you:</p>
        <ul>
          <li>Visit or interact with our websites</li>
          <li>Request information about our products and services</li>
          <li>Contact us on one of our social media platforms</li>
          <li>Purchase and use our products and services</li>
          <li>Register an account with us</li>
          <li>Make payments to us</li>
          <li>Participate in our promotions or agree to receive marketing offers</li>
          <li>Complete our web forms</li>
          <li>Exchange communications with us by mail, email, phone or other electronic means</li>
          <li>Post comments to our online communities</li>
          <li>Obtain technical support or customer service</li>
          <li>Visit one of our offices and are asked to provide information for security reasons</li>
        </ul>

        <p className="noindent">Information we obtain from third parties, which will vary over time and may include:</p>
        <ul>
          <li>
            Our resellers and other partners with which we offer co-branded products or services or engage in joint
            marketing
          </li>
          <li>Our service providers for the online services and our products and services</li>
          <li>Ad networks and other advertising and marketing partners</li>
          <li>Information we obtain or exchange through third party sites and platforms</li>
          <li>
            Service providers we use to process your personal information on our behalf, such as IT service providers,
            payment processors and data analytics services
          </li>
          <li>Sponsors or event managers of events for which you register</li>
          <li>Publicly available sources, including government databases</li>
        </ul>

        <p className="noindent">
          Information we collect automatically using cookies, pixels, web beacons, HTTP referrers and other tracking
          technologies, including website analytics, as further detailed in the “OUR USE OF TRACKING TECHNOLOGIES”
          section of this Privacy Statement.
        </p>

        <p className="noindent">
          Other general business-related information that we may receive from or about you in the ordinary course of our
          business.
        </p>

        <h6>Information We Collect.</h6>
        <p className="noindent">
          Through those various sources of information, we may collect multiple categories of personal information about
          you, such as:
        </p>
        <ul>
          <li>Contact details such as name, email address, billing address and phone numbers</li>
          <li>Employment information such as your job title, business address and other business information</li>
          <li>Unique identifiers such as usernames, passwords and other authentication information</li>
          <li>Demographic information such as gender, date of birth, preferences and interests</li>
          <li>Account information such as purchase history and other account activities</li>
          <li>
            Online activity information relating to your use of our online services, and information you may submit to
            us online such as job applicant data
          </li>
          <li>Location information such as your approximate location based on IP address</li>
          <li>
            Device and usage information, such as browser and device type, unique identifiers, URLs, access times and
            other usage, viewing, technical and device data
          </li>
          <li>
            Inferences drawn from the above, such as your preferences, characteristics, predispositions, behavior, or
            attitudes based on other personal information collected about you
          </li>
        </ul>

        <p className="noindent">
          In the ordinary course of our business, we also collect many types of information that are not considered
          “personal information” under relevant law, either because the information relates to an entity or
          organization, is non-identifying, or is aggregated, de-identified or publicly available. We may use these
          types of information for any purpose.
        </p>

        <h5>HOW WE USE AND SHARE YOUR INFORMATION</h5>
        <p className="noindent">
          We may use and share your personal information for the purposes described in this Privacy Statement,
          including:
        </p>
        <ul>
          <li>To present our websites, products and services to you and continuously improve them.</li>
          <li>
            To provide you with information, products or services that you request from us, or to market our products or
            services to you
          </li>
          <li>
            To notify you about changes to our website, products or services (including new offerings) and promotions or
            events
          </li>
          <li>To maintain the integrity and security of our websites, products, and services.</li>
          <li>
            For our ordinary business operations, including human resources, recruiting and business research and
            outreach
          </li>
          <li>To prevent fraud and other illegal activity</li>
          <li>As necessary in connection with other lawful business purposes</li>
          <li>As otherwise necessary when required or permitted by law.</li>
        </ul>

        <p className="noindent">
          We do not sell your personal information to third parties and we will not share your personal information with
          third parties except in limited circumstances consistent with this Privacy Statement, including with respect
          to:
        </p>

        <h6>Partners and Service Providers:</h6>
        <p className="noindent">
          We may employ other companies and people to perform tasks on our behalf and need to share your information
          with them, including service providers and business partners such as resellers who assist us in providing our
          online services and our products and services. They are prohibited from using your personal information for
          purposes other than those requested by us or as required by law.
        </p>

        <h6>Cross Border Transfers:</h6>
        <p className="noindent">
          To facilitate our operations, your personal information may be transferred, stored and processed in any
          jurisdiction where we have operations or where we engage service providers, including in countries other than
          the one in which the personal information was first collected. As a result, your personal information may be
          subject to privacy laws that are different from those in your country of residence or place of business.
          However, we take measures to ensure that any such transfers comply with applicable data protection laws and
          that your personal information remains protected to the standards described in this Privacy Statement.
        </p>

        <h6>Legal Proceedings:</h6>
        <p className="noindent">
          We may share your personal information with any law enforcement, regulatory, or government agency requesting
          personal information in connection with any inquiry, subpoena, court order, or other legal or regulatory
          procedures, with which we would need to comply. We may also share personal information to enforce our Terms of
          Use and to otherwise establish or protect our legal rights, property, or safety, or the rights, property, or
          safety of others, or to defend against legal claims.
        </p>

        <h6>Anonymized Data:</h6>
        <p className="noindent">
          We may share non-identifiable or aggregate information with third parties for lawful purposes. Aggregate
          information is anonymous and may include demographic and usage information.
        </p>

        <h6>Business Transfers:</h6>
        <p className="noindent">
          We may disclose or transfer your personal information to a third party in connection with any change of
          control, reorganization, restructuring, merger, acquisition, assignment, transfer or other disposition of all
          or part of our business.
        </p>

        <h6>Professional Advisors:</h6>
        <p className="noindent">
          We may share information with our insurers and professional advisers for the purposes of managing risks,
          exercising or defending against legal claims and obtaining professional services.
        </p>

        <h5>OUR USE OF TRACKING TECHNOLOGIES</h5>
        <p className="noindent">
          We, our service providers and other third parties use cookies and other widely adopted online tracking
          technologies—such as web beacons, local stored objects, tags and scripts—in connection with our online
          services, advertisements and email campaigns. We may also combine the information we collect through these
          tracking technologies with other personal information we have collected about you.
        </p>

        <p className="noindent">
          Cookies are small files that a website or its service provider transfers to a computer’s hard drive through a
          web browser that enable the site’s or service provider’s systems to recognize the user’s browser and capture
          and remember certain information. In general, we and our service providers use cookies and other tracking
          technologies (such as web beacons, tracking pixels, and HTTP referrers) to optimize the functionality of our
          website, to help us understand how our website is used, and to provide users with interest-based content or
          advertising based on their browsing activities. If you do not want to have cookies placed on your device, you
          should set your browser to refuse cookies before accessing our website. Please review your browser’s Help menu
          for instructions or visit www.allaboutcookies.org for more information on disabling and deleting cookies.
          Please note that, if you turn cookies off, some features of our website may not function properly. More
          information on how we use cookies can be found in our Cookies Policy.
        </p>

        <p className="noindent">We use tracking technologies for many purposes, including to:</p>
        <ul>
          <li>Help us better understand how our visitors engage with our online services</li>
          <li>Provide a better user experience</li>
          <li>Deliver relevant content and advertising based on your preferences, usage patterns and location</li>
          <li>Gather usage and performance data</li>
          <li>
            Help track whether our communications are reaching you, to measure their effectiveness and to allow us to
            better design future communications to you
          </li>
        </ul>

        <p className="noindent">By way of example, we use these kinds of tracking technologies in connection with:</p>
        <ul>
          <li>
            <strong>Data Analytics:</strong> We may use different analytics services from time to time such as Google
            Analytics that help us collect non-personally identifiable information about how our online services are
            being used. This software may collect information such as your IP address, location, age and gender, browser
            type and language, operating system, access time, duration of visit, pages you view within our website,
            search terms you enter, and other actions you take while visiting us, the pages you view immediately before
            and after you access our website, how often you use our website, aggregated usage and performance data,
            application errors, and debugging information. Google Analytics is owned and controlled by Google LLC. Data
            collected by Google is subject to its privacy policy. To opt of our Google Analytics.
          </li>
          <li>
            <strong>Targeted Advertising:</strong> Our advertising partners use tracking technologies to help us to
            manage our advertising on our online services and on third party sites, display personalized content and
            appropriate advertising, and measure their performance. For example, we can count the number of individuals
            who visit our website from a particular advertisement.
          </li>
          <li>
            <strong>Email Campaigns:</strong> We may use tracking technologies to measure the effectiveness of our email
            campaigns, such as by counting the number of individuals who open or act upon an email message, determining
            when an email message is opened, and determining how many times an email message is forwarded.
          </li>
        </ul>

        <p className="noindent">
          For information about interest-based advertising, visit the Digital Advertising Alliance (youradchoices.com)
          and the Network Advertising Initiative (networkadvertising.org). You can adjust your device settings to opt
          out of interest-based advertising or you may opt out by hitting on this button. Please note these actions do
          not opt you out of all advertising and you will continue to receive generic ads. You can use your browser
          controls to opt out of social media platform ad tracking and analytics.
        </p>

        <p className="noindent">
          Please note these opt-out tools may be limited to the device and web browser you are using to the extent the
          opt out is based on your device ID or browser ID. If you use another device or browser, you may need to opt
          out on each device and browser. If you block or clear cookies from your browser, it may remove the opt out
          setting, requiring you to opt out again.
        </p>

        <p className="noindent">
          We do not currently have the technology to automatically respond to “Do Not Track” (DNT) signals sent by web
          browsers, mobile devices, or other mechanisms.
        </p>

        <h5>MANAGING YOUR INFORMATION</h5>
        <p className="noindent">We may retain your personal information for the periods necessary to:</p>
        <ul>
          <li>fulfill the purposes described in this Privacy Statement</li>
          <li>meet the timelines determined or recommended by regulators, professional bodies, or associations</li>
          <li>
            comply with applicable laws, legal holds, and other legal obligations, including contractual obligations
          </li>
          <li>comply with your requests</li>
          <li>resolve disputes</li>
          <li>enforce our rights</li>
        </ul>

        <p className="noindent">
          Consistent with applicable law, you may exercise certain choices and controls regarding our personal
          information, which may include:
        </p>
        <ul>
          <li>
            Whenever you are asked to fill in a form on our online services, look for the box that you can click to
            indicate that you do not want to receive offers and promotions from us or from other companies that we think
            might interest you.
          </li>
          <li>
            You may unsubscribe from our marketing email communications by clicking the “unsubscribe” link found in
            every commercial email we send, or by sending us a request to unsubscribe to privacy@AgileView.com. If you
            opt-out of our marketing email communications, we may still send you email messages related to specific
            transactions, or products or services you request. Unsubscribing from our marketing communications will not
            affect the level or quality of service we provide to you.
          </li>
          <li>
            You may exercise your options relating to tracking technologies, interest-based advertising and social media
            platform ad tracking and analytics set forth in the preceding “OUR USE OF TRACKING TECHNOLOGIES” section of
            this Privacy Statement.
          </li>
          <li>
            If you believe that specific information we hold about you is incorrect or incomplete, please email us at
            privacy@AgileView.com and we will make the necessary corrections, which may take several business days to
            take effect.
          </li>
        </ul>

        <p className="noindent">
          Please be aware that in some instances we cannot delete all or some of your personal information as required
          or permitted by applicable laws.
        </p>

        <h5>SECURING YOUR DATA</h5>
        <p className="noindent">
          We are committed to ensuring that your personal information is secure. We follow generally accepted industry
          standards and have put in place reasonable administrative, technical and physical security measures that are
          designed to safeguard personal information against unauthorized access, use, disclosure, destruction or
          alteration. We regularly review our security procedures to consider appropriate new technologies and methods.
          However, no method of transmission over the Internet, and no method of storing electronic information, can be
          100% secure, and we cannot guarantee the security of your transmissions to us and of the personal information
          we store.
        </p>

        <h5>LINKS AND SOCIAL MEDIA</h5>
        <p className="noindent">
          Our online services may contain links to third party sites, and once you leave our website we do not have any
          control over those sites and are not responsible for their privacy practices. Please be aware when you leave
          our online services and review carefully each third party site’s privacy practices.
        </p>
        <p className="noindent">
          If you contact us on one of our social media platforms, request customer service via social media, or
          otherwise direct us to communicate with you via social media, we may contact you via direct message or use
          other social media tools to interact with you. In those instances, your interactions with us are governed by
          this Privacy Statement as well as the privacy policy of the social media platform you use.
        </p>

        <h5>CHILDREN UNDER AGE 16</h5>
        <p className="noindent">
          Our website is neither intended for, nor targeted to those under age 16, and we do not knowingly collect
          personal information from children under the age of 16. If you are under the age of 16, please do not provide
          us with any personal information. If we determine an individual under the age of 16 submitted personal
          information to us, we will delete it. If you are a parent or guardian and you believe that your child under
          the age of 16 has provided us with personal information, please contact us.
        </p>

        <h5>CALIFORNIA PRIVACY RIGHTS</h5>
        <p className="noindent">
          This section provides additional information regarding the California Consumer Privacy Act of 2018 (the
          “CCPA”) and supplements the disclosures and information throughout this Privacy Statement. The commitments in
          this section apply only to individuals covered by the CCPA, and are subject to certain statutory exceptions
          laid out in the CCPA itself.
        </p>
        <p className="noindent">
          The personal information we generally collect, use, and disclose regarding California residents, as well as
          the categories of sources of such information, is described above in the sections, “THE INFORMATION WE
          COLLECT” and “HOW WE USE AND SHARE YOUR INFORMATION”. We do not sell California residents’ personal
          information (and will not do so without presenting an opportunity to opt-out, as would be required under the
          CCPA). Any disclosures of such information to third parties over the preceding 12 months have been in
          accordance with the permitted categories in the “HOW WE USE AND SHARE YOUR INFORMATION” section above. We also
          do not sell the personal information of minors under 16 years of age, and if it ever did would require
          affirmative authorization to do so.
        </p>
        <p className="noindent">
          The CCPA creates a right for covered individuals to request that a company disclose what, if any, personal
          information that company collects, uses, discloses, and sells about them specifically, or to request the
          deletion of personal information the company has collected or maintained about them. If you believe you have
          such a right under the CCPA and would like to exercise that right with respect to AgileView, please submit an
          email request to privacy@AgileView.com with “Request to Know” or “Request to Delete” in the subject line. For
          a “Request to Know,” please specify what details you would like to know, including any specific pieces of
          personal information you would like to access. For a “Request to Delete,” please specify what information you
          would like to have deleted (which may be all your information as required by the CCPA). If you submit a
          request, we may ask for information necessary to verify your identity and eligibility to assert this right,
          and we will respond to verified requests in accordance with the CCPA. You may also utilize an authorized agent
          to exercise your CCPA rights on your behalf. You may not be discriminated against because you exercise any of
          your rights under the CCPA, and AgileView is committed to this principle.
        </p>

        <h5>DATA SUBJECT RIGHTS UNDER GDPR</h5>
        <p className="noindent">
          Individuals in the United Kingdom, the European Union and the European Economic Area have the following rights
          in relation to their personal information:
        </p>
        <ul>
          <li>
            <strong>Right of Access</strong> – the right to be informed of and request access to the personal
            information we process about you
          </li>
          <li>
            <strong>Right to Rectification</strong> – the right to request that we amend or update your personal
            information where it is inaccurate or incomplete
          </li>
          <li>
            <strong>Right to Erasure</strong> – the right to request the deletion of your personal information
          </li>
          <li>
            <strong>Right to Restrict Processing</strong> – the right to request that we temporarily or permanently stop
            processing some or all of your personal information
          </li>
          <li>
            <strong>Right to Data Portability</strong> – the right to request your personal information in a structured,
            commonly used and machine-readable format for use in another party’s service
          </li>
          <li>
            <strong>Right to Object</strong> – the right to object to our receipt and processing of your personal
            information under certain conditions. If you are dissatisfied with the manner in which we have used or
            processed your personal information, please contact us as provided below. You also have the right to lodge a
            complaint with the appropriate Data Protection Authority, which may be found{' '}
            <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">
              here
            </a>
            .
          </li>
          <li>
            <strong>Right to Withdraw Consent</strong> – the right to withdraw your consent to our use of your personal
            information. If you withdraw your consent, we may not be able to provide you with access to certain
            functionality of our products, services, and website.
          </li>
          <li>
            <strong>Right not to be Subject to Automated Decision-Making</strong> – the right to not be subject to a
            decision based solely on automated decision-making, including profiling, where the decision would have a
            legal effect on you or produce a similarly significant effect
          </li>
        </ul>

        <p className="noindent">
          If you wish to exercise any of the foregoing rights, please contact us as provided below. We may need to
          confirm your identity before we can provide you with the personal information that we hold about you or make
          your requested changes. We have 30 days to respond to your request.
        </p>

        <p className="noindent">
          Depending on the circumstances and the nature of your request it may not be possible for us to do what you
          have asked—for example, where there is a statutory or contractual requirement for us to process your personal
          information and it would not be possible to fulfill our legal obligations if we were to stop. Applicable law
          may allow or require us to refuse to provide you with access to some or all of the personal information that
          we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance
          with our record retention obligations and practices. If we cannot provide you with access to your personal
          information, we will inform you of the reasons why, subject to any legal or regulatory restrictions.
        </p>

        <h5>CONTACT US</h5>
        <p className="noindent">
          For any questions, comments, or concerns about this Privacy Statement, please contact us at{' '}
          <a href="mailto:privacy@agileview.ai">privacy@agileview.ai</a> or write to us at:
        </p>

        <p className="noindent">
          AgileView Inc.
          <br />
          Attention: Legal Department— Privacy Rights
          <br />
          420 Lexington Avenue, Suite 300
          <br />
          New York, NY 10170
          <br />
          United States
        </p>

        <p className="noindent">
          This Privacy Statement is designed to be accessible to everyone, but if you have any difficulties accessing it
          in the format in which it is presented, please let us know.
        </p>

        <h5>CHANGES TO THIS PRIVACY STATEMENT</h5>
        <p className="noindent">
          From time to time, AgileView may amend or modify this Privacy Statement. We will post the updated Privacy
          Statement on our website and so encourage you to regularly review it to learn of any updates.
        </p>

        <p className="noindent">
          On October 13, 2023, we changed our Privacy Statement to, among other things, clarify the types of information
          we collect about you, the ways we collect your information through our website, how we use and share your
          information, and the rights of California residents under the CCPA and EU/UK persons under the GDPR. We also
          re-formatted our Privacy Statement to make it easier to read, and better enable you to find the information
          you are looking for.
        </p>
      </Container>
    </div>
  )
}

export default PrivacyPolicy
