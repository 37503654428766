// src/store/slices/datasetSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { UploadProjectResp } from '../../types/responses'
import { FileInfo } from '../../types/file'
import { Dataset } from '../../types/dataset'

export interface DatasetState {
  uploadStats: UploadProjectResp
  aggregateStats: UploadProjectResp
  selectedFiles: FileInfo[]
  selectedTiffFiles: FileInfo[]
  uploading: boolean
  uploaded: boolean
  progress: number
  error: string | null
  labelsFile: FileInfo | null
  // datasetSources: string[]
  selectedDatasetSource: string | undefined
}

const initialState: DatasetState = {
  aggregateStats: {
    errors: [],
    labels_file: '',
    total_bytes: 0,
    total_files: 0,
    total_files_duplicate: 0,
    total_files_failed: 0,
    total_files_succeeded: 0,
  },
  uploadStats: {
    errors: [],
    labels_file: '',
    total_bytes: 0,
    total_files: 0,
    total_files_duplicate: 0,
    total_files_failed: 0,
    total_files_succeeded: 0,
  },
  selectedFiles: [],
  selectedTiffFiles: [],
  uploading: false,
  uploaded: false,
  progress: 0,
  error: null,
  labelsFile: null,
  // datasetSources: [],
  selectedDatasetSource: undefined,
}

const datasetSlice = createSlice({
  name: 'dataset',
  initialState,
  reducers: {
    setSelectedDatasetSource(state, action: PayloadAction<string>) {
      state.selectedDatasetSource = action.payload
    },

    clearSelectedDatasetSource(state) {
      state.selectedDatasetSource = undefined
    },

    setLabelsFile(state, action: PayloadAction<FileInfo>) {
      state.labelsFile = action.payload
    },

    clearLabelsFile(state) {
      state.labelsFile = null
    },

    setSelectedFiles(state, action: PayloadAction<FileInfo[]>) {
      state.selectedFiles = action.payload
    },

    clearSelectedFiles(state) {
      state.selectedFiles = []
    },

    setSelectedTiffFiles(state, action: PayloadAction<FileInfo[]>) {
      state.selectedTiffFiles = action.payload
    },

    clearSelectedTiffFiles(state) {
      state.selectedTiffFiles = []
    },

    setFilesUploading(state, action: PayloadAction<boolean>) {
      state.uploading = action.payload
    },

    setFilesUploaded(state, action: PayloadAction<boolean>) {
      state.uploaded = action.payload
    },

    setProgress(state, action: PayloadAction<number>) {
      state.progress = action.payload
    },

    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload
    },

    updateDatasetStats(state, action: PayloadAction<UploadProjectResp>) {
      state.uploadStats = action.payload
      // Update aggregate stats
      state.aggregateStats.total_bytes += action.payload.total_bytes
      state.aggregateStats.total_files += action.payload.total_files - (action.payload.labels_file ? 1 : 0)
      state.aggregateStats.total_files_duplicate += action.payload.total_files_duplicate
      state.aggregateStats.total_files_failed += action.payload.total_files_failed
      state.aggregateStats.total_files_succeeded += action.payload.total_files_succeeded
      state.aggregateStats.errors = [...state.aggregateStats.errors, ...action.payload.errors]
      // Keep the most recent labels file
      if (action.payload.labels_file) {
        state.aggregateStats.labels_file = action.payload.labels_file
      }
    },

    resetUploadStats(state) {
      state.uploadStats = { ...initialState.uploadStats }
    },

    resetAllStats(state) {
      state.uploadStats = { ...initialState.uploadStats }
      state.aggregateStats = { ...initialState.aggregateStats }
      state.selectedFiles = []
    },
    //
    // setDatasetSources(state, action: PayloadAction<string[]>) {
    //   state.datasetSources = action.payload
    // },
    //
    // clearDatasetSources(state) {
    //   state.datasetSources= []
    // },
    //
    // addDatasetSource(state, action: PayloadAction<string>) {
    //   if (!state.datasetSources.includes(action.payload)) {
    //     state.datasetSources.push(action.payload)
    //   }
    // },
    //
    // removeDatasetSource(state, action: PayloadAction<string>) {
    //   state.datasetSources = state.datasetSources.filter((dataset) => dataset !== action.payload)
    // },
  },
})

export const {
  updateDatasetStats,
  resetUploadStats,
  resetAllStats,
  setSelectedFiles,
  clearSelectedFiles,
  setSelectedTiffFiles,
  clearSelectedTiffFiles,
  setFilesUploading,
  setFilesUploaded,
  setProgress,
  setError,
  setLabelsFile,
  clearLabelsFile,

  // setDatasetSources,
  // clearDatasetSources,
  // addDatasetSource,
  // removeDatasetSource,
  setSelectedDatasetSource,
  clearSelectedDatasetSource,
} = datasetSlice.actions

export const selectUploadStats = (state: RootState) => state.dataset.uploadStats
export const selectAggregateStats = (state: RootState) => state.dataset.aggregateStats
export const selectSelectedFiles = (state: RootState) => state.dataset.selectedFiles
export const selectSelectedTiffFiles = (state: RootState) => state.dataset.selectedTiffFiles
export const selectFilesUploading = (state: RootState) => state.dataset.uploading
export const selectFilesUploaded = (state: RootState) => state.dataset.uploaded
export const selectProgress = (state: RootState) => state.dataset.progress
export const selectError = (state: RootState) => state.dataset.error
export const selectLabelsFile = (state: RootState) => state.dataset.labelsFile
export const selectSelectedDatasetSource = (state: RootState) => state.dataset.selectedDatasetSource

export default datasetSlice.reducer
