import React from 'react'
import { Card } from 'react-bootstrap'
import { useContentImage } from '../../hooks/useContentImage'
import BBoxPills from '../BBoxPills'
import { Content } from '../../types/content'
import { useAppSelector } from '../../store/hooks'
import { selectActiveTab, selectSelectedProject } from '../../store/slices/projectSlice'
import ClassPills from '../ClassPills'

interface ContentImageProps {
  content: Content
  cardSize: number
  onImageClick: (image: Content) => void
}

const ContentImage: React.FC<ContentImageProps> = ({ content, cardSize, onImageClick }) => {
  const project = useAppSelector(selectSelectedProject)

  const { base64Image } = useContentImage(content.id, project?.id || '', project?.datasetid || '')
  const activeTab = useAppSelector(selectActiveTab)

  return (
    <div style={{ padding: '5px' }} onClick={() => onImageClick(content)}>
      <Card
        bg="bg-light"
        text="light"
        style={{ height: `${cardSize}px`, width: `${cardSize}px`, cursor: 'pointer', position: 'relative' }}
      >
        <Card.Img
          variant="top"
          src={base64Image || ''}
          style={{ height: `${cardSize}px`, width: `${cardSize}px`, objectFit: 'cover' }}
        />
        {activeTab === 'annotated' && project?.annotation_type === 'bounding_box' && content?.annotation?.length && (
          <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
            <BBoxPills bounding_boxes={content.annotation?.[0].metadata?.bounding_boxes || []} />
          </div>
        )}
        {activeTab === 'annotated' && project?.annotation_type === 'classification' && content?.annotation?.length && (
          <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
            <ClassPills tagIds={content.annotation?.[0].tagids} />
          </div>
        )}
      </Card>
    </div>
  )
}

export default ContentImage
