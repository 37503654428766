// src/components/FileUploadStats.tsx

import React from 'react'
import { Card } from 'react-bootstrap'
import { useAppSelector } from '../store/hooks'
import { selectAggregateStats } from '../store/slices/datasetSlice'

const FileUploadStats: React.FC = () => {
  // const stats = useAppSelector(selectUploadStats)
  const stats = useAppSelector(selectAggregateStats)

  const statItems = [
    { label: 'Labels File', value: stats.labels_file },
    { label: 'Total Images', value: stats.total_files },
    { label: 'Total Bytes (MB)', value: `${(stats.total_bytes / (1024 * 1024)).toFixed(2)} MB` },
    { label: 'Duplicates', value: stats.total_files_duplicate },
    { label: 'Failed', value: stats.total_files_failed },
    { label: 'Succeeded (+labels.json)', value: stats.total_files_succeeded },
    { label: 'Errors', value: stats.errors.length },
  ]

  return (
    <Card className="file-upload-stats mt-3">
      <Card.Body>
        <h5 className="mb-3">Upload Statistics</h5>
        {statItems.map((item, index) => (
          <div key={index} className="stat-item">
            <span className="stat-label">{item.label}:</span>
            <span className="stat-value">{item.value}</span>
          </div>
        ))}
      </Card.Body>
    </Card>
  )
}

export default FileUploadStats
