// src/components/ProjectDetails/InteractiveArea.tsx

import React from 'react'
import AnnotationTools from '../tools/AnnotationTools'
import FileUploadStats from '../FileUploadStats'
import DatasetTools from '../tools/DatasetTools'
import { Project } from '../../types/project'
import ModelTools from '../tools/ModelTools'
import MiniClassDistributionCard from '../MiniClassDistributionCard'
import PredictionTools from '../tools/PredictionTools'
import { FileInfo } from '../../types/file'
import { selectActiveTab } from '../../store/slices/projectSlice'

import PredictionStatisticsTable from '../PredictionStatisticsTable'
import { useAppSelector } from '../../store/hooks'

interface ToolsAreaProps {
  project: Project | undefined
  activeView: 'project' | 'models' | 'dataset' | 'upload' | 'annotation'
  onLabelsFileSelect: (file: File | null) => void
  labelsFile: File | null
  labelsFileInfo: FileInfo | null
}

const ToolsArea: React.FC<ToolsAreaProps> = ({ project, activeView }) => {
  const activeTab = useAppSelector(selectActiveTab)

  switch (activeView) {
    case 'upload':
      return <FileUploadStats />
    case 'annotation':
      return (
        <>
          <AnnotationTools />
          {project && <PredictionTools />}
          {activeTab !== 'annotated' && activeTab !== 'unannotated' && (
            <div className="mb-3">
              <PredictionStatisticsTable modelId={activeTab} />
            </div>
          )}
          {(activeTab === 'annotated' || activeTab === 'unannotated') && (
            <MiniClassDistributionCard showButtons={false} />
          )}
        </>
      )
    case 'dataset':
      return <DatasetTools></DatasetTools>
    case 'models':
      return <ModelTools />
    default:
      return null
  }
}

export default ToolsArea
