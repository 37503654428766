import React, { Component, ReactNode } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.scss'
import App from './App'
// import { suppressResizeObserverErrors } from './utils/errorSuppression' // Adjust the import path as necessary

// suppressResizeObserverErrors()

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

interface ErrorBoundaryProps {
  children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, error: null }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    if (error.message.startsWith('ResizeObserver loop')) {
      return { hasError: true, error: error }
    }
    return { hasError: false, error: null }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!error.message.startsWith('ResizeObserver loop')) {
      // Log the error to an error reporting service
      console.error(error, errorInfo)
    }
  }

  render() {
    if (this.state.hasError && this.state.error?.message.startsWith('ResizeObserver loop')) {
      // Render a fallback UI for the specific error
      return null // or render any fallback UI
    }

    return this.props.children
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>,
)
