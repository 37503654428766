import React from 'react'
import { Form } from 'react-bootstrap'

interface DatasetSplitSliderProps {
  value: number
  onChange: (value: number) => void
  onChangeComplete: () => void
}

const DatasetSplitSlider: React.FC<DatasetSplitSliderProps> = ({ value, onChange, onChangeComplete }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value))
  }

  const handleMouseUp = () => {
    onChangeComplete()
  }

  return (
    <div className="custom-slider-container">
      <Form.Range
        value={value}
        onChange={handleChange}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp}
        min={0}
        max={100}
        step={1}
      />
      <div className="slider-label training" style={{ left: '0%' }}>
        Train {value}%
      </div>
      <div className="slider-label validation" style={{ right: '0%' }}>
        Validate {100 - value}%
      </div>
    </div>
  )
}

export default DatasetSplitSlider
