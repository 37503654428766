// src/components/OverviewCard.tsx

import React from 'react'
import { Card } from 'react-bootstrap'

interface OverviewCardProps {
  title: string
  value: number
  icon: React.ReactElement
}

const OverviewCard: React.FC<OverviewCardProps> = ({ title, value, icon }) => {
  return (
    <Card
      className="bg-dark text-light border-0 p-2 shadow-sm overview-card me-3"
      style={{ flex: '1 1 0', minWidth: '150px' }}
    >
      <Card.Body className="d-flex align-items-center p-2">
        {icon}
        <div className="text-center flex-grow-1">
          <div style={{ marginBottom: '10px', fontSize: '12px' }}>{title}</div>
          <h3 className="mb-0 fw-bold fs-5">{value}</h3>
        </div>
      </Card.Body>
    </Card>
  )
}

export default OverviewCard
