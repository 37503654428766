import React, { useEffect } from 'react'
import { Card, ListGroup, Spinner } from 'react-bootstrap'
import { useViewContentQuery } from '../../services/contentApi'
import ClassPills from '../ClassPills'
import { useAppSelector } from '../../store/hooks'
import { selectEditingPredictionMetadata } from '../../store/slices/annotationSlice'
import { formatNumber } from '../../utils'
import { selectSelectedProject } from '../../store/slices/projectSlice'

interface MetadataDisplayProps {
  contentId: string | undefined
  handleTagRemove?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ClassificationMetadata: React.FC<MetadataDisplayProps> = ({ contentId, handleTagRemove }) => {
  const project = useAppSelector(selectSelectedProject)
  const editingPredictionMetadata = useAppSelector(selectEditingPredictionMetadata)

  const { data: contentData, isLoading: contentLoading } = useViewContentQuery(
    {
      ContentID: contentId || '',
      project_id: project?.id || '',
      dataset_id: project?.datasetid || '',
    },
    {
      skip: !contentId,
    },
  )

  useEffect(() => {
    console.log('META - editingPredictionMetadata useEffect', editingPredictionMetadata)
  }, [contentId, editingPredictionMetadata, contentData])

  if (contentLoading || !editingPredictionMetadata) {
    return <Spinner animation="border" variant="primary" />
  }

  return (
    <>
      <Card className="metadata-display">
        <Card.Header>
          <h5 className="mb-0">Metadata</h5>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <strong>Total Tags:</strong> <span className="text-muted">{editingPredictionMetadata.length}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Unique Tags:</strong>{' '}
            <span className="text-muted">{new Set(editingPredictionMetadata.map((b) => b.class_name)).size}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Size:</strong> <span className="text-muted">{formatNumber(contentData?.size || 0)} bytes</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>MIME Type:</strong> <span className="text-muted">{contentData?.content_type}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Width:</strong> <span className="text-muted">{contentData?.width || 0}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Height:</strong> <span className="text-muted">{contentData?.height || 0}</span>
          </ListGroup.Item>
        </ListGroup>
        <Card.Header>
          <h5 className="mb-0">Current Labels</h5>
        </Card.Header>
        <ListGroup variant="flush">
          {/* Get labels and display them */}
          <ListGroup.Item>
            {editingPredictionMetadata?.length > 0 ? (
              <ClassPills
                handleTagRemove={handleTagRemove}
                tagNames={editingPredictionMetadata.map((pred) => pred.class_name)}
              />
            ) : (
              <span className="text-muted">No labels</span>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </>
  )
}

export default ClassificationMetadata
