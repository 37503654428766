// src/store/slices/predictionSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Prediction } from '../../types/prediction'
import { RootState } from '../index'
import { Tag } from '../../types/tag'

export interface PredictionState {
  selectedPredictions: Prediction[]
  selectedPredictionTags: Tag[]
}

const initialState: PredictionState = {
  selectedPredictions: [],
  selectedPredictionTags: [],
}

const predictionSlice = createSlice({
  name: 'predictions',
  initialState,

  reducers: {
    setSelectedPredictionTags: (state, action: PayloadAction<Tag[]>) => {
      state.selectedPredictionTags = action.payload
    },

    addSelectedPredictionTag: (state, action: PayloadAction<Tag>) => {
      state.selectedPredictionTags = Array.from(new Set([...state.selectedPredictionTags, action.payload]))
    },

    removeSelectedPredictionTag: (state, action: PayloadAction<Tag>) => {
      state.selectedPredictionTags = [...state.selectedPredictionTags.filter((tag) => tag.id !== action.payload.id)]
    },

    toggleSelectedPredictionTag: (state, action: PayloadAction<Tag>) => {
      if (state.selectedPredictionTags.find((t: Tag) => t.id === action.payload.id)) {
        state.selectedPredictionTags = [...state.selectedPredictionTags.filter((tag) => tag.id !== action.payload.id)]
      } else {
        state.selectedPredictionTags = [...Array.from(new Set([...state.selectedPredictionTags, action.payload]))]
      }
    },

    clearSelectedPredictionTags: (state) => {
      state.selectedPredictionTags = []
    },

    setPredictions: (state, action: PayloadAction<Prediction[]>) => {
      state.selectedPredictions = action.payload
    },

    addPrediction: (state, action: PayloadAction<Prediction>) => {
      state.selectedPredictions.push(action.payload)
    },

    removePrediction: (state, action: PayloadAction<string>) => {
      state.selectedPredictions = state.selectedPredictions.filter((prediction) => prediction.id !== action.payload)
    },

    clearPredictions: (state) => {
      state.selectedPredictions = []
    },
  },
})

export const {
  addPrediction,
  removePrediction,
  setPredictions,
  clearPredictions,
  setSelectedPredictionTags,
  addSelectedPredictionTag,
  removeSelectedPredictionTag,
  clearSelectedPredictionTags,
  toggleSelectedPredictionTag,
} = predictionSlice.actions

export default predictionSlice.reducer

export const selectSelectedPredictions = (state: RootState) => state.prediction.selectedPredictions
export const selectSelectedPredictionTags = (state: RootState) => state.prediction.selectedPredictionTags
