import React, {useState, useCallback, useEffect, useRef} from 'react'
import { Card, Row, Col, Button, Alert } from 'react-bootstrap'
import { Model } from '../types/model'
import { rateBBoxModel } from '../utils'
import StarRating from './StarRating'
import {
  useDeployModelMutation,
  useDeleteModelDeploymentMutation,
  useRunRealtimeInferenceMutation, useGetModelQuery,
} from '../services/modelsApi'
import GaugeMetric from './GaugeMetric'
import ClassDistributionCard from './ClassDistributionCard'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {selectSelectedModel, setSelectedModel} from '../store/slices/modelSlice'
import FileUploader from './FileUploader'
import InferenceResults from './InferenceResults'
import { RunInferenceResponse } from '../types/responses'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const ModelMetricsCard: React.FC = () => {
  const dispatch = useAppDispatch()
  const model = useAppSelector(selectSelectedModel)

  const { refetch: refetchModel } = useGetModelQuery(model?.id || '', { skip: !model })

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)

  const { metrics, train_started_at, train_ended_at, deployment } = model || {}

  // Calculate Train Time using native JavaScript methods
  const trainStart = train_started_at ? new Date(train_started_at) : null
  const trainEnd = train_ended_at ? new Date(train_ended_at) : null

  useEffect(() => {
    const shouldPoll = model?.deployment?.status !== 'TRAINED'
    if (shouldPoll) {
      pollingIntervalRef.current = setInterval(() => {
        refetchModel().then((res) => {
          console.log('res:', res)
          dispatch(setSelectedModel(res.data as Model))
        })
      }, 3000) // Poll every 5 seconds
    } else if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
      }
    }
  }, [dispatch, model, refetchModel])

  const [deployModel] = useDeployModelMutation()


  const [deleteDeployment] = useDeleteModelDeploymentMutation()

  const { bBoxRating } = rateBBoxModel(model || ({} as Model))

  const [dropFiles, setDropFiles] = useState<File[] | null>(null)
  const [inferenceResults, setInferenceResults] = useState<RunInferenceResponse | null>(null)
  const [inferenceErrorMsg, setInferenceError] = useState<string | undefined>(undefined)
  const [runInference, { isLoading: inferenceLoading, isError }] = useRunRealtimeInferenceMutation()
  const [isDeployed, setIsDeployed] = useState<boolean>(false)


  const onFileSelect = useCallback((acceptedFiles: File[]) => {
    setDropFiles(acceptedFiles)
    setInferenceResults(null) // Clear previous results when new files are selected
  }, [])


  const handleInference = useCallback(() => {
    if (dropFiles) {
      const formData = new FormData()
      dropFiles.forEach((df) => {
        formData.append('files', df, df.name)
      })

      try {
        runInference({ id: model?.id || '', data: formData })
          .unwrap()
          .then((result) => {
            // setDropFiles(null)
            // TODO: show modal with results here.
            setInferenceResults(result)
            setDropFiles(null)
          })
      } catch (error: unknown) {
        setDropFiles(null)
        let msg: string
        if (isFetchBaseQueryError(error)) {
          if ('data' in error && error.data) {
            msg = `Inference error: ${Object.values(error.data)[0]}`
          } else {
            msg = `Inference error: ${error.status}`
          }
        } else {
          msg = `Unexpected inference error: ${error}`
        }
        setInferenceError(msg)
      }
    }
  }, [dropFiles, model?.id, runInference])

  useEffect(() => {
    setIsDeployed(model?.deployment.status === 'IN_SERVICE')
  }, [model])

  useEffect(() => {
    if (dropFiles && dropFiles.length > 0 && isDeployed && !inferenceLoading) {
      handleInference()
    }
  }, [dropFiles, handleInference, isDeployed, inferenceLoading])

  // Helper function to type-check FetchBaseQueryError
  function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error
  }

  const handleInferenceReset = () => {
    setInferenceResults(null)
  }

  let trainTime = 'N/A'
  if (trainEnd && trainStart) {
    if (train_ended_at === '0001-01-01T00:00:00Z') {
      trainTime = 'In Progress'
    } else {
      const durationMs = trainEnd.getTime() - trainStart.getTime()
      const hours = Math.floor(durationMs / (1000 * 60 * 60))
      const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60))
      trainTime = `${hours}h ${minutes}m`
    }
  }

  const handleDeleteDeployment = async (modelId: string | undefined) => {
    if (modelId) {
      const deletedDeployment = await deleteDeployment(modelId)
      console.log('deletedDeployment', { deletedDeployment })
    }
  }

  const handleDeploy = async (modelId: string) => {
    try {
      await deployModel(modelId).unwrap()
    } catch (error) {
      console.error('Failed to deploy selectedModel:', error)
    }
  }

  const allowedMetrics = [
    'validation:mAP',
    'train:smooth_l1',
    'train:cross_entropy',
    'validation:accuracy',
    'train:accuracy',
  ]

  // Filter train metrics (based on allowed keys)
  const trainMetrics = Object.entries(metrics || {})
    .filter(([key]) => allowedMetrics.includes(key) && key.startsWith('train:'))
    .map(([key, value]) => {
      const percentageValue = typeof value === 'number' ? (value * 100).toFixed(2) + '%' : String(value)
      return { key, value: percentageValue }
    })

  // Filter validation metrics (based on allowed keys)
  const validationMetrics = Object.entries(metrics || {})
    .filter(([key]) => allowedMetrics.includes(key) && key.startsWith('validation:'))
    .map(([key, value]) => {
      const percentageValue = typeof value === 'number' ? (value * 100).toFixed(2) + '%' : String(value)
      return { key, value: percentageValue }
    })

  // Combine train and validation metrics for easier rendering
  const combinedMetrics = [...trainMetrics, ...validationMetrics]

  if (!model) {
    return <div>No model data available.</div>
  }

  return (
    <div className="model-details-content">
      <div className="model-details-metadata mb-4">
        <div className="model-metrics-card">
          {/* Metrics row at the top */}
          <Row className="mb-4 metrics-row">
            <Col xs={12} sm={6} md={3}>
              <Card className="mb-2 metric-card model-metrics-card">
                <Card.Body>
                  {/* Model Name */}
                  <Card.Title>Rating</Card.Title>

                  {/* Star Rating */}
                  <div className="star-rating-container">
                    <div className="star-rating">
                      <StarRating rating={bBoxRating} />
                    </div>
                  </div>

                  {/* Train Time */}
                  <div className="train-time">
                    <strong>Train Time:</strong>{' '}
                    {train_ended_at === '0001-01-01T00:00:00Z' ? 'In Progress' : `${trainTime}`}
                  </div>

                  {/* Train Status */}
                  <div className="train-status">
                    <strong>Train Status:</strong> {model?.state}
                  </div>

                  {/* Deployment Status */}
                  <div className="deployment-status">
                    <strong>Deployment Status:</strong> {deployment?.status || 'Not Deployed'}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            {combinedMetrics.map(({ key, value }) => {
              // Ensure the value is a number, otherwise convert it to a number
              const numericValue = parseFloat(value)

              // Check if the conversion was successful (i.e., the value is a valid number)
              if (isNaN(numericValue)) {
                console.warn(`Invalid metric value for ${key}: ${value}`)
                return null // Skip rendering the metric if it's not a valid number
              }

              return (
                <Col key={key} xs={12} sm={6} md={3}>
                  <GaugeMetric metricName={key} metricValue={numericValue} />
                </Col>
              )
            })}
          </Row>

          {/* Deployment Button and Class Distribution */}
          <Row className="mb-3">
            <Col xs={3}>
              <Card
                className="shadow-sm deployment-card pb-3 card-equal-size flex-grow-1"
                style={{ maxHeight: '190px' }}
              >
                <Card.Title>Deployment</Card.Title>
                <Card.Body className="d-flex flex-column align-items-center">
                  {!(model?.deployment.status === 'IN_SERVICE') ? (
                    <Button
                      size="lg"
                      className={`deploy-btn ${model?.deployment?.status === 'CREATING' ? 'pulse deploying' : ''}`}
                      style={{ marginTop: '10px' }}
                      onClick={() => handleDeploy(model?.id || '')}
                      disabled={model?.deployment?.status === 'CREATING'}
                    >
                      {model?.deployment?.status === 'CREATING' ? 'Deploying...' : 'Deploy Model'}
                    </Button>
                  ) : (
                    <Button
                      size="lg"
                      className="delete-btn"
                      style={{ marginTop: '10px' }}
                      onClick={() => handleDeleteDeployment(model?.id)}
                    >
                      Delete Deploy
                    </Button>
                  )}
                </Card.Body>
              </Card>

              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>
                    {((dropFiles?.length || 0) > 0) || ((inferenceResults?.length || 0) > 0) && (
                      <div className="mt-3">
                        <Button variant="primary" onClick={handleInferenceReset} disabled={inferenceLoading || !isDeployed}>
                          {inferenceLoading ? 'Running Inference...' : 'Reset Inference'}
                        </Button>
                      </div>
                    )}
                  </Card.Title>

                  {isDeployed && !inferenceResults && (
                    <>
                      <div className="file-upload w-full max-w-md mx-auto">
                        <div className="bg-gray-900 rounded-lg p-6">
                          <FileUploader onFileSelect={onFileSelect} uploading={inferenceLoading} />
                        </div>
                      </div>
                    </>
                  )}

                  {!isDeployed && <>Deploy model to run inference</>}

                  {isError && (
                    <Alert variant="danger" className="mt-3">
                      Error occurred. {inferenceErrorMsg}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            </Col>

            <Col xs={9}>
              <ClassDistributionCard />
            </Col>
          </Row>
          <Row>
            {inferenceResults && inferenceResults.length > 0 && (
              <InferenceResults results={inferenceResults} dropFiles={dropFiles} />
            )}
          </Row>

          {/*/!* Inference Drop Zone *!/*/}
          {/*<Row>*/}
          {/*  {model?.deployment.status === 'IN_SERVICE' && (*/}
          {/*    <InferenceDropzone model={model || ({} as Model)} />*/}
          {/*  )}*/}
          {/*</Row>*/}
        </div>
      </div>
    </div>
  )
}

export default ModelMetricsCard
