import React, { useState } from 'react'
import { Card, Button, Form, Row, Col } from 'react-bootstrap'
import { FaLock, FaLockOpen, FaRocket, FaRunning } from 'react-icons/fa'
import { useUpdateModelMutation, useTrainModelMutation } from '../services/modelsApi'
import { useUpdateDatasetMutation } from '../services/datasetApi'
import { InstanceType, TrainParameters } from '../types/model'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectSelectedModel, setSelectedModel } from '../store/slices/modelSlice'
import DatasetSplitSlider from './DatasetSplitSlider'
import HyperparameterSettingsModal from './modals/HyperparametersSettingsModal'
import { useClassDistribution } from '../store/hooks'
import { selectSelectedProject } from '../store/slices/projectSlice'
// import DatasetSourceDropdown from './tools/DatasetSourceDropdown'
import { selectSelectedDatasetSource } from '../store/slices/datasetSlice'

const ModelGenerationView: React.FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const [trainSplit, setTrainSplit] = useState(80) // Default to 80% train, 20% validation
  const [updateModel] = useUpdateModelMutation()
  const [updateDataset] = useUpdateDatasetMutation()
  const [trainModel] = useTrainModelMutation()

  const selectedModel = useAppSelector(selectSelectedModel)

  const [showHyperparametersModal, setShowHyperparametersModal] = useState(false)
  const [isLocked, setIsLocked] = useState(true) // Lock state
  const [instanceType, setInstanceType] = useState<'NORMAL' | 'FAST'>('NORMAL') // Instance type selection

  const classDistribution = useClassDistribution(project?.id || '', project?.datasetid || '')
  const totalAnnotations = classDistribution.reduce((sum, item) => sum + item.value, 0)

  const selectedDatasetSource = useAppSelector(selectSelectedDatasetSource)

  const handleSplitChange = (value: number) => {
    setTrainSplit(value)
  }

  const handleSplitChangeComplete = async () => {
    if (project?.id && project?.datasetid) {
      const datasetSplit = {
        test: 0,
        train: trainSplit / 100,
        validation: (100 - trainSplit) / 100,
      }

      try {
        await updateDataset({
          datasetId: project.datasetid,
          datasetData: datasetSplit,
        }).unwrap()
      } catch (err) {
        console.error('Failed to update dataset split:', err)
      }
    }
  }

  const handleTrainModel = async () => {
    if (selectedModel?.id) {
      try {
        const modelTrainParams = {
          id: selectedModel.id,
          metadata: selectedDatasetSource ? [`dataset:${selectedDatasetSource}`] : undefined,
        }
        const updatedModel = await trainModel(modelTrainParams).unwrap()
        dispatch(setSelectedModel(updatedModel))
      } catch (err) {
        console.error('Failed to train model:', err)
      }
    }
  }

  const handleOpenHyperparametersModal = () => {
    if (!isLocked) {
      setShowHyperparametersModal(true)
    }
  }

  const handleCloseHyperparametersModal = () => {
    setShowHyperparametersModal(false)
  }

  const handleSaveTrainingParameters = async (newParameters: TrainParameters) => {
    if (selectedModel) {
      try {
        const updatedModel = await updateModel({
          id: selectedModel.id,
          modelData: {
            name: selectedModel.name,
            parameters: {
              ...selectedModel.parameters,
              hyperparameters: newParameters.hyperparameters ?? selectedModel.parameters.hyperparameters,
              resource: newParameters.resource ?? selectedModel.parameters.resource,
              runtime: newParameters.runtime ?? selectedModel.parameters.runtime,
            },
          },
        }).unwrap()
        dispatch(setSelectedModel(updatedModel))
      } catch (err) {
        console.error('Failed to update model name:', err)
      }
    }
  }

  const toggleLock = () => {
    setIsLocked((prevState) => !prevState)
  }

  const handleInstanceTypeSelect = async (type: 'NORMAL' | 'FAST') => {
    setInstanceType(type)
    let new_instance_type: InstanceType = 'ml.p3.8xlarge'
    if (type === 'NORMAL') {
      new_instance_type = 'ml.p3.2xlarge'
    } else if (type === 'FAST') {
      new_instance_type = 'ml.p3.8xlarge'
    }

    if (selectedModel) {
      try {
        const updatedParameters: TrainParameters = {
          ...selectedModel.parameters,
          resource: {
            ...selectedModel.parameters.resource,
            instance_type: new_instance_type, // Update the instance type in resource params
          },
        }

        const updatedModel = await updateModel({
          id: selectedModel.id,
          modelData: {
            name: selectedModel.name,
            parameters: updatedParameters,
          },
        }).unwrap()
        dispatch(setSelectedModel(updatedModel))
      } catch (err) {
        console.error('Failed to update model name:', err)
      }
    }
  }

  return (
    <div className="model-generation-content p-4">
      <Row className="mb-4 g-3" style={{ display: 'flex' }}>
        <Col xs={12} md={12}>
          <Card className="rounded card-hover-effect no-background">
            <Card.Body>
              <div className="d-flex justify-content-around">
                <div className="circle hover">
                  <div>
                    <div
                      style={{
                        fontSize: '2em',
                        fontWeight: 'bold',
                      }}
                    >
                      {classDistribution.length || 0}
                    </div>
                    <div style={{ fontSize: '0.5em' }}>Classes</div>
                  </div>
                </div>
                <div className="circle">
                  <div>
                    <div style={{ fontSize: '2em', fontWeight: 'bold' }}>{project?.count || 0}</div>
                    <div style={{ fontSize: '0.5em' }}>Images</div>
                  </div>
                </div>
                <div className="circle">
                  <div>
                    <div style={{ fontSize: '2em', fontWeight: 'bold' }}>{totalAnnotations || 0}</div>
                    <div style={{ fontSize: '0.5em' }}>Annotations</div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="h-100 border border-dark rounded card-hover-effect" style={{ padding: '20px' }}>
            {/*<Card.Body>*/}
            {/*  <Card.Title style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Training Settings</Card.Title>*/}
            {/*  <Card.Text style={{ fontSize: '1.05em' }} />*/}
            {/*</Card.Body>*/}

            {/*<Card.Title style={{ fontSize: '.7em', fontWeight: 'bold', marginTop: '10px' }}>*/}
            {/*    Dataset Source(s)*/}
            {/*</Card.Title>*/}
            {/*<Row>*/}
            {/*  <Col>*/}
            {/*    <DatasetSourceDropdown />*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<hr />*/}
            <Row className="mt-3">
              <Card.Title style={{ fontSize: '.7em', fontWeight: 'bold' }}>Split</Card.Title>
              <Form.Group style={{ padding: '10px 20px', fontSize: '1.05em' }}>
                <DatasetSplitSlider
                  value={trainSplit}
                  onChange={handleSplitChange}
                  onChangeComplete={handleSplitChangeComplete}
                />
              </Form.Group>
            </Row>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="h-100 border border-dark rounded card-hover-effect" style={{ padding: '20px' }}>
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <Card.Title style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Train Model</Card.Title>
              <Card.Text style={{ fontSize: '1.05em' }} />
              <Button
                variant="primary"
                onClick={handleTrainModel}
                disabled={selectedModel?.state === 'TRAINING' || !selectedModel?.id}
                className={`start-button ${selectedModel?.state === 'TRAINING' ? 'training' : ''}`}
              >
                {selectedModel?.state === 'TRAINING' ? 'Training' : 'Start Training'}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        {/* Advanced Settings Card */}
        <Col xs={12} md={4}>
          <Card
            className="h-100 border border-dark rounded card-hover-effect position-relative"
            style={{ padding: '20px' }}
          >
            {isLocked ? (
              <FaLock
                size={28}
                className="position-absolute"
                style={{ top: '10px', right: '10px', cursor: 'pointer', color: 'red' }}
                onClick={toggleLock}
              />
            ) : (
              <FaLockOpen
                size={28}
                className="position-absolute"
                style={{ top: '10px', right: '10px', cursor: 'pointer', color: 'green' }}
                onClick={toggleLock}
              />
            )}

            <Card.Body>
              <Card.Title className="text-center" style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                Advanced Settings
              </Card.Title>
              <Card.Title style={{ fontSize: '.7em', fontWeight: 'bold', marginTop: '10px' }}>
                Training Speed
              </Card.Title>
              <div className="d-flex justify-content-center mb-4">
                <div
                  className={`instance-type-icon ${instanceType === 'NORMAL' ? 'selected-instance' : ''}`}
                  onClick={() => !isLocked && handleInstanceTypeSelect('NORMAL')}
                  style={{
                    cursor: isLocked ? 'not-allowed' : 'pointer', // Disable cursor if locked
                    marginRight: '15px',
                    marginTop: '10px',
                    color: instanceType === 'NORMAL' ? 'green' : 'gray',
                    opacity: isLocked ? 0.5 : 1, // Dim icons if locked
                  }}
                >
                  <FaRunning size={28} title="Normal Instance" />
                  <div style={{ fontSize: '0.8rem', textAlign: 'center' }}>Normal</div>
                </div>

                <div
                  className={`instance-type-icon ${instanceType === 'FAST' ? 'selected-instance' : ''}`}
                  onClick={() => !isLocked && handleInstanceTypeSelect('FAST')}
                  style={{
                    cursor: isLocked ? 'not-allowed' : 'pointer', // Disable cursor if locked
                    marginTop: '10px',
                    color: instanceType === 'FAST' ? 'green' : 'gray',
                    opacity: isLocked ? 0.5 : 1, // Dim icons if locked
                  }}
                >
                  <FaRocket size={28} title="Fast Instance" />
                  <div style={{ fontSize: '0.8rem', textAlign: 'center' }}>Fast</div>
                </div>
              </div>
              <Card.Title style={{ fontSize: '.7em', fontWeight: 'bold', marginTop: '10px' }}>Tuning</Card.Title>
              <Button
                variant="secondary"
                onClick={handleOpenHyperparametersModal}
                className="hyperparameter-button"
                disabled={isLocked}
              >
                Hyperparameter Settings
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <HyperparameterSettingsModal
        show={showHyperparametersModal}
        handleClose={handleCloseHyperparametersModal}
        projectType={project?.annotation_type}
        onSave={handleSaveTrainingParameters}
        initialHyperparameters={selectedModel?.parameters?.hyperparameters}
      />
    </div>
  )
}

export default ModelGenerationView
