// src/components/Annotation/hooks/useImageAnnotation.ts

import { useState, useRef, useCallback, useEffect } from 'react'
import { useAppDispatch } from '../../../store/hooks'
import {
  addBoundingBox,
  clearFilteredBoundingBoxes,
  removeBoundingBox,
  setFilteredBoundingBoxes,
  updateBoundingBox,
} from '../../../store/slices/annotationSlice'
import { normalizeBoundingBox } from '../../../utils'
import { AnnotationDataBoundingBox } from '../../../types/annotation'
import { Tag } from '../../../types/tag'

export interface MousePosition {
  x: number
  y: number
}

export interface PopupState {
  show: boolean
  position: MousePosition
  box: AnnotationDataBoundingBox | null
}

export const useImageAnnotation = (
  useBBoxes: boolean,
  editingBoundingBoxes: AnnotationDataBoundingBox[],
  readonly: boolean,
) => {
  const [initialEditingBBoxes, setInitialEditingBBoxes] = useState<AnnotationDataBoundingBox[] | undefined>(undefined)
  const [isDrawing, setIsDrawing] = useState(false)
  const [newBoundingBox, setNewBoundingBox] = useState<AnnotationDataBoundingBox | null>(null)
  const [selectedBBox, setSelectedBBox] = useState<AnnotationDataBoundingBox | null>(null)
  const [resizingHandle, setResizingHandle] = useState<string | null>(null)
  const [panOffset, setPanOffset] = useState<MousePosition>({ x: 0, y: 0 })
  const [isPanning, setIsPanning] = useState(false)
  const [zoomLevel, setZoomLevel] = useState(0.8)
  const [hoveredBox, setHoveredBox] = useState<AnnotationDataBoundingBox | null>(null)

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const lastPanPointRef = useRef<MousePosition>({ x: 0, y: 0 })
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 })

  const [showTagSelector, setShowTagSelector] = useState(false)
  const [tagSelectorPosition, setTagSelectorPosition] = useState<MousePosition>({ x: 0, y: 0 })
  const [contrast, setContrast] = useState(0)
  const [undoStack, setUndoStack] = useState<AnnotationDataBoundingBox[][]>([])
  const [redoStack, setRedoStack] = useState<AnnotationDataBoundingBox[][]>([])

  const [popupState, setPopupState] = useState<PopupState>({
    show: false,
    position: { x: 0, y: 0 },
    box: null,
  })

  const dispatch = useAppDispatch()

  const getMousePos = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>): MousePosition => {
      if (!canvasRef.current) return { x: 0, y: 0 }

      const rect = canvasRef.current.getBoundingClientRect()
      const scaleX = canvasRef.current.width / rect.width
      const scaleY = canvasRef.current.height / rect.height

      const canvasX = (e.clientX - rect.left) * scaleX
      const canvasY = (e.clientY - rect.top) * scaleY

      let x = (canvasX - canvasRef.current.width / 2) / zoomLevel + canvasRef.current.width / 2 - panOffset.x
      let y = (canvasY - canvasRef.current.height / 2) / zoomLevel + canvasRef.current.height / 2 - panOffset.y

      x = x >= 0 ? (x < canvasRef.current.width ? x : canvasRef.current.width) : 0
      y = y >= 0 ? (y < canvasRef.current.height ? y : canvasRef.current.height) : 0
      setMousePosition({ x, y })

      return { x, y }
    },
    [zoomLevel, panOffset],
  )

  useEffect(() => {
    if (!initialEditingBBoxes && editingBoundingBoxes.length > 0) {
      setInitialEditingBBoxes(editingBoundingBoxes)
    }
  }, [editingBoundingBoxes, initialEditingBBoxes])

  useEffect(() => {
    setInitialEditingBBoxes([...editingBoundingBoxes])
    setSelectedBBox(null)
    const ctx = canvasRef?.current?.getContext('2d')
    if (ctx && imageRef.current) {
      ctx.drawImage(imageRef.current, 0, 0, ctx.canvas.width, ctx.canvas.height)
    }
    // Do not include editingBoundingBoxes in this dependency array since we only want to set the
    // initialBoundingBoxes one time per image load so that we can reset the display
  }, [imageRef])

  const recenterImage = useCallback(() => {
    setZoomLevel(0.8)
    setPanOffset({ x: 0, y: 0 })
    setContrast(0)
  }, [])

  const undo = useCallback(() => {
    if (!readonly) {
      const undoneEditingBBoxes = undoStack[0]
      if (undoneEditingBBoxes) {
        setRedoStack((prev) => [[...editingBoundingBoxes], ...prev])
        dispatch(setFilteredBoundingBoxes(undoneEditingBBoxes))
        setUndoStack((prev) => prev.slice(1))
      }
    }
  }, [dispatch, editingBoundingBoxes, readonly, undoStack])

  const redo = useCallback(() => {
    if (!readonly) {
      const redoneEditingBBoxes = redoStack[0]
      if (redoneEditingBBoxes) {
        setUndoStack((prev) => [[...editingBoundingBoxes], ...prev])
        dispatch(setFilteredBoundingBoxes(redoneEditingBBoxes))
        setRedoStack((prev) => prev.slice(1))
      }
    }
  }, [dispatch, editingBoundingBoxes, readonly, redoStack])

  const reset = useCallback(() => {
    dispatch(setFilteredBoundingBoxes(initialEditingBBoxes || []))
  }, [dispatch, initialEditingBBoxes])

  const removeAll = useCallback(() => {
    if (!readonly) {
      setUndoStack((prev) => [[...editingBoundingBoxes], ...prev])
      dispatch(clearFilteredBoundingBoxes())
    }
  }, [dispatch, editingBoundingBoxes, readonly])

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      if (!readonly) {
        e.preventDefault()
        e.stopPropagation()
        const { x, y } = getMousePos(e)
        const clickedBox = editingBoundingBoxes.find(
          (box) => x >= box.xmin && x <= box.xmax && y >= box.ymin && y <= box.ymax,
        )

        if (clickedBox) {
          setPopupState({
            show: true,
            position: {
              x: e.clientX,
              y: e.clientY,
            },
            box: clickedBox,
          })
        }
      }
    },
    [editingBoundingBoxes, getMousePos, readonly],
  )

  const handleTagChange = useCallback(
    (newTag: Tag) => {
      if (!readonly) {
        if (popupState.box) {
          const updatedBBox = { ...popupState.box, name: newTag.id }
          dispatch(updateBoundingBox({ updatedBBox }))
          setPopupState((prev) => ({ ...prev, show: false, box: null }))
        }
      }
    },
    [readonly, popupState.box, dispatch],
  )

  const handleBoxDelete = useCallback(() => {
    if (!readonly) {
      if (popupState.box) {
        setUndoStack((prev) => [[...editingBoundingBoxes], ...prev])
        dispatch(removeBoundingBox(popupState.box))
        setPopupState((prev) => ({ ...prev, show: false, box: null }))
      }
    }
  }, [readonly, popupState.box, dispatch, editingBoundingBoxes])

  const handleClosePopup = useCallback(() => {
    if (!readonly) {
      setPopupState((prev) => ({ ...prev, show: false, box: null }))
    }
  }, [readonly])

  const handleBoxSelection = useCallback((box: AnnotationDataBoundingBox | null) => {
    console.log('handleBoxSelected - selectedBBox', box)
    setSelectedBBox(box)
  }, [])

  const handleBoxResize = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>) => {
      if (!readonly) {
        if (!selectedBBox || !resizingHandle) return

        const { x: ex, y: ey } = getMousePos(e)
        const x = Math.floor(ex)
        const y = Math.floor(ey)
        const updatedBBox = { ...selectedBBox } as AnnotationDataBoundingBox

        switch (resizingHandle) {
          case 'topLeft':
            updatedBBox.xmin = Math.min(x, selectedBBox.xmax)
            updatedBBox.ymin = Math.min(y, selectedBBox.ymax)
            break
          case 'topRight':
            updatedBBox.xmax = Math.max(x, selectedBBox.xmin)
            updatedBBox.ymin = Math.min(y, selectedBBox.ymax)
            break
          case 'bottomLeft':
            updatedBBox.xmin = Math.min(x, selectedBBox.xmax)
            updatedBBox.ymax = Math.max(y, selectedBBox.ymin)
            break
          case 'bottomRight':
            updatedBBox.xmax = Math.max(x, selectedBBox.xmin)
            updatedBBox.ymax = Math.max(y, selectedBBox.ymin)
            break
        }

        dispatch(updateBoundingBox({ updatedBBox }))
        setSelectedBBox(updatedBBox)
      }
    },
    [readonly, selectedBBox, resizingHandle, getMousePos, dispatch],
  )

  const getResizeHandle = useCallback(
    (box: AnnotationDataBoundingBox, mouseX: number, mouseY: number): string | null => {
      if (!readonly) {
        const handleSize = 18 / zoomLevel // Ensure this matches the handle size used in drawing
        const handles = [
          { name: 'topLeft', x: box.xmin, y: box.ymin }, // Top-left
          { name: 'topRight', x: box.xmax - handleSize, y: box.ymin }, // Top-right
          { name: 'bottomLeft', x: box.xmin, y: box.ymax - handleSize }, // Bottom-left
          { name: 'bottomRight', x: box.xmax - handleSize, y: box.ymax - handleSize }, // Bottom-right
        ]

        for (const handle of handles) {
          // Check if the mouse is within the rectangle for each handle
          if (
            mouseX >= handle.x &&
            mouseX <= handle.x + handleSize &&
            mouseY >= handle.y &&
            mouseY <= handle.y + handleSize
          ) {
            return handle.name // Return the name of the handle if the mouse is over it
          }
        }
      }
      return null // No handle found
    },
    [readonly, zoomLevel],
  )

  const boxHasSize = (bBox: AnnotationDataBoundingBox): boolean => {
    return bBox.xmax - bBox.xmin !== 0 && bBox.ymax - bBox.ymin !== 0
  }

  const handleMouseEvent = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement>, eventType: 'down' | 'move' | 'up' | 'click' | 'context') => {
      const { x, y } = getMousePos(e)

      if (useBBoxes && eventType === 'down' && e.button === 0) {
        const clickedBox = editingBoundingBoxes.find(
          (box) => x >= box.xmin && x <= box.xmax && y >= box.ymin && y <= box.ymax,
        )
        if (clickedBox) {
          handleBoxSelection(clickedBox)
          if (!readonly) {
            const handle = getResizeHandle(clickedBox, x, y)
            setResizingHandle(handle)
          }
        } else {
          handleBoxSelection(null)
          if (!readonly) {
            setIsDrawing(true)
            setNewBoundingBox({
              name: 'newBox',
              xmin: x,
              ymin: y,
              xmax: x,
              ymax: y,
              class_index: editingBoundingBoxes.length,
            })
          }
        }
      } else if (eventType === 'down' && e.button === 1) {
        setIsPanning(true)
        lastPanPointRef.current = { x: e.clientX, y: e.clientY }
      } else if (eventType === 'move') {
        // drawing a new bounding box
        if (!readonly && useBBoxes && isDrawing && newBoundingBox) {
          const newBox = {
            ...newBoundingBox,
            xmax: x,
            ymax: y,
          }
          setNewBoundingBox(newBox)
        }
        // resizing an existing box
        else if (!readonly && useBBoxes && selectedBBox && resizingHandle) {
          handleBoxResize(e)
        } else if (isPanning) {
          const dx = (e.clientX - lastPanPointRef.current.x) / zoomLevel
          const dy = (e.clientY - lastPanPointRef.current.y) / zoomLevel
          setPanOffset((prev) => ({ x: prev.x + dx, y: prev.y + dy }))
          lastPanPointRef.current = { x: e.clientX, y: e.clientY }
        } else if (useBBoxes) {
          const hoveredBox =
            editingBoundingBoxes.find((box) => x >= box.xmin && x <= box.xmax && y >= box.ymin && y <= box.ymax) || null
          setHoveredBox(hoveredBox)
        }
      } else if (eventType === 'up') {
        if (!readonly && selectedBBox && resizingHandle) {
          setResizingHandle(null)
        } else if (!readonly && isDrawing && newBoundingBox) {
          if (canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect()
            const centerX = rect.width / 2
            const centerY = rect.height / 2

            let tagX = e.clientX - rect.left
            let tagY = e.clientY - rect.top
            if (centerX < tagX) {
              tagX = tagX - 250
            }
            if (centerY < tagY) {
              tagY = tagY - 300
            }
            if (!isPanning) {
              setTagSelectorPosition({ x: tagX, y: tagY })
              setShowTagSelector((prevState) => !prevState)
            }
          }
        }
        setIsDrawing(false)
        setIsPanning(false)
      } else if (!readonly && eventType === 'context') {
        e.preventDefault()
        setShowTagSelector(false)
        setHoveredBox(null)
        setNewBoundingBox(null)
        setIsDrawing(false)
      }
    },
    [
      getMousePos,
      useBBoxes,
      readonly,
      editingBoundingBoxes,
      handleBoxSelection,
      getResizeHandle,
      isDrawing,
      newBoundingBox,
      selectedBBox,
      resizingHandle,
      isPanning,
      handleBoxResize,
      zoomLevel,
    ],
  )

  const handleWheel = useCallback(
    (e: React.WheelEvent<HTMLCanvasElement>) => {
      e.preventDefault()
      const zoomSensitivity = 0.001
      const newZoomLevel = zoomLevel * (1 - e.deltaY * zoomSensitivity)
      setZoomLevel(Math.max(0.5, Math.min(10, newZoomLevel))) // Limit zoom
    },
    [zoomLevel],
  )

  const handleTagSelect = useCallback(
    (tag: Tag) => {
      console.log('handleTagSelect - selectedBBox', selectedBBox)
      console.log('newBBox', newBoundingBox)

      setPopupState({
        show: false,
        position: { x: 0, y: 0 },
        box: null,
      })
      if (useBBoxes && newBoundingBox) {
        const normalizedBoundingBox = normalizeBoundingBox(newBoundingBox)
        const flooredBox: AnnotationDataBoundingBox = {
          name: tag.id,
          xmin: Math.floor(normalizedBoundingBox.xmin),
          ymin: Math.floor(normalizedBoundingBox.ymin),
          xmax: Math.floor(normalizedBoundingBox.xmax),
          ymax: Math.floor(normalizedBoundingBox.ymax),
        }
        if (boxHasSize(newBoundingBox)) {
          setUndoStack((prev) => [[...editingBoundingBoxes], ...prev])
          dispatch(addBoundingBox(flooredBox))
        }
        setNewBoundingBox(null)
      } else if (useBBoxes && selectedBBox) {
        setUndoStack((prev) => [[...editingBoundingBoxes], ...prev])
        dispatch(removeBoundingBox(selectedBBox))
        dispatch(
          addBoundingBox({
            ...selectedBBox,
            name: tag.id,
            class_index: editingBoundingBoxes.length,
          }),
        )
      }
    },
    [useBBoxes, newBoundingBox, selectedBBox],
  )

  return {
    canvasRef,
    imageRef,
    handleMouseEvent,
    handleWheel,
    zoomLevel,
    panOffset,
    newBoundingBox,
    selectedBBox,
    handleBoxSelection,
    setNewBoundingBox,
    handleTagSelect,
    mousePosition,
    showTagSelector,
    setShowTagSelector,
    tagSelectorPosition,
    recenterImage,
    undo,
    redo,
    reset,
    removeAll,
    contrast,
    setContrast,
    handleDoubleClick,
    popupState,
    setPopupState,
    handleTagChange,
    handleBoxDelete,
    handleClosePopup,
  }
}
