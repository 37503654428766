// src/components/tools/TagSelector.tsx
import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Form, ListGroup, Button } from 'react-bootstrap'
import { useGetTagsQuery, useCreateTagMutation } from '../../services/tagApi'
import { Tag } from '../../types/tag'
import { X } from 'lucide-react'
import { useAppSelector } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'

interface TagListSelectorProps {
  onTagSelect: (tag: Tag) => void
  position?: { x: number; y: number }
  onClose?: () => void
}

const TagSelector: React.FC<TagListSelectorProps> = ({ onTagSelect, position, onClose }) => {
  const project = useAppSelector(selectSelectedProject)
  const [filter, setFilter] = useState('')
  const [filteredTags, setFilteredTags] = useState<Tag[]>([])
  const [createTag] = useCreateTagMutation()
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    data: tags,
    isLoading,
    error,
  } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleFilterChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }, [])

  const handleTagSelect = useCallback(
    async (tag: Tag) => {
      onTagSelect(tag)
      if (onClose) {
        onClose()
      }
    },
    [onTagSelect, onClose],
  )

  useEffect(() => {
    const filtered =
      tags?.tags?.filter((tag: Tag) => tag.name.toLowerCase().includes(filter.toLowerCase())).slice(0, 10) || []
    setFilteredTags(filtered)
  }, [filter, tags])

  const handleKeyPress = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && filter.trim() !== '') {
        const existingTag = filteredTags.find((tag) => tag.name.toLowerCase() === filter.toLowerCase())
        if (existingTag) {
          await handleTagSelect(existingTag)
        } else {
          try {
            const newTag = await createTag({
              name: filter,
              color: Math.floor(Math.random() * 16777215), // Random color
              dataset_id: project?.datasetid || '',
              project_id: project?.id || '',
            }).unwrap()
            await handleTagSelect(newTag)
          } catch (error) {
            console.error('Failed to create new tag:', error)
          }
        }
      }
    },
    [filter, filteredTags, handleTagSelect, createTag, project],
  )

  if (isLoading) return <div>Loading tags...</div>
  if (error) return <div>Error loading tags</div>

  const selectorContent = (
    <>
      <Form.Control
        ref={inputRef}
        type="text"
        placeholder="Filter or create new tag"
        value={filter}
        onChange={handleFilterChange}
        onKeyDown={handleKeyPress}
      />
      <ListGroup className="mt-2">
        {filteredTags.map((tag: Tag) => (
          <ListGroup.Item key={tag.id} action onClick={() => handleTagSelect(tag)} style={{ cursor: 'pointer' }}>
            {tag.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  )

  const containerStyle: React.CSSProperties = {
    position: 'relative',
    background: 'white',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    ...(position && {
      position: 'absolute',
      left: `${position.x}px`,
      top: `${position.y}px`,
      zIndex: 9999,
    }),
  }

  const closeButtonStyle: React.CSSProperties = {
    position: 'absolute',
    top: '-15px',
    right: '-15px',
    borderRadius: '50%',
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  }

  return (
    <div style={containerStyle}>
      {onClose && (
        <Button variant="light" style={closeButtonStyle} onClick={onClose} aria-label="Close">
          <X size={16} />
        </Button>
      )}
      {selectorContent}
    </div>
  )
}

export default TagSelector
