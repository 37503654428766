import React, { useState } from 'react'
import { Card, Row, Col, Table, Dropdown, DropdownButton, Form } from 'react-bootstrap'
import { FaTrello, FaTags, FaImages } from 'react-icons/fa'
import ClassDistributionCard from '../ClassDistributionCard'
import { useAppSelector, useClassDistribution } from '../../store/hooks'
import OverviewCard from '../OverviewCard'
import { selectSelectedProject } from '../../store/slices/projectSlice'

const DatasetView: React.FC = () => {
  const project = useAppSelector(selectSelectedProject)
  const classDistribution = useClassDistribution(project?.id || '', project?.datasetid || '')
  const [filter, setFilter] = useState<string>('all') // Filter for over/underrepresented classes
  const [sortBy, setSortBy] = useState<string>('value') // Sort by value, name, percentage
  const [classNameSearch, setClassNameSearch] = useState<string>('') // Search by class name

  const totalImages = classDistribution.reduce((sum, item) => sum + item.value, 0)
  const classWithoutUnknown = classDistribution.filter((item) => item.name !== 'Unknown')

  // Apply both the class name search and the balance filter
  const filteredClasses = classWithoutUnknown.filter((item) => {
    const matchesSearch = item.name.toLowerCase().includes(classNameSearch.toLowerCase())
    const matchesFilter = filter === 'all' || item.balance.includes(filter)
    return matchesSearch && matchesFilter
  })

  const sortedClasses = [...filteredClasses].sort((a, b) => {
    if (sortBy === 'value') return b.value - a.value
    if (sortBy === 'name') return a.name.localeCompare(b.name)
    if (sortBy === 'percentage') return b.percentage - a.percentage
    return 0
  })

  // Helper to display balance with icons
  const getBalanceIcon = (balance: string) => {
    if (balance.includes('balanced')) {
      return <span className="text-success">✔ Balanced</span>
    } else if (balance.includes('under')) {
      return <span className="text-danger">⬇ Underrepresented</span>
    } else if (balance.includes('over')) {
      return <span className="text-warning">⬆ Overrepresented</span>
    }
  }

  // Helper to get the percentage of annotations for a class
  const getClassPercentage = (value: number) => {
    return ((value / totalImages) * 100).toFixed(2)
  }

  return (
    <>
      {/* Dataset Overview Header */}
      <Row className="mt-4 project-view-header align-items-center">
        <Col>
          <h2 className="text-light">Dataset Overview</h2>
        </Col>

        <Col className="d-flex justify-content-end">
          {/* Classes Card */}
          <OverviewCard
            title={'Classes'}
            value={classWithoutUnknown.length}
            icon={<FaTrello size={35} className="text-primary" style={{ marginRight: '5px' }} />}
          />

          {/* Images Card */}
          <OverviewCard
            title={'Images'}
            value={project?.count || 0}
            icon={<FaImages size={35} className="text-info" style={{ marginRight: '5px' }} />}
          />

          {/* Annotations Card */}
          <OverviewCard
            title={'Annotations'}
            value={totalImages}
            icon={<FaTags size={35} className="text-success" style={{ marginRight: '10px' }} />}
          />
        </Col>
      </Row>

      {/* Manage Image Data and Export Dataset Section */}

      {/* Class Distribution Chart */}
      <Row className="mt-2">
        {/* Class List with Filters in Table Headers */}
        <Col xs={6}>
          <Card className="class-distribution-card mt-3 shadow-sm" style={{ height: '465px' }}>
            <Card.Header className="d-flex justify-content-between align-items-center bg-dark text-white p-3">
              <h5 className="mb-0 card-title">Class List</h5>
            </Card.Header>

            {/* Class List with Custom Styling */}
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>
                    Class
                    <Form.Control
                      type="text"
                      placeholder="Search Class"
                      className="mt-2"
                      onChange={(e) => setClassNameSearch(e.target.value)} // Set class name search state
                    />
                  </th>
                  <th className="text-center">
                    {sortBy === 'percentage' ? 'Percentage (%)' : 'Annotations'}
                    <DropdownButton
                      variant="outline-light"
                      title={`Sort: ${sortBy.charAt(0).toUpperCase() + sortBy.slice(1)}`}
                      size="sm"
                      onSelect={(value) => setSortBy(value || 'value')}
                      className="mt-2"
                    >
                      <Dropdown.Item eventKey="value">Value</Dropdown.Item>
                      <Dropdown.Item eventKey="percentage">Percentage</Dropdown.Item>
                    </DropdownButton>
                  </th>
                  <th>
                    Balance
                    <DropdownButton
                      variant="outline-light"
                      title={`Filter: ${filter.charAt(0).toUpperCase() + filter.slice(1)}`}
                      size="sm"
                      onSelect={(value) => setFilter(value || 'all')}
                      className="mt-2"
                    >
                      <Dropdown.Item eventKey="all">All</Dropdown.Item>
                      <Dropdown.Item eventKey={'bal'}>Balanced</Dropdown.Item>
                      <Dropdown.Item eventKey="over">Overrepresented</Dropdown.Item>
                      <Dropdown.Item eventKey="under">Underrepresented</Dropdown.Item>
                    </DropdownButton>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedClasses.map((item) => (
                  <tr key={item.id}>
                    <td className="d-flex align-items-center">
                      {/* Class Color Indicator */}
                      <div
                        className="class-color-indicator me-3"
                        style={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor: item.color,
                        }}
                      />
                      <span>{item.name}</span>
                    </td>
                    <td className="text-center">
                      {sortBy === 'percentage' ? `${getClassPercentage(item.value)}%` : item.value}
                    </td>
                    <td>{getBalanceIcon(item.balance)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col xs={6}>
          <ClassDistributionCard title="Class Distribution" showButtons={true} />
        </Col>
      </Row>
    </>
  )
}

export default DatasetView
