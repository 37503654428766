// src/components/InferenceResults.tsx

import React, { useEffect, useState } from 'react'

import { InferenceResult } from '../types/responses'
import { PredictionMetadata } from '../types/prediction'
import { Row, Col, Image } from 'react-bootstrap'

interface InferenceResultsProps {
  results: InferenceResult[]
  dropFiles: File[] | null
}

const InferenceResults: React.FC<InferenceResultsProps> = ({ results, dropFiles }) => {
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [flippedCards, setFlippedCards] = useState<boolean[]>([])

  useEffect(() => {
    const urls = dropFiles?.map((file) => URL.createObjectURL(file))
    if (urls) {
      setImageUrls(urls)
    }
    setFlippedCards(new Array(dropFiles?.length).fill(false))

    // Cleanup function to revoke the URLs when the component unmounts
    return () => {
      urls?.forEach((url) => URL.revokeObjectURL(url))
    }
  }, [dropFiles])

  const handleSetCardFlip = (index: number) => {
    setFlippedCards((prev) => {
      const newFlippedCards = [...prev]
      newFlippedCards[index] = true
      return newFlippedCards
    })
  }

  const handleResetCardFlip = (index: number) => {
    setFlippedCards((prev) => {
      const newFlippedCards = [...prev]
      newFlippedCards[index] = false
      return newFlippedCards
    })
  }

  return (
    <Row className="results-container">
      {results.map((result: InferenceResult, index) => (
        <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
          <div
            className={`flip-card ${flippedCards[index] ? 'flipped' : ''}`}
            onClick={() => handleSetCardFlip(index)} // Pass the ID to fetch heatmap
            onMouseLeave={() => handleResetCardFlip(index)}
          >
            <div className="flip-card-inner">
              <div className="flip-card-front">
                {imageUrls[index] && <Image src={imageUrls[index]} alt={`Inference image ${index + 1}`} fluid />}
                <div className="image-overlay">
                  <p>Confidence: {((result?.predictions[0]?.confidence || 0) * 100).toFixed(2)}%</p>
                </div>
              </div>

              <div className="flip-card-back">
                <h4>{result.filename}</h4>
                {result.predictions.map((prediction: PredictionMetadata, predIndex: number) => (
                  <div key={predIndex} className="prediction">
                    <p>Class: {prediction.class_name}</p>
                    <p>Confidence: {(prediction.confidence * 100).toFixed(2)}%</p>
                    <div className="bounding-box">
                      <p>Bounding Box:</p>
                      <ul>
                        <li>{`X-min: ${prediction?.bounding_boxes?.xmin}`}</li>
                        <li>{`Y-min: ${prediction?.bounding_boxes?.ymin}`}</li>
                        <li>{`X-max: ${prediction?.bounding_boxes?.xmax}`}</li>
                        <li>{`Y-max: ${prediction?.bounding_boxes?.ymax}`}</li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )
}

export default InferenceResults
