import React, { useCallback, useEffect } from 'react'
import { Badge, Card, Col, Dropdown, Row } from 'react-bootstrap'
import { FaEllipsisV } from 'react-icons/fa'
import { Project } from '../types/project'
import { useNavigate } from 'react-router-dom'
import {
  useFetchAnnotatedContentQuery,
  // useGetContentMetadataQuery,
} from '../services/contentApi'
import { projectsApi, useDeleteProjectMutation } from '../services/projectsApi'
import { useFetchModelsQuery } from '../services/modelsApi'
import { Model } from '../types/model'
import { toTitleCase } from '../utils'
import { useAppDispatch, useClassDistribution } from '../store/hooks'
import { setSelectedProject } from '../store/slices/projectSlice'

interface ProjectCardProps {
  project: Project
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [deleteProject] = useDeleteProjectMutation()
  const { data: modelsData } = useFetchModelsQuery({ project_id: project?.id || '' })

  const classDistribution = useClassDistribution(project.id || '', project.datasetid || '')
  const totalAnnotations = classDistribution.reduce((sum, item) => sum + item.value, 0)

  const { data: contentResp } = useFetchAnnotatedContentQuery({
    project_id: project.id,
    limit: 1,
    page: 1,
    sort_key: 'created_at',
    sort_val: -1,
  })

  // const { data: metadata } = useGetContentMetadataQuery({
  //   project_id: project.id,
  // })

  // useEffect(() => {
  //   console.log('metadata: ', metadata)
  // }, [metadata])

  const countTrainedAndDeployedModels = (models: Model[]): { trained: number; deployed: number } => {
    return models.reduce(
      (acc, model) => {
        if (model.deployment?.status === 'IN_SERVICE') {
          acc.deployed++
        } else if (model.state === 'TRAINED') {
          acc.trained++
        }
        return acc
      },
      { trained: 0, deployed: 0 },
    )
  }

  const modelCounts = modelsData?.models
    ? countTrainedAndDeployedModels(modelsData.models)
    : { trained: 0, deployed: 0 }

  const handleCardClick = () => {
    dispatch(setSelectedProject(project))
    navigate(`/ProjectDetails/${project.id}`)
  }

  const handleDelete = useCallback(async () => {
    try {
      await deleteProject(project.id).unwrap()
      setTimeout(() => {
        dispatch(projectsApi.util.invalidateTags([{ type: 'Project', id: 'LIST' }]))
      }, 300)
    } catch (error) {
      console.error('Failed to delete project:', error)
      // Optionally, you can show an error message to the user here
    }
  }, [deleteProject, dispatch, project.id])

  let imageUrl = '/fa18.jpeg'
  if (contentResp?.content && contentResp.content.length > 0) {
    imageUrl = `data:image/;base64,${contentResp?.content[0].b64_image}`
  }

  return (
    <Card
      className="project-card"
      style={{ minHeight: '370px', width: '450px', margin: '10px', position: 'relative' }}
      onClick={handleCardClick}
    >
      <Card.Header style={{ position: 'relative', padding: 0 }}>
        <Card.Img variant="top" src={imageUrl} style={{ height: '200px', borderRadius: '8px', objectFit: 'cover' }} />
        <Badge
          className="project-type-overlay"
          bg={project.annotation_type === 'classification' ? 'primary' : 'success'}
          style={{
            position: 'absolute',
            bottom: '10px',
            left: '10px',
            padding: '5px 10px',
            fontSize: '12px',
            borderRadius: '8px',
            width: '25%',
          }}
        >
          {toTitleCase(project.annotation_type)}
        </Badge>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col xs={10}>
            <Card.Title style={{ fontSize: '24px', marginBottom: '20px', textOverflow: 'ellipsis' }}>
              {project.name}
            </Card.Title>
          </Col>
          <Col xs={2} className="text-end">
            <Dropdown onClick={(e) => e.stopPropagation()} className="card-menu">
              <Dropdown.Toggle as="div" style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                <FaEllipsisV size={24} />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu" style={{ zIndex: 20 }}>
                <Dropdown.Item className="dropdown-item" href="#/action-1">
                  Edit
                </Dropdown.Item>
                <Dropdown.Item className="dropdown-item" onClick={handleDelete}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Card.Text style={{ minHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          Description: {project.description}
        </Card.Text>

        <Row>
          <Col xs={12}>
            <Row className="justify-content-between mb-4">
              <Col className="text-center">
                <Card.Text>Models</Card.Text>
                <Badge>{modelsData?.models?.length || 0}</Badge>
              </Col>
              <Col className="text-center">
                <Card.Text>Trained</Card.Text>
                <Badge>{modelCounts.trained}</Badge>
              </Col>
              <Col className="text-center">
                <Card.Text>Deployed</Card.Text>
                <Badge>{modelCounts.deployed}</Badge>
              </Col>
            </Row>

            <Row className="justify-content-between mt-2">
              <Col className="text-center">
                <Card.Text>Images</Card.Text>
                <Badge>{project.count || 0}</Badge>
              </Col>
              <Col className="text-center">
                <Card.Text>Classes</Card.Text>
                <Badge>{classDistribution.length || 0}</Badge>
              </Col>
              {/*<Col className="text-center">*/}
              {/*  <Card.Text>Datasets</Card.Text>*/}
              {/*  <Badge>{metadata?.metadata?.dataset?.length}</Badge>*/}
              {/*</Col>*/}
              <Col className="text-center">
                <Card.Text>Annotations</Card.Text>
                <Badge>{totalAnnotations || 0}</Badge>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default ProjectCard
