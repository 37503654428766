import React, { useState } from 'react'
import { Card, Dropdown, DropdownButton } from 'react-bootstrap'
import { Doughnut, Bar } from 'react-chartjs-2'
import { useAppSelector, useClassDistribution } from '../store/hooks'
import 'chart.js/auto'
import { selectSelectedProject } from '../store/slices/projectSlice'

interface ClassDistributionCardProps {
  title?: string
  showButtons?: boolean
}

const ClassDistributionCard: React.FC<ClassDistributionCardProps> = ({
  title = 'Class Distribution',
  showButtons = true,
}) => {
  const project = useAppSelector(selectSelectedProject)
  const classDistribution = useClassDistribution(project?.id || '', project?.datasetid || '')
  const [viewMode, setViewMode] = useState<'bar' | 'doughnut' | 'radar'>('bar')

  const maxValue = Math.max(...classDistribution.map((item) => item.value))
  const maxRange = Math.ceil(maxValue / 100) * 100 + 100

  const doughnutData = {
    labels: classDistribution.map((item) => item.name),
    datasets: [
      {
        data: classDistribution.map((item) => item.value),
        backgroundColor: classDistribution.map((item) => item.color),
      },
    ],
  }

  const barData = {
    labels: classDistribution.map((item) => item.name), // Array of class names
    datasets: [
      {
        label: '', // This can remain, but we will hide the legend entirely
        data: classDistribution.map((item) => item.value),
        backgroundColor: classDistribution.map((item) => item.color),
      },
    ],
  }

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        max: maxRange,
      },
    },
    plugins: {
      legend: {
        display: viewMode !== 'bar', // Disable legend only for the bar chart
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.label
            const value = context.raw
            return `${label}: ${value}`
          },
        },
      },
    },
  }

  return (
    <Card className="class-distribution-card mt-3 shadow-sm">
      <Card.Header className="d-flex justify-content-between align-items-center bg-dark text-white p-3">
        <h5 className="mb-0 card-title">{title}</h5>
        {showButtons && (
          <DropdownButton
            id="view-mode-dropdown"
            title={`View: ${viewMode.charAt(0).toUpperCase() + viewMode.slice(1)}`}
            variant="outline-light"
            size="sm"
            drop="down" // Ensures the dropdown opens downward
          >
            <Dropdown.Item onClick={() => setViewMode('bar')}>Bar Chart</Dropdown.Item>
            <Dropdown.Item onClick={() => setViewMode('doughnut')}>Doughnut Chart</Dropdown.Item>
          </DropdownButton>
        )}
      </Card.Header>
      <Card.Body className="card-body">
        {viewMode === 'bar' && (
          <div className="chart-container">
            <Bar data={barData} options={chartOptions} />
          </div>
        )}
        {viewMode === 'doughnut' && (
          <div className="chart-container">
            <Doughnut data={doughnutData} options={chartOptions} />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default ClassDistributionCard
