// src/pages/ProjectDetails.tsx

import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import TopBar from '../components/ProjectDetails/TopBar'
import MainContent from '../components/ProjectDetails/MainContent'
import ToolsArea from '../components/ProjectDetails/ToolsArea'
import { useGetProjectQuery } from '../services/projectsApi'
import { useFetchDatasetQuery } from '../services/datasetApi'
import { useParams } from 'react-router-dom'
import { FileInfo } from '../types/file'

const ProjectDetails: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>()

  const { data: project } = useGetProjectQuery(projectId || '')
  const { data: dataset } = useFetchDatasetQuery(project?.datasetid || '')

  const [activeView, setActiveView] = useState<'project' | 'models' | 'dataset' | 'upload' | 'annotation'>('project')
  const [labelsFile, setLabelsFile] = useState<File | null>(null)
  const [labelsFileInfo, setLabelsFileInfo] = useState<FileInfo | null>(null)

  const handleLabelsFileSelect = (file: File | null) => {
    setLabelsFile(file)
    if (file) {
      const fileInfo: FileInfo = {
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      }
      setLabelsFileInfo(fileInfo)
    } else {
      setLabelsFile(null)
    }
  }

  return (
    <Container fluid className="project-details">
      {project && <TopBar activeView={activeView} onViewChange={setActiveView} project={project} />}
      <Row className="project-details-content">
        <Col md={3} className="project-sidebar">
          <ToolsArea
            project={project}
            activeView={activeView}
            onLabelsFileSelect={handleLabelsFileSelect}
            labelsFile={labelsFile}
            labelsFileInfo={labelsFileInfo}
          />
        </Col>
        <Col md={9} className="project-main-content">
          {dataset && <MainContent activeView={activeView} />}
        </Col>
      </Row>
    </Container>
  )
}

export default ProjectDetails
