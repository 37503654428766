import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Spinner, Form, Button } from 'react-bootstrap'
import { FaPencilAlt, FaExclamationTriangle } from 'react-icons/fa'
import {
  selectSelectedModelId,
  selectModelIsLoading,
  selectModelError,
  setSelectedModel,
  clearSelectedModel,
  selectSelectedModel,
} from '../../store/slices/modelSlice'
import { useGetModelQuery, useUpdateModelMutation, useDeleteModelMutation, modelsApi } from '../../services/modelsApi'
import ModelGenerationView from '../ModelGenerationView'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import ModelMetricsCard from '../ModelMetricsCard'

const ModelView: React.FC = () => {
  const dispatch = useAppDispatch()
  const selectedModelId = useAppSelector(selectSelectedModelId)
  const isLoading = useAppSelector(selectModelIsLoading)
  const error = useAppSelector(selectModelError)
  const selectedModel = useAppSelector(selectSelectedModel)

  const { data: modelData } = useGetModelQuery(selectedModelId || '', { skip: !selectedModelId })

  const [modelName, setModelName] = useState(modelData?.name || '')
  const [isEditingName, setIsEditingName] = useState(false)
  const [updateModel] = useUpdateModelMutation()
  const [deleteModel] = useDeleteModelMutation()

  useEffect(() => {
    if (modelData?.name) {
      setModelName(modelData.name)
    }
    dispatch(setSelectedModel(modelData || null))
    dispatch(modelsApi.util.invalidateTags([{ type: 'Model' }]))
  }, [dispatch, modelData])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModelName(e.target.value)
  }

  const handleNameBlur = async () => {
    if (modelData?.id && modelName !== modelData.name) {
      try {
        const updatedModel = await updateModel({
          id: modelData.id,
          modelData: { name: modelName },
        }).unwrap()
        dispatch(setSelectedModel(updatedModel))
      } catch (err) {
        console.error('Failed to update model name:', err)
      }
    }
    setIsEditingName(false)
  }

  const handleDeleteModel = async (modelId: string | undefined) => {
    if (modelId) {
      const res = await deleteModel(modelId)
      console.log('res', res)
      dispatch(clearSelectedModel())
    }
  }

  if (isLoading) {
    return (
      <Card className="model-view">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    )
  }

  if (error) {
    return (
      <Card className="model-view">
        <Card.Body>
          <p className="text-danger">{error}</p>
        </Card.Body>
      </Card>
    )
  }

  const showModelGenerationView = ['UNKNOWN', 'INITIALIZED', 'TRAINING'].includes(modelData?.state || '')

  return (
    <>
      {selectedModel ? (
        <>
          <Row className="project-view-header">
            <Col xs={5}>
              {isEditingName ? (
                <Form.Control
                  type="text"
                  value={modelName}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                  autoFocus
                  style={{ maxWidth: '300px' }}
                />
              ) : (
                <div className="position-relative" style={{ display: 'inline-block' }}>
                  <h2 className="model-name" style={{ marginRight: '25px', display: 'inline-block' }}>
                    {selectedModel?.name || 'Unknown Model'}
                  </h2>
                  <FaPencilAlt
                    style={{
                      cursor: 'pointer',
                      fontSize: '0.8em',
                      position: 'absolute',
                      top: '0',
                      right: '-20px',
                      color: 'blue',
                    }}
                    onClick={() => setIsEditingName(true)}
                  />
                </div>
              )}
              <div className="model-details-metadata mt-1">
                Created: {modelData?.created_at ? new Date(selectedModel.created_at).toLocaleString() : 'N/A'}
              </div>
            </Col>
            <Col xs={5}>
              <p className="model-details-metadata mb-4">
                View and manage your trained model. Monitor its performance and make adjustments as needed.
              </p>
            </Col>
            <Col xs={2}>
              <Button disabled={['IN_SERVICE', 'CREATING'].includes(modelData?.deployment?.status || '')} onClick={() => handleDeleteModel(modelData?.id || undefined)}>Delete Model</Button>
            </Col>
          </Row>

          <div className="model-view-container">
            <Card className="model-view" style={{ border: 'none' }}>
              <Card.Body>
                {showModelGenerationView && selectedModel ? (
                  <ModelGenerationView />
                ) : selectedModel ? (
                  <ModelMetricsCard />
                ) : null}
              </Card.Body>
            </Card>
          </div>
        </>
      ) : (
        <Card
          className="text-center shadow"
          style={{
            width: '50%',
            margin: '100px auto',
            padding: '20px',
            border: 'none',
            borderRadius: '10px',
            backgroundColor: '#2a2a2a',
          }}
        >
          <Card.Body>
            <FaExclamationTriangle size={60} color="red" className="mb-4" />
            <Card.Title style={{ fontSize: '24px', fontWeight: 'bold', color: '#ffffff' }}>
              No Model Selected
            </Card.Title>
            <Card.Text style={{ fontSize: '16px', color: '#6c757d' }}>
              Select a model, or you can generate a new model by selecting &quot;New Model&quot; on the left hand side.
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

export default ModelView
