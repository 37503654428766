import React, { useState } from 'react'
import { Button, Card, CardBody, Form, Row } from 'react-bootstrap'
import BulkTagManagerModal from '../modals/BulkTagManagerModal'
// import DatasetSourceDropdown from './DatasetSourceDropdown'

const AnnotationTools: React.FC = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {/*<Card className="gallery-tools mb-3">*/}
      {/*  <Card.Title>Filter for dataset</Card.Title>*/}
      {/*  <CardBody>*/}
      {/*    <Row>*/}
      {/*      <DatasetSourceDropdown />*/}
      {/*    </Row>*/}
      {/*  </CardBody>*/}
      {/*</Card>*/}
      <Card className="gallery-tools mb-3">
        <CardBody>
          <Row>
            <Button onClick={() => setShowModal(true)}>Tag Manager</Button>
          </Row>
          <BulkTagManagerModal show={showModal} onHide={() => setShowModal(false)} />
        </CardBody>
      </Card>
    </>
  )
}

export default AnnotationTools
