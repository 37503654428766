// src/store/themeSlice.ts

import { createSlice } from '@reduxjs/toolkit'

export interface ThemeState {
  isDarkMode: boolean
  // Add more theme properties as needed
}

const initialState: ThemeState = {
  isDarkMode: true,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.isDarkMode = !state.isDarkMode
    },
    // Add more theme-related actions as needed
  },
})

export const { toggleTheme } = themeSlice.actions

export default themeSlice.reducer
