// src/components/PredictionsView.tsx

import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap'
import { Model } from '../../types/model'
import { useQueryPredictionsMutation } from '../../services/predictionApi'
import { Prediction, PredictionMetadata, QueryPredictionRequest } from '../../types/prediction'
import { setSelectedModel } from '../../store/slices/modelSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  clearEditingPrediction,
  selectAnnotatedContentIds,
  selectAllBoundingBoxes,
  selectFilteredBoundingBoxes,
  selectEditingPrediction,
  setAllBoundingBoxes,
  setFilteredBoundingBoxes,
  setEditingPrediction,
  clearAllBoundingBoxes,
  clearFilteredBoundingBoxes,
} from '../../store/slices/annotationSlice'
import { useCreateAnnotationMutation } from '../../services/annotationApi'
import { addPrediction, removePrediction, selectSelectedPredictionTags } from '../../store/slices/predictionSlice'
import { RootState } from '../../store'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import { useGetTagsQuery } from '../../services/tagApi'
import { contentApi } from '../../services/contentApi'
import { CreateBBoxAnnotationReq } from '../../types/requests'
import { useRunBatchInferenceMutation } from '../../services/modelsApi'
import { AnnotationDataBoundingBox } from '../../types/annotation'
import BBoxMetadata from '../tools/BBoxMetadata'
import {selectActiveTab, selectFilterTags, selectSelectedProject, setActiveTab} from '../../store/slices/projectSlice'
import { projectsApi } from '../../services/projectsApi'
import PredictionStatisticsTable from '../PredictionStatisticsTable'
import ClassificationMetadata from '../tools/ClassificationMetadata'
import PredictionImage from '../PredictionImage'
import ImageAnnotationComponentV2 from '../Annotation/ImageAnnotationComponentV2'
import { Tag } from '../../types/tag'
import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react'

interface PredictionsViewProps {
  model: Model
  page: number
  threshold: { min: number; max: number }
  cardSize: number
}

const PredictionsView: React.FC<PredictionsViewProps> = ({ model, page, threshold, cardSize }) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const selectedFilterTags = useAppSelector(selectFilterTags)

  // used in filtering out images that have already been confirmed
  // TODO: Ensure this works as intended...the content ID may change...need to check that
  const annotatedContentIds = useAppSelector(selectAnnotatedContentIds)
  const activeTab = useAppSelector(selectActiveTab)
  const editingPrediction = useAppSelector(selectEditingPrediction)
  const allBoundingBoxes = useAppSelector(selectAllBoundingBoxes)
  const filteredBoundingBoxes = useAppSelector(selectFilteredBoundingBoxes)
  const selectedPredictionTags = useAppSelector(selectSelectedPredictionTags)

  const [selectedThreshold, setSelectedThreshold] = useState(threshold)

  const stableSelectedThreshold = useMemo(() => selectedThreshold, [selectedThreshold])
  const [filteredPredictions, setFilteredPredictions] = useState<Prediction[]>([])

  const [predictions, setPredictions] = useState<Prediction[]>([])

  const [runBatchInference, { isLoading: isBatchRunning }] = useRunBatchInferenceMutation()
  // The predictions selected via checkbox in the gallery view

  const selectedPredictions = useAppSelector((state: RootState) => state.prediction.selectedPredictions)
  // Used when accepting the list of annotations

  const [createAnnotation] = useCreateAnnotationMutation()
  // const stableSelectedPredictionTags = useMemo(() => selectedPredictionTags, [/* dependencies that change the tags */])

  const handleImageModalClose = () => {
    dispatch(clearEditingPrediction())
  } // Close modal

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const [queryPredictions, { isLoading: isPredictionsLoading }] = useQueryPredictionsMutation()

  useEffect(() => {
    if (activeTab === model.id) {
      try {
        const queryParams: QueryPredictionRequest = {
          filters: [{ key: 'modelid', value: model.id }],
          sort_key: 'confidence',
          sort_val: -1,
          page: page - 1,
          limit: 25,
        }
        queryPredictions(queryParams)
          .unwrap()
          .then((result) => {
            setPredictions(result.predictions)
          })
      } catch (error) {
        console.error('Failed to fetch predictions:', error)
      }
    }
  }, [model.id, threshold.min, page, activeTab, queryPredictions])

  useEffect(() => {
    setTimeout(() => dispatch(setSelectedModel(model)), 0)
  }, [dispatch, model])

  // Filter BBoxPredictionsTab whenever BBoxPredictionsTabData or annotatedContentIds change
  useEffect(() => {
    if (predictions?.length > 0) {
      const filterOutAnnotated = predictions.filter(
        (prediction: Prediction) => !annotatedContentIds.includes(prediction.contentid),
      )
      const filteredConfidence = filterOutAnnotated.filter((p) => {
        return !!p.predictions.find((predicted) => {
          return predicted.confidence >= threshold.min / 100 && predicted.confidence <= threshold.max
        })
      })
      if (selectedFilterTags?.length > 0) {
        const filteredBBoxes = filteredConfidence.filter((bbox) =>
          selectedFilterTags?.map((t) => t.id).includes(bbox.id),
        )
        setFilteredPredictions(filteredBBoxes)
      } else {
        setFilteredPredictions(filteredConfidence)
      }
    }
  }, [predictions, annotatedContentIds, threshold.min, threshold.max])

  useEffect(() => {
    const predBoundingBoxes = editingPrediction?.predictions.map((bbox: PredictionMetadata) => {
      if (project?.annotation_type === 'bounding_box') {
        return {
          name: tags?.tags?.find((t) => t.name === bbox.class_name)?.id,
          xmin: bbox.bounding_boxes?.xmin,
          ymin: bbox.bounding_boxes?.ymin,
          xmax: bbox.bounding_boxes?.xmax,
          ymax: bbox.bounding_boxes?.ymax,
          confidence: bbox.confidence,
          class_index: bbox.class_index,
        } as AnnotationDataBoundingBox
      } else {
        return {
          name: tags?.tags?.find((t) => t.name === bbox.class_name)?.id,
          xmin: 0,
          ymin: 0,
          xmax: 0,
          ymax: 0,
          confidence: bbox.confidence,
          class_index: bbox.class_index,
        } as AnnotationDataBoundingBox
      }
    })

    if (allBoundingBoxes.length === 0 && predBoundingBoxes && predBoundingBoxes.length > 0) {
      dispatch(setAllBoundingBoxes(predBoundingBoxes))
    }
    console.log('prediction bboxes:', predBoundingBoxes?.length)
  }, [
    editingPrediction,
    tags,
    selectedPredictionTags,
    activeTab,
    project?.annotation_type,
    dispatch,
    stableSelectedThreshold.min,
    stableSelectedThreshold.max,
    allBoundingBoxes.length,
  ])

  useEffect(() => {
    // filter here for tagId
    const filteredForTags =
      allBoundingBoxes?.filter((bBox) => {
        // default to showing everything
        if (selectedPredictionTags.length === 0) {
          return true
        }
        return selectedPredictionTags.find((t: Tag) => t.id === bBox.name)
      }) || []

    const filteredForThreshold = filteredForTags.filter((bBox) => {
      if (bBox.confidence) {
        console.log(
          bBox.confidence >= stableSelectedThreshold.min / 100 && bBox.confidence <= stableSelectedThreshold.max / 100,
        )
        return (
          bBox.confidence >= stableSelectedThreshold.min / 100 && bBox.confidence <= stableSelectedThreshold.max / 100
        )
      }
      return true
    })

    console.log('filteredForTags:', filteredForTags.length)
    console.log('filteredForThreshold:', filteredForThreshold.length)

    if (filteredForTags && activeTab !== 'annotated' && activeTab !== 'unannotated') {
      dispatch(setFilteredBoundingBoxes(filteredForThreshold))
    }
  }, [
    editingPrediction,
    tags,
    selectedPredictionTags,
    activeTab,
    project?.annotation_type,
    dispatch,
    stableSelectedThreshold.min,
    stableSelectedThreshold.max,
    allBoundingBoxes,
  ])

  const handleNextImage = () => {
    const predictionIdx = filteredPredictions?.findIndex((p) => p.id === editingPrediction?.id)
    if (predictionIdx < filteredPredictions.length - 1) {
      dispatch(setEditingPrediction(filteredPredictions?.[predictionIdx + 1]))
      dispatch(clearAllBoundingBoxes())
      dispatch(clearFilteredBoundingBoxes())
    }
  }

  const handlePrevImage = () => {
    const predictionIdx = filteredPredictions?.findIndex((p) => p.id === editingPrediction?.id)
    if (predictionIdx > 0) {
      dispatch(setEditingPrediction(filteredPredictions?.[predictionIdx - 1]))
      dispatch(clearAllBoundingBoxes())
      dispatch(clearFilteredBoundingBoxes())
    }
  }

  const handleGridPredictionClick = (prediction: Prediction) => {
    if (prediction && activeTab !== 'annotated' && activeTab !== 'unannotated') {
      dispatch(setEditingPrediction(prediction))
      dispatch(clearAllBoundingBoxes())
      dispatch(clearFilteredBoundingBoxes())
    }
  }

  const handleThresholdChange = (threshold: ChangeResult) => {
    setSelectedThreshold({ min: threshold.minValue, max: threshold.maxValue })
  }

  const handleTogglePrediction = (prediction: Prediction) => {
    console.log('toggling prediction: ', prediction)
    if (selectedPredictions.some((p) => p.id === prediction.id)) {
      dispatch(removePrediction(prediction.id))
    } else {
      dispatch(addPrediction(prediction))
    }
  }

  const handleApplyToProject = (modelId: string) => {
    try {
      runBatchInference({ id: modelId, thumbnail_size: 640 })
    } catch (error) {
      console.error('Failed to run batch inference:', error)
    }
  }

  const handleAcceptPredictions = async () => {
    if (editingPrediction) {
      try {
        const annotationTagIds: string[] = Array.from(new Set(filteredBoundingBoxes.map((a) => a.name)))
        // Create Annotation request CreateAnnotationReq
        const annoToCreate: CreateBBoxAnnotationReq = {
          content_id: editingPrediction.contentid || '',
          dataset_id: project?.datasetid || '',
          metadata: project?.annotation_type === 'bounding_box' ? { bounding_boxes: filteredBoundingBoxes } : undefined,
          project_id: project?.id || '',
          tag_id: annotationTagIds,
          // thumbnailSize: 640,
        }

        const createdAnno = await createAnnotation(annoToCreate)
        console.log(createdAnno)
        // Invalidate the relevant cache to refresh the content
        dispatch(
          contentApi.util.invalidateTags([
            { type: 'AnnotatedContent', id: createdAnno?.data?.contentid },
            { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
            { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
            { type: 'Content', id: createdAnno?.data?.contentid || '' },
          ]),
        )
        dispatch(projectsApi.util.invalidateTags([{ type: 'Project', id: project?.id }]))
      } catch (error) {
        console.error('Failed to create annotation:', error)
      }
      handleNextImage()
    } else {
      for (const prediction of selectedPredictions) {
        try {
          await createAnnotation({
            content_id: prediction.contentid,
            dataset_id: model.datasetid,
            project_id: model.projectid,
            tag_id: prediction.predictions.map((p) => p.tagid),
            metadata:
              project?.annotation_type === 'bounding_box'
                ? {
                  bounding_boxes: prediction.predictions.flatMap((p) =>
                    p.bounding_boxes ? Object.values(p.bounding_boxes) : [],
                  ),
                }
                : undefined,
          }).unwrap()
        } catch (error) {
          console.error('Failed to create annotation:', error)
        }
      }
      dispatch(
        contentApi.util.invalidateTags([
          { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
          { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
        ]),
      )
    }
  }

  const renderImageAnnotator = () => {
    return (
      <Modal show={!!editingPrediction} onHide={handleImageModalClose} fullscreen>
        <Modal.Header className="bg-dark text-white" closeButton>
          <Modal.Title>Image Annotation Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white">
          <div className="annotation-tab-content">
            <div className="annotorious-container">
              <Row>
                <div className="annotation-controls">
                  <Row className="justify-content-between">
                    <Col xs={3} />
                    <Col>
                      <Button onClick={handlePrevImage} className="annotation-nav-btn" aria-label="Previous image">
                        <ChevronLeft size={24} />
                      </Button>
                    </Col>
                    <Col className="text-center">
                      <Button variant="success" className="mx-2 mt-1" onClick={handleAcceptPredictions}>
                        Submit
                      </Button>
                    </Col>
                    <Col className="text-end">
                      <Button onClick={handleNextImage} className="annotation-nav-btn" aria-label="Next image">
                        <ChevronRight size={24} />
                      </Button>
                    </Col>
                    <Col xs={2} />
                  </Row>
                </div>
              </Row>
              <Row>
                <Col xs={3} style={{ paddingRight: '10px', paddingBottom: '10px' }}>
                  <Row>
                    <Col>
                      <MultiRangeSlider
                        min={0}
                        max={100}
                        step={10}
                        minValue={stableSelectedThreshold.min}
                        maxValue={stableSelectedThreshold.max}
                        onInput={(newThreshold) => handleThresholdChange(newThreshold)}
                      />
                      <PredictionStatisticsTable modelId={model.id} />
                    </Col>
                  </Row>
                </Col>
                <Col xs="7">
                  <Card
                    style={{
                      height: '700px',
                      overflow: 'hidden',
                      border: 'none',
                      backgroundColor: 'grey',
                      position: 'relative',
                    }}
                  >
                    {editingPrediction && (
                      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ImageAnnotationComponentV2
                          contentId={editingPrediction.contentid}
                          useBBoxes={project?.annotation_type === 'bounding_box'}
                        />
                      </div>
                    )}
                  </Card>
                </Col>

                <Col xs={2} style={{ paddingLeft: '10px' }}>
                  {project?.annotation_type === 'bounding_box' && (
                    <BBoxMetadata
                      key={editingPrediction?.contentid || ''}
                      contentId={editingPrediction?.contentid || ''}
                    />
                  )}
                  {project?.annotation_type === 'classification' && (
                    <ClassificationMetadata
                      key={editingPrediction?.contentid || ''}
                      contentId={editingPrediction?.contentid || ''}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div className="annotation-tab-content">
      <div className="annotorious-container">
        {!editingPrediction && (
          <Row className="pt-3">
            <Col xs={2}>{model.name}</Col>
            <Col xs={2}>
              {project?.annotation_type === 'bounding_box' && (
                <>
                  <strong>mAP:</strong> {(parseFloat(model.metrics.ObjectiveMetric as string) * 100).toFixed(2)}%
                </>
              )}
            </Col>
            <Col xs={3}>
              {project?.annotation_type === 'bounding_box' && (
                <>
                  <strong>Smooth L1:</strong>{' '}
                  {(parseFloat(model.metrics['train:smooth_l1'] as string) * 100).toFixed(2)}%
                </>
              )}
              {project?.annotation_type === 'classification' && (
                <>
                  <strong>Accuracy:</strong> {(parseFloat(model.metrics.ObjectiveMetric as string) * 100).toFixed(2)}%
                </>
              )}
            </Col>
            <Col xs={3}>
              {project?.annotation_type === 'bounding_box' && (
                <>
                  <strong>Cross Entropy:</strong>{' '}
                  {(parseFloat(model.metrics['train:cross_entropy'] as string) * 100).toFixed(2)}%
                </>
              )}
            </Col>
            <Col xs={2} className="text-end">
              <Button
                size="lg"
                variant="success"
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(setActiveTab('annotated'))
                  handleApplyToProject(model.id)
                }}
                disabled={isBatchRunning || model.batch.status === 'RUNNING'}
                className="mt-2"
              >
                Re-apply
              </Button>
            </Col>
          </Row>
        )}

        {model.batch?.status?.toUpperCase().startsWith('COMPLETE') && (
          <Row style={{ overflow: 'hidden' }}>
            <Col>
              {isPredictionsLoading ? (
                <Spinner animation="border" variant="primary" />
              ) : editingPrediction ? (
                renderImageAnnotator()
              ) : filteredPredictions?.length > 0 ? (
                <div className="grid-view">
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {filteredPredictions.map((prediction: Prediction) => (
                      <>
                        {/*<HeatmapImage key={prediction.id} predictionId={prediction.id} isDroppedFile={false} cardSize={cardSize}/>*/}
                        <PredictionImage
                          key={prediction.id}
                          prediction={prediction}
                          onEdit={handleGridPredictionClick}
                          onToggle={handleTogglePrediction}
                          cardSize={cardSize}
                          isSelected={selectedPredictions?.some((p) => p.id === prediction.id)}
                        />
                      </>
                    ))}
                  </div>
                </div>
              ) : (
                <p>No new suggestive labels available.</p>
              )}
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default PredictionsView
