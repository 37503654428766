import { createApi } from '@reduxjs/toolkit/query/react'

import { PREDICTIONS_STATISTICS, PREDICTIONS_QUERY, PREDICTIONS_SAMPLE, PREDICTIONS_URL } from '../utils'
import baseQueryWithReauth from './baseQuery'
import {
  PredictionsStatisticsResp,
  QueryPredictionRequest,
  QueryPredictionsResp,
  SamplePredictionsResp,
} from '../types/prediction'

export const predictionApi = createApi({
  reducerPath: 'predictionApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Prediction', 'Heatmap'],
  endpoints: (builder) => ({
    getPredictionStatistics: builder.query<
    PredictionsStatisticsResp,
    {
      model_id: string
      uncertainty_threshold?: number
      tag_id?: string[]
    }
    >({
      query: (params) => ({
        url: PREDICTIONS_STATISTICS,
        method: 'GET',
        params,
      }),
      providesTags: ['Prediction'],
    }),

    queryPredictions: builder.mutation<QueryPredictionsResp, QueryPredictionRequest>({
      query: (body) => ({
        url: PREDICTIONS_QUERY,
        method: 'POST',
        body,
      }),
    }),

    getSamplePredictions: builder.query<
    SamplePredictionsResp,
    {
      model_id: string
      uncertainty_threshold: number
      tag_id?: string[]
      sample_count?: number
      thumbnail_size?: number
    }
    >({
      query: (params) => ({
        url: PREDICTIONS_SAMPLE,
        method: 'GET',
        params,
      }),
    }),

    getPredictionHeatmap: builder.query<string, { PredictionId: string }>({
      query: (PredictionId) => ({
        url: `${PREDICTIONS_URL}/${PredictionId.PredictionId}/heatmap`,
        method: 'GET',
        responseHandler: async (response) => {
          const blob = await response.blob()
          return URL.createObjectURL(blob)
        },
      }),
      transformResponse: (response: string) => response,
      // Merge incoming data instead of replacing it
      merge: (currentCache, newItems) => {
        return currentCache || newItems
      },
      // Only keep unused data in the cache for 300 seconds
      keepUnusedDataFor: 300,
      providesTags: (result, error, { PredictionId }) => [{ type: 'Heatmap', id: `${PredictionId}-image` }],
    }),
  }),
})

export const {
  useGetPredictionStatisticsQuery,
  useQueryPredictionsMutation,
  useGetSamplePredictionsQuery,
  useGetPredictionHeatmapQuery,
} = predictionApi
