import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, Badge, Button, Card, Col, Pagination, Row, Tab, Tabs } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { contentApi, useFetchAnnotatedContentQuery /*, useGetContentMetadataQuery*/ } from '../../services/contentApi'
import { useFetchModelsQuery } from '../../services/modelsApi'
import {
  annotationsApi,
  useCreateAnnotationMutation,
  useGetAnnotationStatisticsQuery,
} from '../../services/annotationApi'
import {
  clearAllBoundingBoxes,
  clearEditingPrediction,
  clearFilteredBoundingBoxes,
  selectFilteredBoundingBoxes,
  setAllBoundingBoxes,
  setFilteredBoundingBoxes,
} from '../../store/slices/annotationSlice'
import { selectActiveTab, selectFilterTags, selectSelectedProject, setActiveTab } from '../../store/slices/projectSlice'

import { normalizeBoundingBox } from '../../utils'
import { Content, ContentMetadata } from '../../types/content'
import { CreateBBoxAnnotationReq } from '../../types/requests'
import BBoxMetadata from '../tools/BBoxMetadata'

import MiniClassDistributionCard from '../MiniClassDistributionCard'
import ContentImage from '../tools/ContentImage'
import ModelAssistedLabeling from '../tools/ModelAssistedLabeling'
import MultiRangeSlider, { ChangeResult } from 'multi-range-slider-react'
import PredictionsView from './PredictionsView'
import ImageAnnotationComponentV2 from '../Annotation/ImageAnnotationComponentV2'
import { selectSelectedDatasetSource } from '../../store/slices/datasetSlice'

interface AnnotationViewProps {
  itemsPerPage: number
}

const AnnotationView: React.FC<AnnotationViewProps> = ({ itemsPerPage = 12 }) => {
  const project = useAppSelector(selectSelectedProject)
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector(selectActiveTab)
  const editingBoundingBoxes = useAppSelector(selectFilteredBoundingBoxes)
  const selectedFilterTags = useAppSelector(selectFilterTags)
  const selectedDatasetSource = useAppSelector(selectSelectedDatasetSource)

  const [pageForTabs, setPageForTabs] = useState({
    unannotated: 1,
    annotated: 1,
    suggestive_labels: 1,
  })

  const [showModelModal, setShowModelModal] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)
  const [selectedContentId, setSelectedContentId] = useState<string | null>(null)
  const [cardSize, setCardSize] = useState(295)
  const [tempMinThreshold, setTempMinThreshold] = useState<number>(10)
  const [tempMaxThreshold, setTempMaxThreshold] = useState<number>(80)
  const [queryDatasets, setQueryDatasets] = useState<ContentMetadata | undefined>(undefined)
  const [threshold, setThreshold] = useState<{ min: number; max: number }>({ min: 10, max: 100 })

  // Redux
  const handleModelModalOpen = () => setShowModelModal(true) // Open modal
  const handleModelModalClose = () => setShowModelModal(false) // Close modal
  const handleImageModalOpen = () => setShowImageModal(true) // Open modal

  const handleImageModalClose = () => {
    setSelectedContentId(null)
    dispatch(clearEditingPrediction())
    setShowImageModal(false)
  }

  // API Hooks
  const [createAnnotation] = useCreateAnnotationMutation()
  const { data: modelsData } = useFetchModelsQuery({ project_id: project?.id || '' })

  // const {
  //   data: metadata,
  // } = useGetContentMetadataQuery({
  //   project_id: project?.id || '',
  // }, { skip: !project?.id })

  useEffect(() => {
    if (selectedDatasetSource) {
      setQueryDatasets({ dataset: selectedDatasetSource })
    } else {
      setQueryDatasets(undefined)
    }
    dispatch(
      contentApi.util.invalidateTags([
        { type: 'Content', id: 'LIST' },
        { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
        { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
      ]),
    )
  }, [dispatch, selectedDatasetSource])

  const { data: annotatedContent } = useFetchAnnotatedContentQuery(
    {
      project_id: project?.id || '',
      dataset_id: project?.datasetid || '',
      limit: itemsPerPage,
      page: pageForTabs.annotated - 1,
      metadata: queryDatasets,
    },
    { skip: activeTab !== 'annotated' },
  )

  const { data: unannotatedContent } = useFetchAnnotatedContentQuery(
    {
      project_id: project?.id || '',
      dataset_id: undefined,
      limit: itemsPerPage,
      page: pageForTabs.unannotated - 1,
      metadata: queryDatasets,
    },
    { skip: activeTab !== 'unannotated' },
  )

  const { data: annoStats } = useGetAnnotationStatisticsQuery({
    projectId: project?.id || '',
    datasetId: project?.datasetid || '',
  })

  // Memoized Values
  const filteredContent = useMemo(() => {
    const contentToProcess = activeTab === 'annotated' ? annotatedContent?.content : unannotatedContent?.content || []

    // let selectedFilterTagIds: Set<any>
    // if (activeTab === 'annotated') {
    //   selectedFilterTagIds = new Set(contentToProcess?.map((t) => t.id))
    // }

    let filteredForTagIds: Content[] | undefined = []
    if (selectedFilterTags?.length > 0) {
      filteredForTagIds = contentToProcess?.filter((content) => {
        const contentTagIds = content.annotation?.[0]?.tagids || []
        return contentTagIds?.some(tagId => selectedFilterTags?.map(t => t.id).includes(tagId))
      })
    } else {
      filteredForTagIds = contentToProcess
    }

    return filteredForTagIds

    // // filter for metadata dataset name
    // return selectedDatasetSource
    //   ? filteredForTagIds?.filter((c) => c.metadata?.dataset === selectedDatasetSource)
    //   : filteredForTagIds
  }, [activeTab, unannotatedContent?.content, annotatedContent?.content, selectedFilterTags])

  useEffect(() => {
    if (selectedContentId) {
      const content = filteredContent?.find((c) => c.id === selectedContentId)

      if (content?.annotation?.length && content.annotation[0]?.metadata?.bounding_boxes) {
        const boundingBoxes = content.annotation[0].metadata.bounding_boxes.map((bbox, index) =>
          normalizeBoundingBox(bbox, index),
        )
        dispatch(setAllBoundingBoxes(boundingBoxes))

        if (selectedFilterTags?.length > 0) {
          const filteredBBoxes = boundingBoxes.filter((bbox) =>
            selectedFilterTags?.map((t) => t.id).includes(bbox.name),
          )
          dispatch(setFilteredBoundingBoxes(filteredBBoxes))
        } else {
          dispatch(setFilteredBoundingBoxes(boundingBoxes))
        }
      } else {
        dispatch(clearAllBoundingBoxes())
        dispatch(clearFilteredBoundingBoxes())
      }
    }
  }, [selectedContentId, filteredContent, unannotatedContent, activeTab, dispatch])

  // Event Handlers
  const handlePageChange = (pageNumber: number) => {
    setPageForTabs((prevPages) => ({
      ...prevPages,
      [activeTab.endsWith('ted') ? activeTab : 'suggestive_labels']: pageNumber,
    }))
  }

  const handleTabSelect = (tab: string | null) => {
    dispatch(clearAllBoundingBoxes())
    dispatch(clearFilteredBoundingBoxes())
    dispatch(setActiveTab(tab as 'unannotated' | 'annotated' | 'suggestive_labels'))
  }

  const handleImageClick = useCallback((image: Content) => {
    setSelectedContentId(image.id)
    handleImageModalOpen()
  }, [])

  const handlePrevImage = () => {
    const currIdx = filteredContent?.findIndex((c) => c.id === selectedContentId)
    if (currIdx && currIdx > 0) {
      setSelectedContentId(filteredContent?.[currIdx - 1]?.id || null)
    }
    dispatch(clearAllBoundingBoxes())
    dispatch(clearFilteredBoundingBoxes())
  }

  const handleNextImage = async () => {
    const currIdx = filteredContent?.findIndex((c) => c.id === selectedContentId)

    let contentLength = filteredContent?.length || 0
    if (activeTab === 'unannotated') {
      contentLength = unannotatedContent?.content?.length || 0
    }
    if (currIdx !== undefined && currIdx < contentLength - 1) {
      setSelectedContentId(filteredContent?.[currIdx + 1]?.id || null)
    }
    dispatch(clearAllBoundingBoxes())
    dispatch(clearFilteredBoundingBoxes())
  }

  const handleAcceptedAnnotations = async () => {
    if (selectedContentId) {
      const annoCreateUpdateRequest: CreateBBoxAnnotationReq = {
        content_id: selectedContentId,
        dataset_id: project?.datasetid || '',
        project_id: project?.id || '',
        tag_id: Array.from(new Set(editingBoundingBoxes.map((b) => b.name))),
        metadata: { bounding_boxes: editingBoundingBoxes },
      }

      await createAnnotation(annoCreateUpdateRequest).unwrap()

      // Invalidate both annotated and unannotated content lists to refresh data
      dispatch(
        contentApi.util.invalidateTags([
          { type: 'Content', id: selectedContentId },
          { type: 'AnnotatedContent', id: selectedContentId },
        ]),
      )

      dispatch(annotationsApi.util.invalidateTags([{ type: 'Annotation', id: selectedContentId }]))

      dispatch(clearAllBoundingBoxes())
      dispatch(clearFilteredBoundingBoxes())

      // Refetch content and await completion
      await Promise.all([
        dispatch(
          contentApi.endpoints.fetchAnnotatedContent.initiate({
            project_id: project?.id || '',
            dataset_id: project?.datasetid || '',
            limit: itemsPerPage,
            page: pageForTabs.annotated - 1,
          }),
        ).unwrap(),
        dispatch(
          contentApi.endpoints.fetchAnnotatedContent.initiate({
            project_id: project?.id || '',
            dataset_id: undefined,
            limit: itemsPerPage,
            page: pageForTabs.unannotated - 1,
          }),
        ).unwrap(),
      ])

      await handleNextImage() // Navigate to the next image after submitting annotation
    }
  }

  const handleThresholdChange = (newThreshold: ChangeResult) => {
    setTempMinThreshold(newThreshold.minValue)
    setTempMaxThreshold(newThreshold.maxValue)
  }

  const handleApplyThreshold = () => {
    setThreshold({ min: tempMinThreshold > 0 ? tempMinThreshold : 0.1, max: tempMaxThreshold })
  }

  // Render Functions
  const renderTabTitle = (title: string, count: number | null = null) => (
    <>
      {title}
      {count !== null && (
        <Badge bg="primary" className="ms-2">
          {count}
        </Badge>
      )}
    </>
  )

  const renderContent = useMemo(() => {
    // This setup limites the number of times renderContent is ran
    function renderAllContentFunction(content: Content[] | undefined) {
      return (
        <div className="grid-view">
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {content?.map((image: Content, idx: number) => (
              <ContentImage key={idx} content={image} cardSize={cardSize} onImageClick={handleImageClick} />
            ))}
          </div>
        </div>
      )
    }
    return renderAllContentFunction
  }, [cardSize, handleImageClick])

  const renderImageAnnotator = () => {
    return (
      <Modal show={showImageModal} onHide={handleImageModalClose} fullscreen>
        <Modal.Header className="bg-dark text-white" closeButton>
          <Modal.Title>Image Annotation Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark text-white">
          <div className="annotation-tab-content">
            <div className="annotorious-container">
              <Row>
                <Col xs={3} style={{ paddingRight: '10px', marginTop: '68px' }}>
                  <MiniClassDistributionCard showButtons={false} />
                </Col>
                <Col xs={7}>
                  <div className="annotation-controls">
                    <Row className="justify-content-between">
                      <Col>
                        <Button onClick={handlePrevImage} className="annotation-nav-btn" aria-label="Previous image">
                          <ChevronLeft size={24} />
                        </Button>
                      </Col>
                      <Col className="text-center">
                        <Button variant="success" className="mx-2 mt-1" onClick={handleAcceptedAnnotations}>
                          Submit
                        </Button>
                      </Col>
                      <Col className="text-end">
                        <Button onClick={handleNextImage} className="annotation-nav-btn" aria-label="Next image">
                          <ChevronRight size={24} />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Card style={{ height: '700px', overflow: 'hidden', border: 'none', backgroundColor: 'grey' }}>
                    {selectedContentId && (
                      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ImageAnnotationComponentV2
                          contentId={selectedContentId}
                          useBBoxes={project?.annotation_type === 'bounding_box'}
                        />
                      </div>
                    )}
                  </Card>
                </Col>

                <Col xs={2} style={{ paddingLeft: '10px', marginTop: '68px' }}>
                  {selectedContentId && <BBoxMetadata key={selectedContentId} contentId={selectedContentId} />}
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div className="annotation-view-container">
      {selectedContentId ? (
        renderImageAnnotator()
      ) : (
        <div className="annotation-tabs">
          <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="annotation-tabs">
            <Tab
              eventKey="unannotated"
              title={renderTabTitle('Unannotated', annoStats?.statistics.total_unannotated_images || 0)}
              tabClassName="unannotated-tab"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Row>
                <Col xs={3}>
                  {modelsData?.models?.some((model) => model.deployment?.status === 'IN_SERVICE') && (
                    <>
                      <Button
                        style={{
                          padding: '10px',
                          marginTop: '15px',
                          marginLeft: '30px',
                          backgroundColor: '#800085',
                          border: 'none',
                          color: 'white',
                          boxShadow: '0 0 15px 2px #00ccff', // Creates the glow effect
                        }}
                        onClick={handleModelModalOpen}
                      >
                        Assisted Labeling
                      </Button>

                      <Modal show={showModelModal} onHide={handleModelModalClose} size="lg">
                        <Modal.Header closeButton style={{ backgroundColor: '#202020' }}>
                          <Modal.Title style={{ backgroundColor: '#202020' }}>Available Models</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ backgroundColor: '#202020' }}>
                          <ModelAssistedLabeling />
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                </Col>

                <Col xs={6} className="pagination-controls-container">
                  <Pagination className="pagination-wrapper">
                    <Pagination.First onClick={() => handlePageChange(1)} disabled={pageForTabs.unannotated === 1} />
                    <Pagination.Prev
                      onClick={() => handlePageChange(pageForTabs.unannotated - 1)}
                      disabled={pageForTabs.unannotated === 1}
                    />
                    <Pagination.Item active>{pageForTabs.unannotated}</Pagination.Item>
                    <Pagination.Next
                      onClick={() => handlePageChange(pageForTabs.unannotated + 1)}
                      disabled={
                        pageForTabs.unannotated ===
                        Math.ceil((annoStats?.statistics.total_unannotated_images || 0) / itemsPerPage)
                      }
                    />
                    <Pagination.Last
                      onClick={() =>
                        handlePageChange(
                          Math.ceil((annoStats?.statistics.total_unannotated_images || 0) / itemsPerPage),
                        )
                      }
                      disabled={
                        pageForTabs.unannotated ===
                        Math.ceil((annoStats?.statistics.total_unannotated_images || 0) / itemsPerPage)
                      }
                    />
                  </Pagination>
                </Col>
                <Col xs={3} className="d-flex justify-content-end pagination-controls-container">
                  <label htmlFor="sizeSlider" className="me-2">
                    Image Size:
                  </label>
                  <input
                    id="sizeSlider"
                    type="range"
                    min="100"
                    max="600"
                    value={cardSize}
                    onChange={(e) => setCardSize(Number(e.target.value))}
                  />
                </Col>
              </Row>

              {activeTab === 'unannotated' && renderContent(filteredContent)}
            </Tab>

            <Tab
              eventKey="annotated"
              title={renderTabTitle('Annotated', annoStats?.statistics.total_annotated_images || 0)}
              tabClassName="annotated-tab"
              mountOnEnter={true}
              unmountOnExit={true}
            >
              <Row>
                <Col xs={3}></Col>

                <Col xs={6} className="pagination-controls-container">
                  <Pagination className="pagination-wrapper">
                    <Pagination.First onClick={() => handlePageChange(1)} disabled={pageForTabs.annotated === 1} />
                    <Pagination.Prev
                      onClick={() => handlePageChange(pageForTabs.annotated - 1)}
                      disabled={pageForTabs.annotated === 1}
                    />
                    <Pagination.Item active>{pageForTabs.annotated}</Pagination.Item>
                    <Pagination.Next
                      onClick={() => handlePageChange(pageForTabs.annotated + 1)}
                      disabled={
                        pageForTabs.annotated ===
                        Math.ceil((annoStats?.statistics.total_annotated_images || 0) / itemsPerPage)
                      }
                    />
                    <Pagination.Last
                      onClick={() =>
                        handlePageChange(Math.ceil((annoStats?.statistics.total_annotated_images || 0) / itemsPerPage))
                      }
                      disabled={
                        pageForTabs.annotated ===
                        Math.ceil((annoStats?.statistics.total_annotated_images || 0) / itemsPerPage)
                      }
                    />
                  </Pagination>
                </Col>
                <Col xs={3} className="d-flex justify-content-end pagination-controls-container">
                  <label htmlFor="sizeSlider" className="me-2">
                    Image Size:
                  </label>
                  <input
                    id="sizeSlider"
                    type="range"
                    min="100"
                    max="600"
                    value={cardSize}
                    onChange={(e) => setCardSize(Number(e.target.value))}
                  />
                </Col>
              </Row>
              {activeTab === 'annotated' && renderContent(filteredContent)}
            </Tab>

            {modelsData?.models
              ?.filter((model) => model.batch.status.startsWith('COMPLETE'))
              .map((model) => (
                <Tab
                  key={model.id}
                  eventKey={`${model.id}`}
                  title={renderTabTitle(`${model.name}`)}
                  tabClassName="suggestive-labels-tab"
                  mountOnEnter={true}
                  unmountOnExit={true}
                >
                  <Row className="pt-4">
                    <Col xs={3} className="d-flex justify-content-end pagination-controls-container">
                      <label htmlFor="sizeSlider" className="me-2">
                        Image Size:
                      </label>
                      <input
                        id="sizeSlider"
                        type="range"
                        min="100"
                        max="600"
                        value={cardSize}
                        onChange={(e) => setCardSize(Number(e.target.value))}
                      />
                    </Col>
                    <Col xs={3} className="d-flex justify-content-end pagination-controls-container"></Col>
                    <Col xs={4}>
                      <MultiRangeSlider
                        min={0}
                        max={100}
                        step={10}
                        minValue={threshold.min}
                        maxValue={threshold.max}
                        onInput={(newThreshold) => handleThresholdChange(newThreshold)}
                      />
                    </Col>
                    <Col>
                      <Button onClick={handleApplyThreshold}>Apply Threshold</Button>
                    </Col>
                  </Row>
                  <PredictionsView
                    model={model}
                    threshold={threshold}
                    page={pageForTabs.suggestive_labels}
                    cardSize={cardSize}
                  />
                </Tab>
              ))}
          </Tabs>
        </div>
      )}
    </div>
  )
}

export default AnnotationView
