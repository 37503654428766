import React from 'react'
import { Card, ListGroup, Spinner } from 'react-bootstrap'
import { useViewContentQuery } from '../../services/contentApi'
import { useAppSelector } from '../../store/hooks'
import { selectFilteredBoundingBoxes } from '../../store/slices/annotationSlice'
import { formatNumber } from '../../utils'
import BBoxTagDropDowns from './BBoxTagDropDown'
import { AnnotationDataBoundingBox } from '../../types/annotation'
import { selectSelectedProject } from '../../store/slices/projectSlice'

interface MetadataDisplayProps {
  contentId: string | undefined
  handleTagRemove?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const BBoxMetadata: React.FC<MetadataDisplayProps> = ({ contentId }) => {
  const project = useAppSelector(selectSelectedProject)
  const editingBoundingBoxes = useAppSelector(selectFilteredBoundingBoxes)

  const { data: contentData, isLoading: contentLoading } = useViewContentQuery(
    {
      ContentID: contentId || '',
      project_id: project?.id || '',
      dataset_id: project?.datasetid || '',
    },
    {
      skip: !contentId,
    },
  )

  if (contentLoading || !editingBoundingBoxes) {
    return <Spinner animation="border" variant="primary" />
  }

  return (
    <>
      <Card className="metadata-display">
        <Card.Header>
          <h5 className="mb-0">Metadata</h5>
        </Card.Header>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <strong>Total Tags:</strong> <span className="text-muted">{editingBoundingBoxes.length}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Unique Tags:</strong>{' '}
            <span className="text-muted">{new Set(editingBoundingBoxes.map((b) => b.name)).size}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Size:</strong> <span className="text-muted">{formatNumber(contentData?.size || 0)} bytes</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>MIME Type:</strong> <span className="text-muted">{contentData?.content_type}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Width:</strong> <span className="text-muted">{contentData?.width || 0}</span>
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Height:</strong> <span className="text-muted">{contentData?.height || 0}</span>
          </ListGroup.Item>
        </ListGroup>

        <Card.Header>
          <h5 className="mb-0">Tags</h5>
        </Card.Header>
        <Card.Body>
          <BBoxTagDropDowns boundingBoxes={editingBoundingBoxes as AnnotationDataBoundingBox[]} />
        </Card.Body>
      </Card>
    </>
  )
}

export default BBoxMetadata
