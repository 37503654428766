import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Button, Form, Row, Col, Image } from 'react-bootstrap'
import {
  projectsApi,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} from '../../services/projectsApi'
import UploadView from '../views/UploadView'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectSelectedDatasetSource,
  selectFilesUploaded,
  selectSelectedFiles,
  selectSelectedTiffFiles,
  clearSelectedDatasetSource,
} from '../../store/slices/datasetSlice'
import { useNavigate } from 'react-router-dom'
import { clearSelectedProject, setSelectedProject } from '../../store/slices/projectSlice'

interface CreateProjectModalProps {
  show: boolean
  onHide: () => void
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({ show, onHide }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const selectedFiles = useAppSelector(selectSelectedFiles)
  const selectedTiffFiles = useAppSelector(selectSelectedTiffFiles)
  const filesUploaded = useAppSelector(selectFilesUploaded)
  const selectedDatasetSource = useAppSelector(selectSelectedDatasetSource)

  const [projectId, setProjectId] = useState<string | null>(null)
  const [projectName, setProjectName] = useState('')
  const [description, setDescription] = useState('')
  const [projectType, setProjectType] = useState<'bounding_box' | 'classification' | null>(null)
  const [projectCreated, setProjectCreated] = useState(false)

  const [createProject, { isLoading: isCreating }] = useCreateProjectMutation()
  const [updateProject] = useUpdateProjectMutation()
  const [deleteProject] = useDeleteProjectMutation()

  useEffect(() => {
    if (show) {
      // Reset form
      setProjectName('')
      setDescription('')
      setProjectType(null)
      dispatch(clearSelectedDatasetSource())
      dispatch(clearSelectedProject())
    }
  }, [dispatch, show])

  useEffect(() => {
    if (show && !projectCreated) {
      if (!!projectName && projectType && selectedFiles.length + selectedTiffFiles.length > 0) {
        createProject({
          name: projectName,
          description: `Created: ${new Date().toLocaleString()}`,
          annotation_type: projectType,
          license: '',
        })
          .unwrap()
          .then((newProject) => {
            setProjectId(newProject.id)
            setProjectName(newProject.name)
            setDescription(newProject.description)
            setProjectCreated(true)
            dispatch(setSelectedProject(newProject))
          })
          .catch((e) => {
            console.log(`Error creating project: ${e}`)
          })
      }
    }
  }, [createProject, projectCreated, projectName, projectType, selectedFiles.length, selectedTiffFiles.length, show])

  const handleCancel = () => {
    if (projectId) {
      deleteProject(projectId)
      setProjectId(null)
      setProjectName('')
      setDescription('')
      setProjectCreated(false)
      dispatch(projectsApi.util.invalidateTags([{ type: 'Project' }]))
    }
    onHide()
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (projectId) {
      await updateProject({ id: projectId, data: { name: projectName, description: description } })

      dispatch(projectsApi.util.invalidateTags([{ type: 'Project' }]))
      navigate(`/ProjectDetails/${projectId}`)
    }
    onHide()
  }

  const createBtnVariant = useCallback(() => {
    return isCreating ? 'primary' : !projectName || !projectType ? 'error' : !filesUploaded ? 'error' : 'success'
  }, [filesUploaded, isCreating, projectName, projectType])

  const imageStyle = {
    width: 'auto',
    height: '320px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  }

  const selectedImageStyle = {
    borderColor: '#007bff',
    borderWidth: '10px',
    boxShadow: '0 0 20px rgba(0, 123, 255, 0.5)',
  }

  return (
    <Modal show={show} onHide={onHide} centered size="lg" className="create-project-modal">
      <Modal.Header className="d-flex justify-content-between align-items-center">
        <Modal.Title>Create New Project</Modal.Title>
        <Button variant="danger" onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col xs={6} className="text-center">
              <Image
                src="/bbox-image.jpg"
                alt="Bounding Box"
                style={{
                  ...imageStyle,
                  ...(projectType === 'bounding_box' ? selectedImageStyle : {}),
                }}
                onClick={() => setProjectType('bounding_box')}
              />
              <p>Bounding Box</p>
            </Col>
            <Col xs={6} className="text-center">
              <Image
                src="/class-image.jpg"
                alt="Classification"
                style={{
                  ...imageStyle,
                  ...(projectType === 'classification' ? selectedImageStyle : {}),
                }}
                onClick={() => setProjectType('classification')}
              />
              <p>Classification</p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="ms-3">Name</Form.Label>
                <Form.Control
                  type="text"
                  className="small"
                  placeholder="Enter project name"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  onBlur={(e) => setProjectName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter project description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </Row>
          <hr />

          <UploadView />
          <Row>
            <Button
              className="text-center mb-2"
              variant={createBtnVariant()}
              type="submit"
              disabled={!projectName || !projectType || isCreating || !filesUploaded}
            >
              {isCreating
                ? 'Creating...'
                : !projectType
                  ? 'Please select project type'
                  : !projectName
                    ? 'Please provide a name'
                    : // : (!!selectedDatasetSource && !filesUploaded)
                  //   ? 'Must set dataset name'
                    selectedFiles.length + selectedTiffFiles.length < 1 && !projectCreated
                      ? 'Provide Initial Upload'
                      : !filesUploaded
                        ? 'Press Upload to Ingest Initial Dataset'
                        : 'Go to Project'}
            </Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateProjectModal
