// src/services/authApi.ts

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from '../types/user'
import { RootState } from '../store'
import { PASSWORD_RESET_URL, REFRESH_TOKEN_URL, USER_DETAIL_URL, LOGIN_URL } from '../utils'
import { LoginCredentials, LoginResponse, OkResp, ResetPasswordData } from '../types/responses'

export const authApi = createApi({
  reducerPath: 'authApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      console.log(`${process.env.REACT_APP_API_ENDPOINT}`)
      const token = (getState() as RootState).auth.accessToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginCredentials>({
      query: (credentials) => ({
        url: LOGIN_URL,
        method: 'POST',
        body: credentials,
      }),
    }),

    fetchUserProfile: builder.query<User, void>({
      query: () => USER_DETAIL_URL,
    }),

    requestPasswordReset: builder.mutation<OkResp, string>({
      query: (email) => ({
        url: PASSWORD_RESET_URL,
        method: 'GET',
        params: { email },
      }),
    }),

    resetPassword: builder.mutation<void, ResetPasswordData>({
      query: (resetData) => ({
        url: PASSWORD_RESET_URL,
        method: 'POST',
        body: resetData,
      }),
    }),

    refreshToken: builder.mutation<{ access_token: string; refresh_token?: string }, string>({
      query: (refreshToken) => ({
        url: `${REFRESH_TOKEN_URL}/${refreshToken}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useFetchUserProfileQuery,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useRefreshTokenMutation,
} = authApi
