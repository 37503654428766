// src/components/AvNavBar.tsx

import React from 'react'
import { Nav, Navbar as BootstrapNavbar, NavDropdown } from 'react-bootstrap'

import { useAuth } from '../hooks/useAuth'
import LOGO_IMAGE from '../assets/images/logo-transparent-blue-small.svg'
import { useFetchUserProfileQuery } from '../services/authApi'

const AvNavbar: React.FC = () => {
  const { logoutAndNavigate } = useAuth()
  const { data: user } = useFetchUserProfileQuery()

  return (
    <BootstrapNavbar bg="dark" variant="dark" expand="lg">
      <BootstrapNavbar.Brand href="/projects">
        <img src={LOGO_IMAGE} className="header-logo" alt="React Bootstrap logo" />
      </BootstrapNavbar.Brand>
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <NavDropdown title={user?.first_name || 'My Profile'} id="basic-nav-dropdown">
            <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logoutAndNavigate}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </BootstrapNavbar.Collapse>
    </BootstrapNavbar>
  )
}

export default AvNavbar
