// src/components/Annotation/utils/drawing.ts

import { AnnotationDataBoundingBox } from '../../../types/annotation'
import { Tag } from '../../../types/tag'
import { MousePosition } from '../hooks/useImageAnnotation'

const HANDLE_SIZE = 18

export const drawAnnotations = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  image: HTMLImageElement,
  zoomLevel: number,
  panOffset: MousePosition,
  allBoundingBoxes: AnnotationDataBoundingBox[],
  filteredBoundingBoxes: AnnotationDataBoundingBox[],
  newBoundingBox: AnnotationDataBoundingBox | null,
  selectedBBox: AnnotationDataBoundingBox | null,
  tags: Tag[],
  useBBoxes: boolean,
  mousePosition: MousePosition,
  contrast: number,
  showHeatmap: boolean,
  showBBoxes: boolean,
) => {
  ctx.clearRect(0, 0, canvas.width, canvas.height)

  // Apply zoom and pan transformation
  ctx.save()
  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.scale(zoomLevel, zoomLevel)
  ctx.translate(-canvas.width / 2 + panOffset.x, -canvas.height / 2 + panOffset.y)

  // Draw the image
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

  // Apply contrast
  if (contrast !== 0) {
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const factor = (259 * (contrast + 255)) / (255 * (259 - contrast))
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      data[i] = factor * (data[i] - 128) + 128 // Red
      data[i + 1] = factor * (data[i + 1] - 128) + 128 // Green
      data[i + 2] = factor * (data[i + 2] - 128) + 128 // Blue
    }
    ctx.putImageData(imageData, 0, 0)
  }

  if (showHeatmap) {
    drawHeatmap(ctx, canvas, allBoundingBoxes)
  }

  if (useBBoxes && showBBoxes) {
    // Draw existing bounding boxes
    filteredBoundingBoxes.forEach((box) => {
      const selectedTag = tags?.find((tag) => tag.id === box.name)
      if (selectedTag) {
        ctx.strokeStyle = `#${selectedTag.color.toString(16).padStart(6, '0').slice(0, 6)}`
        ctx.lineWidth = 3 / zoomLevel
        ctx.strokeRect(box.xmin, box.ymin, box.xmax - box.xmin, box.ymax - box.ymin)

        // Highlight selected box
        if (selectedBBox && box.class_index === selectedBBox.class_index) {
          ctx.fillStyle = `#${selectedTag.color.toString(16).padStart(6, '0').slice(0, 6)}44`
          ctx.fillRect(box.xmin, box.ymin, box.xmax - box.xmin, box.ymax - box.ymin)

          // Draw resize handles
          const handleSize = HANDLE_SIZE / zoomLevel
          ctx.fillStyle = `#${selectedTag.color.toString(16).padStart(6, '0').slice(0, 6)}`
          ctx.strokeStyle = `#${selectedTag.color.toString(16).padStart(6, '0').slice(0, 6)}`
          ctx.lineWidth = 1 / zoomLevel

          const handles = [
            { x: box.xmin, y: box.ymin },
            { x: box.xmax - handleSize, y: box.ymin },
            { x: box.xmin, y: box.ymax - handleSize },
            { x: box.xmax - handleSize, y: box.ymax - handleSize },
          ]

          handles.forEach((handle) => {
            ctx.beginPath()
            ctx.rect(handle.x, handle.y, handleSize, handleSize) // Draw rectangle
            ctx.fill()
            ctx.stroke()
          })
          // }

          // // Draw the 'X' icon
          // const iconSize = 20 / zoomLevel
          // const iconX = box.xmax - iconSize - 5 / zoomLevel
          // const iconY = box.ymin + 5 / zoomLevel
          //
          // // Draw the 'X' background
          // ctx.fillStyle = 'rgba(128, 0, 133, 0.3)'
          // ctx.fillRect(iconX, iconY, iconSize, iconSize)
          //
          // ctx.strokeStyle = 'red'
          // ctx.lineWidth = 2 / zoomLevel
          // ctx.beginPath()
          // ctx.moveTo(iconX + 4 / zoomLevel, iconY + 4 / zoomLevel) // Start point of first line
          // ctx.lineTo(iconX + iconSize - 4 / zoomLevel, iconY + iconSize - 4 / zoomLevel) // End point of first line
          // ctx.moveTo(iconX + iconSize - 4 / zoomLevel, iconY + 4 / zoomLevel) // Start point of second line
          // ctx.lineTo(iconX + 4 / zoomLevel, iconY + iconSize - 4 / zoomLevel) // End point of second line
          // ctx.stroke()
        }
      }
    })

    // Draw new bounding box if it exists
    if (newBoundingBox) {
      ctx.strokeStyle = 'blue'
      ctx.lineWidth = 2 / zoomLevel
      ctx.strokeRect(
        newBoundingBox.xmin,
        newBoundingBox.ymin,
        newBoundingBox.xmax - newBoundingBox.xmin,
        newBoundingBox.ymax - newBoundingBox.ymin,
      )
    }
  }

  // Draw crosshair
  ctx.beginPath()
  const xPos = mousePosition.x
  const yPos = mousePosition.y
  ctx.moveTo(xPos, 0)
  ctx.lineTo(xPos, canvas.height)

  ctx.moveTo(0, yPos)
  ctx.lineTo(canvas.width, yPos)

  ctx.strokeStyle = 'rgba(0, 0, 255, 0.75)'
  ctx.lineWidth = 1 / zoomLevel
  ctx.stroke()

  ctx.restore()
}

// src/components/Annotation/utils/drawing.ts

function drawHeatmap(
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  boundingBoxes: AnnotationDataBoundingBox[],
) {
  // Create an off-screen canvas for performance
  const heatmapCanvas = document.createElement('canvas')
  heatmapCanvas.width = canvas.width
  heatmapCanvas.height = canvas.height
  const heatmapCtx = heatmapCanvas.getContext('2d')

  if (!heatmapCtx) return

  // Initialize an array to hold overlap counts
  const overlapCounts = new Uint32Array(canvas.width * canvas.height)

  // Accumulate overlap counts
  boundingBoxes.forEach((box) => {
    const xmin = Math.max(0, Math.floor(box.xmin))
    const ymin = Math.max(0, Math.floor(box.ymin))
    const xmax = Math.min(canvas.width - 1, Math.floor(box.xmax))
    const ymax = Math.min(canvas.height - 1, Math.floor(box.ymax))

    for (let y = ymin; y <= ymax; y++) {
      for (let x = xmin; x <= xmax; x++) {
        const index = y * canvas.width + x
        overlapCounts[index]++
      }
    }
  })

  // Find maximum overlap to normalize
  let maxOverlap = 0
  for (let i = 0; i < overlapCounts.length; i++) {
    if (overlapCounts[i] > maxOverlap) {
      maxOverlap = overlapCounts[i]
    }
  }
  if (maxOverlap === 0) return // Avoid division by zero

  // Create ImageData for heatmap
  const imageData = heatmapCtx.createImageData(canvas.width, canvas.height)
  const data = imageData.data

  for (let i = 0; i < overlapCounts.length; i++) {
    const count = overlapCounts[i]
    const intensity = count / maxOverlap // Normalize to [0, 1]

    // Map intensity to color
    const [r, g, b] = intensityToHeatmapColor(intensity)

    const dataIndex = i * 4
    data[dataIndex] = r // Red
    data[dataIndex + 1] = g // Green
    data[dataIndex + 2] = b // Blue
    data[dataIndex + 3] = Math.floor(intensity * 180) // Alpha
  }

  heatmapCtx.putImageData(imageData, 0, 0)

  // Draw the heatmap onto the main canvas
  ctx.drawImage(heatmapCanvas, 0, 0)
}

// src/components/Annotation/utils/drawing.ts

function intensityToHeatmapColor(intensity: number): [number, number, number] {
  // Clamp intensity to [0, 1]
  intensity = Math.max(0, Math.min(1, intensity))

  let r = 0,
    g = 0,
    b = 0

  if (intensity <= 0.5) {
    // Green to Yellow
    r = Math.floor(510 * intensity)
    g = 255
    b = 0
  } else {
    // Yellow to Red
    r = 255
    g = Math.floor(255 - 510 * (intensity - 0.5))
    b = 0
  }

  return [r, g, b]
}
