// src/components/PredictionStatisticsTable.tsx

import React from 'react'
import { Card, Spinner, Row, Col } from 'react-bootstrap'
import { useGetPredictionStatisticsQuery } from '../services/predictionApi'
import { PredictionsStatisticsResp } from '../types/prediction'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectSelectedPredictionTags, toggleSelectedPredictionTag } from '../store/slices/predictionSlice'
import { useGetTagsQuery } from '../services/tagApi'
import { selectSelectedModel } from '../store/slices/modelSlice'
import { Tag } from '../types/tag'
import { selectSelectedProject } from '../store/slices/projectSlice'

interface PredictionStatisticsTableProps {
  modelId: string
  uncertaintyThreshold?: number
  tagIds?: string[]
}

const PredictionStatisticsTable: React.FC<PredictionStatisticsTableProps> = ({
  modelId,
  uncertaintyThreshold,
  tagIds,
}) => {
  const project = useAppSelector(selectSelectedProject)
  const { data, isLoading, error } = useGetPredictionStatisticsQuery({
    model_id: modelId,
    uncertainty_threshold: uncertaintyThreshold || 0.5,
    tag_id: tagIds,
  })

  const dispatch = useAppDispatch()
  const selectedPredictionTags = useAppSelector(selectSelectedPredictionTags)
  const selectedModel = useAppSelector(selectSelectedModel)

  const { data: tags } = useGetTagsQuery({
    project_id: selectedModel?.projectid || '',
    dataset_id: project?.datasetid || '',
  })

  const handleToggleTag = (classname: string) => {
    const tag = tags?.tags?.find((tag) => tag.name === classname)
    console.log('toggling tag', classname)
    if (tag) {
      console.log('found tag', tag)
      dispatch(toggleSelectedPredictionTag(tag))
    }
  }

  if (isLoading) {
    return (
      <Card className="prediction-statistics-card">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="light" />
        </Card.Body>
      </Card>
    )
  }

  if (error) {
    return (
      <Card className="prediction-statistics-card">
        <Card.Body>
          <p className="text-danger">Error loading prediction statistics</p>
        </Card.Body>
      </Card>
    )
  }

  const statistics = data as PredictionsStatisticsResp

  return (
    <Card className="prediction-statistics-card mt-3 shadow-sm">
      <Card.Body>
        <h3 className="card-title mb-3">Prediction Statistics</h3>
        {statistics?.Body.predictions_per_class !== null &&
          statistics?.Body.predictions_per_class.map((item) => {
            const isSelected = selectedPredictionTags.find((t: Tag) => t.name === item.classname) !== undefined
            return (
              <Row
                key={item.tagid}
                className={`class-item-row align-items-center mb-1 ${isSelected ? 'selected' : ''}`}
                onClick={() => handleToggleTag(item.classname)}
              >
                <Col xs={8} className="class-name">
                  {item.classname}
                </Col>
                <Col xs={4} className="value text-right">
                  {item.count}
                </Col>
              </Row>
            )
          })}
        <Row>
          <Col xs={12}>
            <hr />
            <Card.Text>Total Predictions {statistics?.Body.total_predictions}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default PredictionStatisticsTable
