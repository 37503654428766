import { useState, useEffect } from 'react'
import { useGetContentImageQuery } from '../services/contentApi'

export const useContentImage = (contentId: string, projectId: string, datasetId?: string) => {
  const [base64Image, setBase64Image] = useState<string | null>(null)

  const {
    data: imageUrl,
    error: imageError,
    isLoading: imageLoading,
  } = useGetContentImageQuery(
    {
      ContentID: contentId,
      project_id: projectId,
      dataset_id: datasetId,
    },
    { skip: !contentId },
  )

  useEffect(() => {
    const fetchImage = async () => {
      if (imageUrl) {
        try {
          const response = await fetch(imageUrl)
          const blob = await response.blob()
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64data = reader.result as string
            setBase64Image(base64data)
          }
          reader.readAsDataURL(blob)
        } catch (error) {
          console.error('Error converting image to base64:', error)
        }
      }
    }

    fetchImage()
  }, [imageUrl])

  return { base64Image, imageError, imageLoading }
}
