import React from 'react'
import { Card, Button, Col, Row } from 'react-bootstrap'
import { useGetAnnotationStatisticsQuery } from '../../services/annotationApi'
import { CSSProperties } from 'react'
import { FaFileImage } from 'react-icons/fa'
import { formatNumber } from '../../utils'
import { useAppSelector } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'

interface DatasetToolsProps {
  sizeDistributions?: { [key: string]: number }
  aspectRatioDistributions?: { [key: string]: number }
}

const DatasetTools: React.FC<DatasetToolsProps> = ({ sizeDistributions, aspectRatioDistributions }) => {
  const project = useAppSelector(selectSelectedProject)
  const { data: annoStats } = useGetAnnotationStatisticsQuery({
    projectId: project?.id || '',
    datasetId: project?.datasetid || '',
  })

  // Inline style objects for the top two cards
  const cardStyle: CSSProperties = {
    backgroundColor: '#2a2a2a',
    color: 'white',
    borderRadius: '10px',
    padding: '1px',
    boxShadow: '0 4px 6px rgba(0, 0, 128, 0.1)',
    transition: 'transform 0.3s ease',
  }

  const cardBodyStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  }

  const cardTitleStyle: CSSProperties = {
    fontSize: '1.25rem',
    color: 'white',
    marginBottom: '1rem',
  }

  const buttonStyle: CSSProperties = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '0.5rem 1rem',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease',
    cursor: 'pointer',
  }

  return (
    <Col>
      {/* Top two cards with inline styles */}
      <Card style={{ backgroundColor: 'transparent', border: 'none', marginBottom: '10px' }}>
        <Row>
          <Col xs={6}>
            <Card style={cardStyle}>
              <Card.Body style={cardBodyStyle}>
                <Card.Title style={cardTitleStyle}>Manage Dataset</Card.Title>
                <Button style={buttonStyle}>Go</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6}>
            <Card style={cardStyle}>
              <Card.Body style={cardBodyStyle}>
                <Card.Title style={cardTitleStyle}>Export Data</Card.Title>
                <Button style={buttonStyle}>Go</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>

      {/* Other cards with external SCSS or default styles */}
      <Card className="file-upload-stats mb-3">
        <Card.Body>
          <div className="d-flex align-items-center">
            <FaFileImage />
            <h2 className="mb-0 ps-2">Image Details</h2>
          </div>
          <div className="details-grid">
            {/*<p className="mb-1">Dataset ID: {project?.datasetid || 'Unknown'}</p>*/}
            <div className="detail-item">
              <h3>Avg Annotation Per Image</h3>
              <p>{annoStats?.statistics.average_annotations_per_image?.toFixed(2)}</p>
            </div>
            <div className="detail-item">
              <h3>Dimensions</h3>
              <p>
                Min: {annoStats?.statistics.min_image_width_pixels}x{annoStats?.statistics.min_image_height_pixels}
              </p>
              <p>
                Avg: {annoStats?.statistics.average_image_width_pixels}x
                {annoStats?.statistics.average_image_height_pixels}
              </p>
              <p>
                Max: {annoStats?.statistics.max_image_width_pixels}x{annoStats?.statistics.max_image_height_pixels}
              </p>
            </div>
            <div className="detail-item">
              <h3>Sizes</h3>
              <p>Min: {formatNumber(annoStats?.statistics.min_image_size_bytes || 0)}</p>
              <p>Avg: {formatNumber(annoStats?.statistics.average_image_size_bytes || 0)} bytes</p>
              <p>Max: {formatNumber(annoStats?.statistics.max_image_size_bytes || 0)} bytes</p>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default DatasetTools
