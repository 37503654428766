// src/store/slices/projectSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Tag } from '../../types/tag'
import { Project } from '../../types/project'

export type ActiveTab = 'annotated' | 'unannotated' | 'suggestive_labels'

export interface ProjectState {
  activeTab: ActiveTab
  filterTags: Tag[]
  selectedProject: Project | null
}

const initialState: ProjectState = {
  activeTab: 'unannotated',
  filterTags: [],
  selectedProject: null,
}

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<ActiveTab>) => {
      state.activeTab = action.payload
    },
    setFilterTags: (state, action: PayloadAction<Tag[]>) => {
      state.filterTags = action.payload
    },
    clearFilterTags: (state) => {
      state.filterTags = []
    },
    addFilterTag: (state, action: PayloadAction<Tag>) => {
      const filterExists = state.filterTags.find((t) => t.id === action.payload.id)
      if (!filterExists) {
        state.filterTags = [...state.filterTags, action.payload]
      }
    },
    removeFilterTag: (state, action: PayloadAction<Tag>) => {
      state.filterTags = state.filterTags.filter((t) => t.id !== action.payload.id)
    },
    toggleFilterTag: (state, action: PayloadAction<Tag>) => {
      const filterExists = state.filterTags.find((t) => t.id === action.payload.id)
      if (!filterExists) {
        state.filterTags = [...state.filterTags, action.payload]
      } else {
        state.filterTags = state.filterTags.filter((t) => t.id !== action.payload.id)
      }
    },
    setSelectedProject: (state, action: PayloadAction<Project>) => {
      state.selectedProject = action.payload
    },
    clearSelectedProject: (state) => {
      state.selectedProject = null
    },
  },
})

export const {
  setActiveTab,
  setFilterTags,
  addFilterTag,
  removeFilterTag,
  clearFilterTags,
  toggleFilterTag,
  setSelectedProject,
  clearSelectedProject,
} = projectSlice.actions

export default projectSlice.reducer

// Selector
export const selectActiveTab = (state: { project: ProjectState }) => state.project.activeTab
export const selectFilterTags = (state: { project: ProjectState }) => state.project.filterTags
export const selectSelectedProject = (state: { project: ProjectState }) => state.project.selectedProject
