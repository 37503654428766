// src/store/index.ts

import { configureStore } from '@reduxjs/toolkit'
import themeReducer from './themeSlice'
import authReducer from './slices/authSlice'
import datasetReducer from './slices/datasetSlice'
import modelReducer from './slices/modelSlice'
import annotationReducer from './slices/annotationSlice'
import predictionReducer from './slices/predictionSlice'
import projectReducer from './slices/projectSlice'

import { tagApi } from '../services/tagApi'
import { contentApi } from '../services/contentApi'
import { projectsApi } from '../services/projectsApi'
import { datasetApi } from '../services/datasetApi'
import { modelsApi } from '../services/modelsApi'
import { annotationsApi } from '../services/annotationApi'
import { authApi } from '../services/authApi'
import { exportApi } from '../services/exportApi'
import { predictionApi } from '../services/predictionApi'
import { experimentalApi } from '../services/experimentalApi'
import { userApi } from '../services/userApi'
import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
  reducer: {
    [tagApi.reducerPath]: tagApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [datasetApi.reducerPath]: datasetApi.reducer,
    [modelsApi.reducerPath]: modelsApi.reducer,
    [annotationsApi.reducerPath]: annotationsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [exportApi.reducerPath]: exportApi.reducer,
    [predictionApi.reducerPath]: predictionApi.reducer,
    [experimentalApi.reducerPath]: experimentalApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    theme: themeReducer,
    auth: authReducer,
    dataset: datasetReducer,
    model: modelReducer,
    annotation: annotationReducer,
    prediction: predictionReducer,
    project: projectReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      tagApi.middleware,
      contentApi.middleware,
      projectsApi.middleware,
      datasetApi.middleware,
      modelsApi.middleware,
      annotationsApi.middleware,
      exportApi.middleware,
      predictionApi.middleware,
      authApi.middleware,
      experimentalApi.middleware,
      userApi.middleware,
    ),
})

// Optional: Enable refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
