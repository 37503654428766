// src/components/PredictionImage.tsx

import React, { useState } from 'react'
import { AnnotationPredictionBoundingBox, Prediction } from '../types/prediction'
import { Button, Card } from 'react-bootstrap'
import BBoxPills from './BBoxPills'
import ClassPills from './ClassPills'
import { useContentImage } from '../hooks/useContentImage'
import { useAppSelector } from '../store/hooks'
import { selectActiveTab, selectSelectedProject } from '../store/slices/projectSlice'
import { AnnotationDataBoundingBox } from '../types/annotation'

interface PredictionImageProps {
  prediction: Prediction
  onEdit: (prediction: Prediction) => void
  onToggle: (prediction: Prediction) => void
  isSelected: boolean
  cardSize: number
}

const PredictionImage: React.FC<PredictionImageProps> = ({ prediction, onEdit, onToggle, isSelected, cardSize }) => {
  const project = useAppSelector(selectSelectedProject)
  const [isHovered, setIsHovered] = useState(false)

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent the click from propagating to the parent div
    onEdit(prediction)
  }

  const handleImageClick = () => {
    onToggle(prediction)
  }
  const { base64Image } = useContentImage(prediction.contentid, project?.id || '', project?.datasetid)

  const activeTab = useAppSelector(selectActiveTab)

  const getBBoxes = (): AnnotationDataBoundingBox[] => {
    const bBoxes = prediction.predictions.filter((b) => b !== undefined).map((p) => p.bounding_boxes)
    return bBoxes as AnnotationDataBoundingBox[]
  }
  return (
    <div
      className="image-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleImageClick}
    >
      <div style={{ padding: '5px' }} onClick={() => handleImageClick}>
        <Card
          bg="bg-light"
          text="light"
          style={{ height: `${cardSize}px`, width: `${cardSize}px`, cursor: 'pointer', position: 'relative' }}
        >
          <Card.Img
            className="prediction-image"
            variant="top"
            src={base64Image || ''}
            style={{ height: `${cardSize}px`, width: `${cardSize}px`, objectFit: 'cover' }}
          />
          {activeTab === 'annotated' &&
            project?.annotation_type === 'bounding_box' &&
            prediction?.predictions?.length && (
            <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
              <BBoxPills bounding_boxes={getBBoxes()} />
            </div>
          )}
          {activeTab === 'annotated' &&
            project?.annotation_type === 'classification' &&
            prediction?.predictions?.length && (
            <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
              <ClassPills tagIds={[prediction?.predictions?.[0].tagid]} />
            </div>
          )}
          {/*<Form.Check type="checkbox" className="checkbox-top-right" checked={isSelected} readOnly/>*/}

          {isHovered && (
            <div className="hover-overlay">
              <Button
                variant="primary"
                size="sm"
                onClick={handleEdit}
                className="edit-button"
                style={{
                  borderRadius: `${cardSize / 4}px`,
                  width: `${cardSize / 2}px`,
                  height: `${cardSize / 2}px`,
                  fontSize: `${cardSize / 10}px`,
                }}
              >
                Edit
              </Button>
            </div>
          )}
          <input
            type="checkbox"
            className="checkbox-top-right"
            checked={isSelected}
            onChange={() => onToggle(prediction)}
          />

          {project?.annotation_type === 'classification' && (
            <div className="info-overlay">
              <ClassPills
                tagIds={prediction.predictions.map((p) => p.tagid)}
                tagConfidence={prediction.predictions.map((p) => p.confidence)}
                className="prediction-text"
              />
            </div>
          )}

          {project?.annotation_type === 'bounding_box' && (
            <div style={{ position: 'absolute', bottom: '10px', left: '10px', zIndex: 1 }}>
              <BBoxPills
                bounding_boxes={prediction?.predictions?.map((p) => {
                  return { ...(p.bounding_boxes as AnnotationPredictionBoundingBox), name: p.class_name }
                })}
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}

export default PredictionImage
