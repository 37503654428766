// src/store/slices/modelSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { Model } from '../../types/model'

export interface ModelState {
  selectedModelId: string | null
  selectedModel: Model | null
  isLoading: boolean
  error: string | null
}

const initialState: ModelState = {
  selectedModelId: null,
  selectedModel: null,
  isLoading: false,
  error: null,
}

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setSelectedModelId: (state, action: PayloadAction<string | null>) => {
      state.selectedModelId = action.payload
      state.selectedModel = null // Reset the selected model when a new ID is set
      // state.isLoading = true
      state.error = null
    },
    setSelectedModel: (state, action: PayloadAction<Model | null>) => {
      state.selectedModel = action.payload
      state.isLoading = false
    },
    clearSelectedModel: (state) => {
      state.selectedModelId = null
      state.selectedModel = null
      state.isLoading = false
      state.error = null
    },
    setModelError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.isLoading = false
    },
  },
})

export const { setSelectedModelId, setSelectedModel, clearSelectedModel, setModelError } = modelSlice.actions

export const selectSelectedModelId = (state: RootState) => state.model.selectedModelId
export const selectSelectedModel = (state: RootState) => state.model.selectedModel
export const selectModelIsLoading = (state: RootState) => state.model.isLoading
export const selectModelError = (state: RootState) => state.model.error

export default modelSlice.reducer
