// src/ProjectDetails/MainContent.tsx

import React from 'react'
import UploadView from '../views/UploadView'
import DatasetView from '../views/DatasetView'
import ModelView from '../views/ModelView'
import AnnotationView from '../views/AnnotationView'
import ProjectView from '../views/ProjectView'
import { useAppSelector } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'

interface MainContentProps {
  activeView: 'project' | 'models' | 'dataset' | 'upload' | 'annotation'
}

const MainContent: React.FC<MainContentProps> = ({ activeView }) => {
  const project = useAppSelector(selectSelectedProject)
  switch (activeView) {
    case 'project':
      return (
        <div style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <ProjectView />
        </div>
      )
    case 'upload':
      return (
        <div style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <UploadView />
        </div>
      )
    case 'annotation':
      return project ? (
        <div className="annotations" style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <AnnotationView itemsPerPage={100} />
        </div>
      ) : (
        <div>There is no project selected</div>
      )

    case 'dataset':
      return (
        <div className="dataset-component" style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <DatasetView />
        </div>
      )
    case 'models':
      return (
        <div className="model-view-container" style={{ height: 'calc(100vh - [height of your header/navbar])' }}>
          <ModelView />
        </div>
      )
    default:
      return null
  }
}

export default MainContent
