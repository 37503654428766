// src/components/Annotation/ImageAnnotationComponentV2.tsx

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGetContentImageQuery } from '../../services/contentApi'
import { useGetTagsQuery } from '../../services/tagApi'
import { useAppSelector } from '../../store/hooks'
import { selectFilteredBoundingBoxes } from '../../store/slices/annotationSlice'
import Canvas from './Canvas'
import { useImageAnnotation } from './hooks/useImageAnnotation'
import TagSelector from '../tools/TagSelector'
import {
  FaExpandArrowsAlt,
  FaExclamationTriangle,
  FaUndo,
  FaRedo,
  FaSun,
  FaBoxes,
  FaTrash,
  FaEdit,
} from 'react-icons/fa'
import { BiReset } from 'react-icons/bi'
import { SiLinkfire } from 'react-icons/si'
import BBoxPills from '../BBoxPills'
import { selectSelectedProject } from '../../store/slices/projectSlice'

interface ImageAnnotationProps {
  contentId: string
  useBBoxes?: boolean
  readonly?: boolean
}

const ImageAnnotationComponentV2: React.FC<ImageAnnotationProps> = ({
  contentId,
  useBBoxes = false,
  readonly = false,
}) => {
  const project = useAppSelector(selectSelectedProject)
  const editingBoundingBoxes = useAppSelector(selectFilteredBoundingBoxes)
  // const containerRef = useRef<HTMLDivElement>(null)
  const floatingEditorRef = useRef<HTMLDivElement>(null)

  const { data: imageUrl } = useGetContentImageQuery(
    {
      ContentID: contentId,
      project_id: project?.id || '',
      dataset_id: project?.datasetid || '',
    },
    { skip: !contentId },
  )

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  const [showHeatmap, setShowHeatmap] = useState(false)
  const [showBBoxes, setShowBBoxes] = useState(true)

  const {
    canvasRef,
    imageRef,
    handleMouseEvent,
    handleWheel,
    zoomLevel,
    panOffset,
    newBoundingBox,
    setNewBoundingBox,
    handleTagSelect,
    mousePosition,
    showTagSelector,
    setShowTagSelector,
    tagSelectorPosition,
    recenterImage,
    undo,
    redo,
    removeAll,
    reset,
    contrast,
    setContrast,
    selectedBBox,
    handleBoxSelection,
    handleDoubleClick,
    popupState,
    setPopupState,
    handleBoxDelete,
  } = useImageAnnotation(useBBoxes, editingBoundingBoxes, readonly)

  const handleContrastSlider = useCallback(
    (newContrast: number) => {
      setContrast(newContrast)
    },
    [setContrast],
  )

  const handleHeatmapToggle = useCallback(() => {
    if (!showHeatmap) {
      setShowBBoxes(false)
    } else {
      setShowBBoxes(true)
    }
    setShowHeatmap((prev) => !prev)
  }, [showHeatmap])

  const handleCloseTagSelector = useCallback(() => {
    setShowTagSelector(false)
    setNewBoundingBox(null)
  }, [setShowTagSelector])

  useEffect(() => {
    if (popupState.show && floatingEditorRef.current) {
      const editor = floatingEditorRef.current
      const rect = editor.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight

      let { x, y } = popupState.position

      // Adjust x-position if the editor goes beyond the right edge of the viewport
      if (x + rect.width > viewportWidth) {
        x = viewportWidth - rect.width
      }

      // Adjust y-position if the editor goes beyond the bottom edge of the viewport
      if (y + rect.height > viewportHeight) {
        y = viewportHeight - rect.height
      }

      // Ensure the editor doesn't go beyond the left or top edge
      x = Math.max(0, x)
      y = Math.max(0, y)

      editor.style.left = `${x}px`
      editor.style.top = `${y}px`
    }
  }, [popupState])

  useEffect(() => {
    if (!selectedBBox) {
      setPopupState({
        show: false,
        position: { x: 0, y: 0 },
        box: null,
      })
    }
  }, [selectedBBox])

  return (
    <div className="image-annotation-container position-relative">
      {/* Controls at the top */}
      <div className="controls-top position-absolute top-0 start-50 translate-middle-x">
        <div className="btn-group" role="group" aria-label="Image controls">
          <button type="button" className="btn btn-secondary" onClick={recenterImage} title="Recenter">
            <FaExpandArrowsAlt size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={undo} title="Undo">
            <FaUndo size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={reset} title="Reset">
            <BiReset size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={removeAll} title="Remove All">
            <FaExclamationTriangle size={20} />
          </button>
          <button type="button" className="btn btn-secondary" onClick={redo} title="Redo">
            <FaRedo size={20} />
          </button>
          {/* Heatmap Toggle Button */}
          <button
            type="button"
            className="btn btn-secondary"
            // style={{background: showHeatmap ? 'red' : ''}}
            onClick={handleHeatmapToggle}
            title="Toggle Heatmap"
          >
            <SiLinkfire size={20} color={showHeatmap ? 'red' : ''} />
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            // style={{background: showBBoxes ? 'green' : ''}}
            onClick={() => setShowBBoxes((prev) => !prev)}
            title="Toggle Heatmap"
          >
            <FaBoxes size={20} color={showBBoxes ? 'green' : ''} />
          </button>
        </div>
      </div>

      {/* Canvas Component */}
      <Canvas
        canvasRef={canvasRef}
        imageRef={imageRef}
        imageUrl={imageUrl}
        handleMouseEvent={handleMouseEvent}
        handleWheel={handleWheel}
        zoomLevel={zoomLevel}
        panOffset={panOffset}
        newBoundingBox={newBoundingBox}
        tags={tags}
        useBBoxes={useBBoxes}
        showBBoxes={showBBoxes}
        mousePosition={mousePosition}
        contrast={contrast}
        selectedBBox={selectedBBox}
        onBoxSelect={handleBoxSelection}
        showHeatmap={showHeatmap}
        handleDoubleClick={handleDoubleClick}
      />

      {/* Contrast Slider (if needed) */}
      <div className="slider-controls position-absolute bottom-0 start-50 translate-middle-x">
        <button type="button" className="btn btn-secondary" onClick={() => setContrast(0)} title="Redo">
          <FaSun size={20} />
        </button>
        <input
          type="range"
          min="-256"
          max="256"
          value={contrast}
          onChange={(e) => handleContrastSlider(parseInt(e.target.value, 10))}
          className="form-range"
          id="contrast-slider"
        />
      </div>

      {/* Tag Selector */}
      {showTagSelector && !readonly && (
        <TagSelector onTagSelect={handleTagSelect} position={tagSelectorPosition} onClose={handleCloseTagSelector} />
      )}

      {/* Floating Editor for tag editing and box deletion */}
      {popupState.show && !readonly && (
        <div
          ref={floatingEditorRef}
          style={{
            position: 'fixed',
            justifyContent: 'space-around',
            zIndex: 1000,
            backgroundColor: '#1a1a1a',
            opacity: '75%',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '10px',
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            maxWidth: '150px',
            width: '100%',
          }}
        >
          <div className="d-flex justify-content-around align-items-center mt-2">
            {selectedBBox && (
              <>
                <BBoxPills bounding_boxes={[selectedBBox]} showTagCounts={false} showConfidence={true} />
              </>
            )}
          </div>
          <div className="d-flex justify-content-around align-items-center my-2">
            <button className="btn btn-danger mt-1 mx-2 w-40" onClick={handleBoxDelete} style={{ opacity: 1 }}>
              <FaTrash />
            </button>
            <button
              className="btn btn-secondary mt-1 mx-2 w-40"
              onClick={() => setShowTagSelector(true)}
              style={{ opacity: 1 }}
            >
              <FaEdit />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageAnnotationComponentV2
