// src/services/experimentalApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from './baseQuery' // Assuming you have a base query with re-authentication logic

export interface ExperimentalVersionResponse {
  version: string
}

export const experimentalApi = createApi({
  reducerPath: 'experimentalApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getExperimentalVersion: builder.query<ExperimentalVersionResponse, void>({
      query: () => ({
        url: '/v1/experimental',
        method: 'GET',
      }),
      // If the endpoint requires authentication, ensure headers are set in baseQueryWithReauth
      // You can also add providesTags if caching or invalidation is needed
    }),
  }),
})

export const { useGetExperimentalVersionQuery } = experimentalApi
