// src/types/requests.ts

import { AnnotationMetadata } from './annotation'

// ProjectQueryRegex interface
export interface ProjectQueryRegex {
  limit: number
  page: number
  sort_key: string
  sort_val: number
  operator: 'and' | 'or'
  filters: RegexFilter[]
}

interface RegexFilter {
  key: string
  regex: {
    enable: boolean
    options: string
  }
  value: string
}

// Default values for ProjectQueryRegex
export const defaultProjectQueryRegex: ProjectQueryRegex = {
  limit: 100,
  page: 0,
  sort_key: 'created_at',
  sort_val: -1,
  operator: 'or',
  filters: [
    {
      key: 'modelid',
      regex: {
        enable: true,
        options: 'i',
      },
      value: '',
    },
  ],
}

// ProjectQueryDateTime interface
export interface ProjectQueryDateTime {
  limit: number
  page: number
  sort_key: string
  sort_val: number
  operator: 'and' | 'or'
  filters: DateTimeFilter[]
}

interface DateTimeFilter {
  key: string
  start_date?: string
  end_date?: string
}

// Default values for ProjectQueryDateTime
export const defaultProjectQueryDateTime: ProjectQueryDateTime = {
  limit: 10,
  page: 0,
  sort_key: 'created_at',
  sort_val: -1,
  operator: 'and',
  filters: [],
}

export interface CreateProjectReq {
  name: string
  annotation_type: string
  description?: string
  license?: string
}

// export interface CreateClassAnnotationReq {
//   content_id: string
//   dataset_id: string
//   project_id: string
//   tag_id: string[]
//   thumbnailSize?: number
//   metadata: undefined
// }

export interface CreateBBoxAnnotationReq {
  content_id: string
  dataset_id: string
  project_id: string
  tag_id: string[]
  metadata?: AnnotationMetadata
  thumbnail_size?: number
}

// src/types/requests.ts

export interface CreateTiffUploadReq {
  project_id: string
  files: File[]
  source: string
  clip_size: number
}
