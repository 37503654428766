// src/services/endpoints.ts
export const BASE_URL = `${process.env.REACT_APP_API_ENDPOINT}`
export const LOGIN_URL = '/login'
export const USER_ACCOUNT_URL = `${process.env.REACT_APP_API_ENDPOINT}/me`
export const PASSWORD_RESET_URL = `${process.env.REACT_APP_API_ENDPOINT}/password-reset`
export const REFRESH_TOKEN_URL = `${process.env.REACT_APP_API_ENDPOINT}/refresh`
export const USER_DETAIL_URL = `${process.env.REACT_APP_API_ENDPOINT}/me`
export const PROJECTS_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/projects`
export const DATASETS_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/datasets`
export const MODELS_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/models`
export const GET_CONTENT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/content/annotated`
export const DELETE_CONTENT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/content/delete`
export const GET_RANDOM_CONTENT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/content/sample`
export const QUERY_CONTENT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/content/query`
export const GET_SINGLE_CONTENT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/content`
export const GET_METADATA_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/content/metadata`

export const SEARCH_PROJECT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/projects/query`
export const PROJECT_UPLOADS = `${process.env.REACT_APP_API_ENDPOINT}/v1/uploads`
export const UPDATE_PROFILE_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/users`
export const UPDATE_PROFILEPIC_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/users`
export const CHANGE_PASSWORD_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/password`
export const UPDATE_EMAIL_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/users`
export const TAGS_MGMT = `${process.env.REACT_APP_API_ENDPOINT}/v1/tags`
export const TAGS_QUERY = `${process.env.REACT_APP_API_ENDPOINT}/v1/tags/query`

export const TAGS_PROPERTIES = `${process.env.REACT_APP_API_ENDPOINT}/v1/tags/properties`
export const ANNOTATIONS = `${process.env.REACT_APP_API_ENDPOINT}/v1/annotations`
export const ANNOTATIONS_STATISTICS = `${process.env.REACT_APP_API_ENDPOINT}/v1/annotations/statistics`
export const ANNOTATION_QUERY = `${process.env.REACT_APP_API_ENDPOINT}/v1/annotations/query`
export const DATASET_EXPORT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/exports`
export const DATASET_QUERY_EXPORT_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/exports/query`
export const PREDICTIONS_STATISTICS = `${process.env.REACT_APP_API_ENDPOINT}/v1/predictions/statistics`
export const PREDICTIONS_QUERY = `${process.env.REACT_APP_API_ENDPOINT}/v1/predictions/query`
export const PREDICTIONS_SAMPLE = `${process.env.REACT_APP_API_ENDPOINT}/v1/predictions/sample`
export const PREDICTIONS_URL = `${process.env.REACT_APP_API_ENDPOINT}/v1/predictions`
