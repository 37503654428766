// src/hooks/useAuth.ts
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../store/hooks'
import { logout as logoutAction } from '../store/slices/authSlice'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const logout = () => {
    dispatch(logoutAction())
  }

  const logoutAndNavigate = () => {
    logout()
    navigate('/login')
  }

  return { logout, logoutAndNavigate }
}
