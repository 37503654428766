import React, { useEffect } from 'react'
import { Card, Button, Row, Col, Badge } from 'react-bootstrap'
import { useFetchModelsQuery, useRunBatchInferenceMutation } from '../../services/modelsApi'
import { setSelectedModelId, selectSelectedModelId } from '../../store/slices/modelSlice'
import { rateBBoxModel, rateClassificationModel } from '../../utils'
import StarRating from '../StarRating'
import { Model } from '../../types/model'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'

const ModelAssistedLabeling: React.FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const selectedModelId = useAppSelector(selectSelectedModelId)
  const { data: modelsData, isLoading, error, refetch } = useFetchModelsQuery({ project_id: project?.id || '' })
  const [runBatchInference, { isLoading: isBatchRunning }] = useRunBatchInferenceMutation()

  const selectedModel = modelsData?.models.find((model: Model) => model.id === selectedModelId)

  useEffect(() => {
    const shouldPoll = modelsData?.models?.some((model: Model) => model.deployment?.status !== 'TRAINED')

    if (shouldPoll) {
      const pollingInterval = setInterval(() => {
        refetch()
      }, 3000)

      return () => clearInterval(pollingInterval)
    }
  }, [modelsData, refetch])

  const handleApplyToProject = async (modelId: string) => {
    try {
      await runBatchInference({ id: modelId, thumbnail_size: 640 }).unwrap()
    } catch (error) {
      console.error('Failed to run batch inference:', error)
    }
  }

  const getBatchStateLabel = () => {
    if (selectedModel?.batch?.status === 'RUNNING') return 'Running...'
    if (selectedModel?.batch?.status === 'COMPLETED') return 'Rerun Batch'
    if (selectedModel?.batch?.status === 'FAILED') return 'Retry Batch'
    return 'Label'
  }

  const convertTimeFormat = (isoString: string | undefined): string => {
    if (!isoString) return 'N/A' // Handle undefined or null values
    const date = new Date(isoString) // Convert the string to a Date object
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Use 12-hour format (AM/PM)
    })
  }

  const bBoxRating = (model: Model) => rateBBoxModel(model)
  const classificationRating = (model: Model) => rateClassificationModel(model)

  const handleSelectModelId = (modelId: string) => {
    // Toggle selection of model (if already selected, deselect)
    if (selectedModelId === modelId) {
      dispatch(setSelectedModelId('')) // Deselect if the same model is clicked
    } else {
      dispatch(setSelectedModelId(modelId)) // Select the model
    }
  }

  if (isLoading) return <div>Loading models...</div>
  if (error) return <div>Error loading models</div>

  return (
    <div className="model-assisted-labeling-list">
      <Row>
        <Col xs={7}>
          <Card style={{ minHeight: '600px', maxHeight: '600px', padding: '10px', backgroundColor: '#333333' }}>
            {modelsData?.models
              ?.filter((model: Model) => model.deployment?.status === 'IN_SERVICE')
              .map((model: Model) => (
                <Card
                  key={model.id}
                  className={`model-card mb-3 ${model.id === selectedModelId ? 'active' : ''}`}
                  onClick={() => handleSelectModelId(model.id)}
                >
                  <Card.Body style={{ padding: 0 }}>
                    <div className="model-card-header">
                      <div className="model-banner">
                        <span>{model.name}</span>
                      </div>

                      {model.state === 'TRAINED' && (
                        <div className="star-rating-container">
                          <div className="rating-label">Rating</div>
                          {project?.annotation_type === 'classification' && (
                            <StarRating rating={classificationRating(model).classificationRating} />
                          )}
                          {project?.annotation_type === 'bounding_box' && (
                            <StarRating rating={bBoxRating(model).bBoxRating} />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="model-card-date">{new Date(model.created_at).toLocaleString()}</div>
                    </div>

                    <Row className="d-flex justify-content-between align-items-center">
                      <Col>
                        <div className="model-state-container d-flex align-items-center">
                          {model.state === 'TRAINING' && (
                            <Badge pill className="model-badge badge-training">
                              Training
                            </Badge>
                          )}
                          {model.state === 'TRAINED' && model.deployment?.status === 'CREATING' && (
                            <Badge pill className="model-badge badge-deploying">
                              Deploying
                            </Badge>
                          )}
                          {model.state === 'TRAINED' && model.deployment?.status === 'IN_SERVICE' && (
                            <Badge pill className="model-badge badge-deployed">
                              Deployed
                            </Badge>
                          )}
                          {model.state === 'TRAINED' && model.deployment?.status === 'DELETED' && (
                            <Badge pill className="model-badge badge-training">
                              Trained
                            </Badge>
                          )}
                        </div>
                      </Col>

                      <Col className="d-flex justify-content-end">
                        {model.metrics &&
                          model.metrics['validation:mAP'] !== undefined &&
                          model.metrics['validation:mAP'] !== null && (
                          <div className="model-map-box">
                            <div className="model-map-label">mAP</div>
                            <div className="model-map-value">
                              {`${Math.ceil(Number(model.metrics['validation:mAP']) * 100)}%`}
                            </div>
                          </div>
                        )}
                        {model.metrics &&
                          model.metrics['train:smooth_l1'] !== undefined &&
                          model.metrics['validation:mAP'] !== null && (
                          <div className="model-map-box">
                            <div className="model-map-label">L1</div>
                            <div className="model-map-value">
                              {`${Math.ceil(Number(model.metrics['train:smooth_l1']) * 100)}%`}
                            </div>
                          </div>
                        )}
                        {model.metrics &&
                          model.metrics['train:cross_entropy'] !== undefined &&
                          model.metrics['validation:mAP'] !== null && (
                          <div className="model-map-box">
                            <div className="model-map-label">Loss</div>
                            <div className="model-map-value">
                              {`${Math.ceil(Number(model.metrics['train:cross_entropy']) * 100)}%`}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
          </Card>
        </Col>

        {/* Button should always render if a model is selected */}
        <Col xs={5}>
          <Card
            style={{
              height: '300px',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            {selectedModel && (
              <div className="suggestive-label-button">
                <Button
                  className={`suggestive-label-button-inner ${
                    isBatchRunning || selectedModel.batch?.status === 'RUNNING' ? 'training' : ''
                  }`}
                  onClick={() => handleApplyToProject(selectedModel.id)}
                  disabled={isBatchRunning || selectedModel.batch?.status === 'RUNNING'}
                >
                  {getBatchStateLabel()}
                </Button>
              </div>
            )}
          </Card>
          <Card
            style={{
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            {selectedModel && (
              <div className="suggestive-label-card">
                <div className="batch-info">
                  <Row className="info-row">
                    <Col>
                      <strong>Started At:</strong>
                    </Col>
                    <Col>
                      <span>{convertTimeFormat(selectedModel.batch?.started_at) || 'N/A'}</span>
                    </Col>
                  </Row>
                  <Row className="info-row">
                    <Col>
                      <strong>Ended At:</strong>
                    </Col>
                    <Col>
                      <span>{convertTimeFormat(selectedModel.batch?.ended_at) || 'N/A'}</span>
                    </Col>
                  </Row>
                  <Row className="info-row">
                    <Col>
                      <strong>Threshold:</strong>
                    </Col>
                    <Col>
                      <span>{selectedModel.batch?.threshold * 100 + '%' || 'N/A'}</span>
                    </Col>
                  </Row>
                  <Row className="info-row">
                    <Col>
                      <strong>Images:</strong>
                    </Col>
                    <Col>
                      <span>{selectedModel.batch?.completed_content || 'N/A'}</span>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ModelAssistedLabeling
