// src/services/baseQuery.ts

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { RootState } from '../store'
import { logout, setCredentials } from '../store/slices/authSlice'
import { authApi } from './authApi'

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/v1`,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    headers.set('Cache-Control', 'no-cache')
    return headers
  },
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && (result.error.status === 401 || result.error.status === 'FETCH_ERROR')) {
    // try to get a new token
    const refreshToken = (api.getState() as RootState).auth.refreshToken
    if (refreshToken) {
      const refreshResult = await api.dispatch(authApi.endpoints.refreshToken.initiate(refreshToken))
      if ('data' in refreshResult) {
        // store the new token
        api.dispatch(
          setCredentials({
            access_token: refreshResult.data?.access_token || '',
            refresh_token: refreshResult.data?.refresh_token || '',
          }),
        )
        // retry the initial query
        result = await baseQuery(args, api, extraOptions)
      } else {
        console.error('Token refresh failed:', refreshResult.error)
        api.dispatch(logout())
      }
    } else {
      api.dispatch(logout())
    }
  }
  return result
}

export default baseQueryWithReauth
