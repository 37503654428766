// src/utils/helpers.ts

import { AnnotationDataBoundingBox } from '../types/annotation'
import { Model } from '../types/model'

export const toTitleCase = (str: string): string => {
  // List of small words that should not be capitalized unless they're the first or last word
  const smallWords = new Set([
    'a',
    'an',
    'and',
    'as',
    'at',
    'but',
    'by',
    'for',
    'if',
    'in',
    'nor',
    'of',
    'on',
    'or',
    'per',
    'the',
    'to',
    'via',
  ])

  return str.toLowerCase().replace(/([^\s:-_]+)([\s:-_])*/g, (_, word, separator, index, title) => {
    // Always capitalize the first and last word
    if (index === 0 || index + word.length === title.length) {
      return word.charAt(0).toUpperCase() + word.slice(1) + (separator === '_' ? ' ' : separator || '')
    }

    // Capitalize all words that are not in the smallWords set
    if (!smallWords.has(word)) {
      return word.charAt(0).toUpperCase() + word.slice(1) + (separator === '_' ? ' ' : separator || '')
    }

    // Keep small words lowercase
    return word + (separator || '')
  })
}

/**
 * Calculates a rating for a model based on its mAP, Cross Entropy, and Smooth L1 scores.
 * @param mAP - Mean Average Precision (0-100)
 * @param crossEntropy - Cross Entropy loss (lower is better)
 * @param smoothL1 - Smooth L1 loss (lower is better)
 * @returns A rating between 1 and 5, with 5 being the best
 */
export const rateModel = (mAP: number, crossEntropy: number, smoothL1: number): number => {
  // Ensure mAP is between 0 and 100
  mAP = Math.max(0, Math.min(100, mAP))

  // Calculate individual scores
  const mAPScore = mAP / 20 // 100% mAP would contribute 5 points
  const crossEntropyScore = Math.max(0, 5 - crossEntropy / 10) // Lower is better, max 5 points
  const smoothL1Score = Math.max(0, 5 - smoothL1 / 5) // Lower is better, max 5 points

  // Calculate weighted average (adjust weights as needed)
  const weightedScore = mAPScore * 0.5 + crossEntropyScore * 0.25 + smoothL1Score * 0.25

  // Ensure final score is between 1 and 5
  return Math.max(1, Math.min(5, weightedScore))
}

export const rateClassificationModel = (model: Model): { classificationRating: number; fitStatus: string } => {
  if (model.metrics) {
    const validationAccuracy = model.metrics['validation:accuracy'] as number
    const trainAccuracy = model.metrics['train:accuracy'] as number

    // Calculate individual scores
    const validationScore = validationAccuracy * 5 // Scale to 5 points
    // Scale to 5 points
    // Calculate overall scores
    const trainOverallScore = trainAccuracy * 5
    const valOverallScore = validationScore

    // Determine fit status
    let fitStatus = 'Good fit'
    const accuracyDifference = trainAccuracy - validationAccuracy
    if (accuracyDifference > 0.05) {
      fitStatus = 'Potential overfitting'
    } else if (validationAccuracy < 0.6 && trainAccuracy < 0.6) {
      fitStatus = 'Potential underfitting'
    }

    // Calculate final rating
    let finalRating = trainOverallScore * 0.4 + valOverallScore * 0.6

    // Penalize for overfitting or underfitting
    if (fitStatus !== 'Good fit') {
      finalRating *= 0.8
    }

    // Ensure final rating is between 1 and 5
    finalRating = Math.max(1, Math.min(5, finalRating))

    return { classificationRating: finalRating, fitStatus }
  } else {
    return { classificationRating: 0, fitStatus: 'unk' }
  }
}

/**
 * Calculates a rating for a model based on its performance metrics and fit characteristics.
 * @param model - The model
 * @returns A rating between 1 and 5, with 5 being the best
 */

export const rateBBoxModel = (
  model: Model,
  // trainMetrics: { mAP: number; crossEntropy: number; smoothL1: number },
  // valMetrics: { mAP: number; crossEntropy: number; smoothL1: number }
): { bBoxRating: number; fitStatus: string } => {
  const { metrics } = model
  if (metrics) {
    // Calculate model rating
    const trainMetrics = {
      mAP: getSafeMetricValue('train:mAP', metrics),
      crossEntropy: getSafeMetricValue('train:cross_entropy', metrics),
      smoothL1: getSafeMetricValue('train:smooth_l1', metrics),
    }

    const valMetrics = {
      mAP: getSafeMetricValue('validation:mAP', metrics),
      crossEntropy: getSafeMetricValue('validation:cross_entropy', metrics),
      smoothL1: getSafeMetricValue('validation:smooth_l1', metrics),
    }

    // Helper function to calculate individual metric scores
    const calculateScore = (metric: number, isLoss = false) => {
      if (isLoss) {
        return Math.max(0, 5 - metric / 10)
      }
      return metric / 20 // Assuming mAP is 0-100
    }

    // Calculate scores for train and validation sets
    const trainScores = {
      mAP: calculateScore(trainMetrics.mAP),
      crossEntropy: calculateScore(trainMetrics.crossEntropy, true),
      smoothL1: calculateScore(trainMetrics.smoothL1, true),
    }

    const valScores = {
      mAP: calculateScore(valMetrics.mAP),
      crossEntropy: calculateScore(valMetrics.crossEntropy, true),
      smoothL1: calculateScore(valMetrics.smoothL1, true),
    }

    // Calculate overall scores
    const trainOverallScore = trainScores.mAP * 0.6 + trainScores.crossEntropy * 0.2 + trainScores.smoothL1 * 0.2
    const valOverallScore = valScores.mAP * 0.6 + valScores.crossEntropy * 0.2 + valScores.smoothL1 * 0.2

    // Determine fit status
    let fitStatus = 'Good fit'
    const scoreDifference = trainOverallScore - valOverallScore
    if (scoreDifference > 1) {
      fitStatus = 'Potential overfitting'
    } else if (valOverallScore < 2 && trainOverallScore < 2) {
      fitStatus = 'Potential underfitting'
    }

    // Calculate final rating
    let finalRating = (trainOverallScore + valOverallScore) / 2

    // Penalize for overfitting or underfitting
    if (fitStatus !== 'Good fit') {
      finalRating *= 0.8
    }

    // Ensure final rating is between 1 and 5
    finalRating = Math.max(1, Math.min(5, finalRating))

    return { bBoxRating: finalRating, fitStatus }
  } else {
    return { bBoxRating: 0, fitStatus: 'unk' }
  }
}

export const getSafeMetricValue = (key: string, metrics: Record<string, unknown>): number => {
  const value = metrics[key]
  return typeof value === 'number' ? value : 0
}

// Helper function to determine text color based on background color
export const getContrastYIQ = (hexcolor: string) => {
  const r = parseInt(hexcolor.substring(1, 3), 16)
  const g = parseInt(hexcolor.substring(3, 5), 16)
  const b = parseInt(hexcolor.substring(5, 7), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

class SeededRandom {
  private seed: number

  constructor(seed: number) {
    this.seed = seed
  }

  // Linear Congruential Generator (LCG)
  public random(): number {
    const a = 1664525
    const c = 1013904223
    const m = Math.pow(2, 32)
    this.seed = (a * this.seed + c) % m
    return this.seed / m
  }
}

// Function to get a high-resolution time-based seed
function getHighResSeed(): number {
  return Math.floor(performance.now() * 1000)
}

interface ColorResult {
  number: number
  hexColor: string
}

export const generateRandomColor = (): ColorResult => {
  const seed = getHighResSeed()
  const seededRandomColor = new SeededRandom(seed)
  const red = Math.round(seededRandomColor.random() * 255)
  const green = Math.round(seededRandomColor.random() * 255)
  const blue = Math.round(seededRandomColor.random() * 255)

  const whiteDistance = Math.sqrt(red ** 2 + green ** 2 + blue ** 2)
  console.log('whiteDistance: ', whiteDistance)

  const blackDistance = Math.sqrt((red - 255) ** 2 + (green - 255) ** 2 + (blue - 255) ** 2)

  console.log('blackDistance: ', blackDistance)

  // Combine the components into a single uint32 integer
  // tslint:disable-next-line:no-bitwise

  const numberValue = ((red << 24) >>> 0) | (green << 16) | (blue << 8) | 0xff

  // Generate hex value
  const hexValue = `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue
    .toString(16)
    .padStart(2, '0')}`

  return {
    number: numberValue,
    hexColor: hexValue,
  }
}

export const formatNumber = (num: number): string => {
  const suffixes: string[] = ['', 'K', 'M', 'B', 'T']
  let formattedNum = num
  let magnitude = 0

  while (Math.abs(formattedNum) >= 1000 && magnitude < suffixes.length - 1) {
    magnitude += 1
    formattedNum /= 1000
  }

  if (magnitude === 0) {
    return formattedNum.toFixed(2).replace(/\.?0+$/, '')
  }

  return `${formattedNum.toFixed(1).replace(/\.0$/, '')}${suffixes[magnitude]}`
}

export const normalizeBoundingBox = (
  box: AnnotationDataBoundingBox,
  index?: number | undefined,
): AnnotationDataBoundingBox => {
  return {
    ...box,
    xmin: Math.min(box.xmin, box.xmax),
    ymin: Math.min(box.ymin, box.ymax),
    xmax: Math.max(box.xmin, box.xmax),
    ymax: Math.max(box.ymin, box.ymax),
    class_index: index !== undefined ? index : box.class_index,
  }
}
