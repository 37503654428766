// src/services/contentApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import { Content, ContentMetadata, ContentMetadataResponse } from '../types/content'
import {
  DELETE_CONTENT_URL,
  GET_CONTENT_URL,
  GET_RANDOM_CONTENT_URL,
  GET_SINGLE_CONTENT_URL,
  GET_METADATA_URL,
  QUERY_CONTENT_URL,
} from '../utils'

import baseQueryWithReauth from './baseQuery'
import { ListAnnotatedContentResp, QueryContentResp, SampleContentResp } from '../types/responses'
import { Filter } from '../types/filter'

export const contentApi = createApi({
  reducerPath: 'contentApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Content', 'AnnotatedContent', 'Metadata'],
  endpoints: (builder) => ({
    fetchAnnotatedContent: builder.query<
    ListAnnotatedContentResp,
    {
      project_id: string
      dataset_id?: string
      tag_id?: string[]
      operator?: 'and' | 'or'
      limit?: number
      page?: number
      sort_key?: string
      sort_val?: number
      metadata?: ContentMetadata
    }
    >({
      query: (params) => ({
        url: GET_CONTENT_URL,
        params: { ...params, metadata: JSON.stringify(params.metadata) },
      }),
      providesTags: (result, error, arg) => {
        const listTag = arg.dataset_id
          ? [{ type: 'AnnotatedContent' as const, id: 'ANNOTATED_LIST' }]
          : [{ type: 'AnnotatedContent' as const, id: 'UNANNOTATED_LIST' }]

        return result
          ? [
            ...result.content.map(({ id }) => ({ type: 'AnnotatedContent' as const, id })),
            ...listTag,
            // {type: 'AnnotatedContent', id: 'LIST'},
          ]
          : listTag
      },
    }),

    deleteContent: builder.mutation<void, { project_id: string; content_ids: string[] }>({
      query: (params) => ({
        url: DELETE_CONTENT_URL,
        method: 'DELETE',
        body: params,
      }),
      invalidatesTags: (result, error, { content_ids }) => content_ids.map((id) => ({ type: 'Content', id })),
    }),

    queryContent: builder.mutation<
    QueryContentResp,
    {
      filters: Filter[]
      limit?: number
      page?: number
      sort_key?: string
      sort_val?: number
      operator?: 'and' | 'or'
    }
    >({
      query: (params) => ({
        url: QUERY_CONTENT_URL,
        method: 'POST',
        body: { query: params },
      }),
      invalidatesTags: [{ type: 'Content', id: 'LIST' }],
    }),

    sampleContent: builder.query<
    SampleContentResp,
    { project_id: string; count: number; filter_annotated?: boolean; source?: string[] }
    >({
      query: (params) => ({
        url: GET_RANDOM_CONTENT_URL,
        params,
      }),
      providesTags: [{ type: 'Content', id: 'LIST' }],
    }),

    viewContent: builder.query<
    Content,
    { ContentID: string; project_id: string; dataset_id?: string; cacheKey?: string }
    >({
      query: ({ ContentID, cacheKey, ...params }) => ({
        url: `${GET_SINGLE_CONTENT_URL}/${ContentID}`,
        params: { ...params, cacheKey },
      }),
      providesTags: (result, error, { ContentID }) => [
        { type: 'Content' as const, id: ContentID },
        { type: 'AnnotatedContent' as const, id: ContentID },
      ],
    }),

    getContentMetadata: builder.query<ContentMetadataResponse, { project_id: string }>({
      query: ({ project_id, ...params }) => ({
        url: GET_METADATA_URL,
        params: { ...params, project_id: project_id },
      }),
      providesTags: (result, error, { project_id }) => [{ type: 'Metadata', id: 'LIST' }],
    }),

    getContentImage: builder.query<
    string,
    { ContentID: string; project_id: string; dataset_id?: string; timestamp?: number }
    >({
      query: ({ ContentID, ...params }) => ({
        url: `${GET_SINGLE_CONTENT_URL}/${ContentID}`,
        params: { ...params, image: true },
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: async (blob: Blob) => {
        return URL.createObjectURL(blob)
      },
      // Add a custom serializeQueryArgs function
      serializeQueryArgs: ({ queryArgs }) => {
        const { ...rest } = queryArgs
        return JSON.stringify(rest)
      },
      // Merge incoming data instead of replacing it
      merge: (currentCache, newItems) => {
        return currentCache || newItems
      },
      // Only keep unused data in the cache for 60 seconds
      keepUnusedDataFor: 300,
      providesTags: (result, error, { ContentID }) => [{ type: 'Content', id: `${ContentID}-image` }],
    }),
  }),
})

export const {
  useFetchAnnotatedContentQuery,
  useDeleteContentMutation,
  useQueryContentMutation,
  useSampleContentQuery,
  useViewContentQuery,
  useGetContentImageQuery,
  useGetContentMetadataQuery,
} = contentApi

export const {
  util: { invalidateTags },
} = contentApi
