import React, { useState, useCallback, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { CreateTagType, Tag } from '../../types/tag'
import { useGetTagsQuery, useCreateTagMutation, useDeleteTagMutation, tagApi } from '../../services/tagApi'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'
import { contentApi } from '../../services/contentApi'
import { projectsApi } from '../../services/projectsApi'
import { annotationsApi } from '../../services/annotationApi'

interface BulkTagCreatorModalProps {
  show: boolean
  onHide: () => void
}

const BulkTagManagerModal: React.FC<BulkTagCreatorModalProps> = ({ show, onHide }) => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const [newTags, setNewTags] = useState<CreateTagType[]>([])
  const [inputContent, setInputContent] = useState<string>('')
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const { data: existingTagsData, isLoading } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })
  const [createTag] = useCreateTagMutation()
  const [deleteTag] = useDeleteTagMutation()

  const resetState = useCallback(() => {
    setNewTags([])
    setInputContent('')
    setSelectedTags([])
  }, [])

  useEffect(() => {
    if (!show) {
      resetState()
    }
  }, [show, resetState])

  const handleClose = () => {
    resetState()
    onHide()
  }

  const processContent = useCallback(
    (content: string) => {
      const processedTags = content
        .split(/[,\n]/)
        .map((tag) => tag.trim())
        .filter((tag) => tag.length > 0)
        .map((tagName) => ({
          name: tagName,
          color: Math.floor(Math.random() * 16777215),
          dataset_id: project?.datasetid || '',
          project_id: project?.id || '',
        }))
      setNewTags((prevTags) => {
        const combinedTags = [...prevTags, ...processedTags]
        return combinedTags.filter((tag, index) => combinedTags.findIndex((t) => t.name === tag.name) === index)
      })
    },
    [project],
  )

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          processContent(content)
        }
        reader.readAsText(file)
      })
    },
    [processContent],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/plain': ['.txt'],
      'text/csv': ['.csv'],
    },
    multiple: false,
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const content = event.target.value
    setInputContent(content)

    // Check if the last character is a comma or newline
    if (content.endsWith(',') || content.endsWith('\n')) {
      processContent(content)
      setInputContent('')
    }
  }

  const handleInputBlur = () => {
    if (inputContent.trim()) {
      processContent(inputContent)
      setInputContent('')
    }
  }

  const handleTagSelection = (tagIdentifier: string) => {
    const removeNewTag = newTags.find((newTag) => newTag.name === tagIdentifier)
    if (removeNewTag) {
      setNewTags((prevState) => prevState.filter((t) => t.name !== tagIdentifier))
    } else {
      setSelectedTags((prevSelected) =>
        prevSelected.includes(tagIdentifier)
          ? prevSelected.filter((id) => id !== tagIdentifier)
          : [...prevSelected, tagIdentifier],
      )
    }
  }

  const handleDeleteSelected = () => {
    setShowDeleteConfirmation(true)
  }

  const handleSubmit = async () => {
    if (inputContent.trim()) {
      processContent(inputContent)
    }
    let tag = newTags.pop()
    while (tag !== undefined) {
      try {
        await createTag(tag).unwrap()
      } catch (error) {
        console.error('Failed to create tag:', error)
      } finally {
        dispatch(annotationsApi.util.invalidateTags([{ type: 'Annotation', id: 'LIST' }]))
        dispatch(
          contentApi.util.invalidateTags([
            { type: 'Content', id: 'LIST' },
            { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
            { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
          ]),
        )
        dispatch(tagApi.util.invalidateTags([{ type: 'Tag', id: 'LIST' }]))
      }
      tag = newTags.pop()
    }
    setTimeout(() => {
      onHide()
    }, 1000)
  }

  const confirmDelete = async () => {
    setShowDeleteConfirmation(false)
    for (const tagIdentifier of selectedTags) {
      try {
        const tagToDelete =
          existingTagsData?.tags.find((tag) => tag.id === tagIdentifier) ||
          newTags.find((tag) => tag.name === tagIdentifier)

        if (tagToDelete && 'id' in tagToDelete) {
          const res = await deleteTag(tagToDelete.id).unwrap()
        } else if (tagToDelete) {
          setNewTags((prevTags) => prevTags.filter((tag) => tag.name !== tagIdentifier))
        }
      } catch (error) {
        console.error('Failed to delete tag:', error)
      } finally {
        dispatch(annotationsApi.util.invalidateTags([{ type: 'Annotation', id: 'LIST' }]))
        dispatch(
          contentApi.util.invalidateTags([
            { type: 'Content', id: 'LIST' },
            { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
            { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
          ]),
        )
        dispatch(tagApi.util.invalidateTags([{ type: 'Tag', id: 'LIST' }]))
      }
    }
    setTimeout(async () => await handleSubmit(), 500)
    // await handleSubmit()
    setSelectedTags([])
  }

  const renderTagPill = (tag: Tag | CreateTagType, isSelectable = false) => {
    const tagId = 'id' in tag ? tag.id : tag.name
    const isSelected = selectedTags.includes(tagId)

    return (
      <span
        key={`tag-${tagId}`}
        className={`badge rounded-pill me-2 mb-2 ${isSelectable ? 'selectable' : ''} ${isSelected ? 'selected' : ''}`}
        style={{
          backgroundColor: `#${tag.color.toString(16).padStart(6, '0')}`,
          color: getContrastColor(tag.color),
          cursor: isSelectable ? 'pointer' : 'default',
          fontSize: '0.9rem',
          padding: '0.5em 0.8em',
        }}
        onClick={isSelectable ? () => handleTagSelection(tagId) : undefined}
        title={isSelectable ? (isSelected ? 'Click to unselect' : 'Click to select for deletion') : undefined}
      >
        {tag.name}
        {isSelectable && <span className="ms-2">&times;</span>}
      </span>
    )
  }

  const renderSelectedTags = () => {
    const selectedTagObjects = [
      ...newTags.filter((tag) => selectedTags.includes(tag.name)),
      ...(existingTagsData?.tags || []).filter((tag) => selectedTags.includes(tag.id)),
    ]

    return selectedTagObjects?.map((tag) => renderTagPill(tag, true))
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className="bulk-tag-creator-modal" size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Tag Creator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Upload file (TXT or CSV)</Form.Label>
              <div {...getRootProps()} className="dropzone border rounded p-3">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className="mb-0">Drop the files here ...</p>
                ) : (
                  <>
                    <p className="mb-1">Drag-n-drop a csv or newline delimited file here</p>
                    <p className="mb-0">Or, click to select files</p>
                  </>
                )}
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Or, type or paste tags (comma or newline separated)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={inputContent}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                placeholder="Enter tags here..."
                className="border"
              />
            </Form.Group>

            <div className="mb-3">
              <h6>New Tags:</h6>
              {newTags?.map((tag) => renderTagPill(tag, true))}
            </div>

            <hr className="my-4" />

            <div className="mb-3">
              <h6>Existing Tags:</h6>
              {isLoading ? (
                <p>Loading existing tags...</p>
              ) : (
                <>
                  {existingTagsData?.tags?.map((tag) => renderTagPill(tag, true))}
                  {selectedTags.length > 0 && (
                    <>
                      <hr className="my-2" />
                      <div>
                        {/*<Button variant="danger" onClick={handleDeleteSelected} className="mt-3 mb-2">*/}
                        {/*  Delete Selected Tags*/}
                        {/*</Button>*/}
                        <h6>Selected for Deletion:</h6>
                        <div className="mt-2">{renderSelectedTags()}</div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={selectedTags.length > 0 ? handleDeleteSelected : handleSubmit}>
            Accept Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="bulk-tag-deleter-modal"
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Tag Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4">
            Warning: Deleting these tags will affect the entire project and may create null annotations. Are you sure
            you want to proceed?
          </p>
          <h6 className="mb-3">Tags to be deleted:</h6>
          <div className="tags-to-delete">{renderSelectedTags()}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// Helper function to determine text color based on background color
function getContrastColor(hexColor: number): string {
  const r = (hexColor >> 16) & 255
  const g = (hexColor >> 8) & 255
  const b = hexColor & 255
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export default BulkTagManagerModal
