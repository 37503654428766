// src/services/tagApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import { CreateTagType, Tag } from '../types/tag'
import baseQueryWithReauth from './baseQuery'
import { Filter } from '../types/filter'
import { contentApi } from './contentApi'

export const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Tag'],
  endpoints: (builder) => ({
    getTags: builder.query<
    { tags: Tag[]; total: number },
    {
      project_id: string
      dataset_id: string
      limit?: number
      page?: number
      sort_key?: string
      sort_val?: number
    }
    >({
      query: (params) => ({
        url: 'tags',
        params,
      }),
      providesTags: (result) =>
        result && result.tags
          ? [...result.tags.map(({ id }) => ({ type: 'Tag' as const, id })), { type: 'Tag', id: 'LIST' }]
          : [{ type: 'Tag', id: 'LIST' }],
    }),

    queryTags: builder.mutation<
    { tags: Tag[]; total: number },
    {
      page: number
      limit: number
      sort_key?: string
      sort_val?: number
      operator?: 'or' | 'and'
      filters: Filter[]
    }
    >({
      query: (params) => ({
        url: 'tags/query',
        method: 'POST',
        body: params,
      }),
    }),

    getTagById: builder.query<Tag, string>({
      query: (id) => `tags/${id}`,
      providesTags: (result, error, id) => [{ type: 'Tag', id }],
    }),

    createTag: builder.mutation<Tag, CreateTagType>({
      query: (tagData) => ({
        url: 'tags',
        method: 'POST',
        body: tagData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Tag', id: 'LIST' },
        { type: 'Tag', id: `${arg.project_id}-${arg.dataset_id}` },
      ],
    }),

    updateTag: builder.mutation<Tag, { id: string; tagData: { name?: string; property?: string[] } }>({
      query: ({ id, tagData }) => ({
        url: `tags/${id}`,
        method: 'PATCH',
        body: tagData,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Tag', id }],
    }),

    deleteTag: builder.mutation<void, string>({
      query: (id) => ({
        url: `tags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Tag', id }],
    }),

    getTagProperties: builder.query<string[], string>({
      query: (dataset_id) => ({
        url: 'tags/properties',
        params: { dataset_id },
      }),
    }),
  }),
})

export const {
  useGetTagsQuery,
  useGetTagByIdQuery,
  useCreateTagMutation,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useGetTagPropertiesQuery,
  useQueryTagsMutation,
} = tagApi

// Custom hooks for selectors
export const useSelectAllTags = () => {
  const { data } = useGetTagsQuery({ project_id: '', dataset_id: '' })
  return data?.tags || []
}

export const useSelectTagById = (tagId: string) => {
  const { data } = useGetTagByIdQuery(tagId)
  return data
}

export const useSelectTagsByDataset = (datasetId: string) => {
  const { data } = useGetTagsQuery({ project_id: '', dataset_id: datasetId })
  return data?.tags || []
}

export const {
  util: { invalidateTags },
} = tagApi
