import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../index'
import { enableMapSet } from 'immer'
import { AnnotationDataBoundingBox, AnnotationPostResponse } from '../../types/annotation'
import { Prediction, PredictionMetadata } from '../../types/prediction'

// Enable support for Map and Set in Immer
enableMapSet()

export interface AnnotationState {
  sortKey: string
  sortOrder: 'asc' | 'desc'
  view: 'grid' | 'list' | 'slideshow'
  zoomLevel: number
  annotatedContentIds: string[]
  allBoundingBoxes: AnnotationDataBoundingBox[]
  filteredBoundingBoxes: AnnotationDataBoundingBox[]
  editingPredictionMetadata: PredictionMetadata[]
  editingTagIds: string[]
  editingPrediction: Prediction | undefined
}

const initialState: AnnotationState = {
  sortKey: 'created_at',
  sortOrder: 'desc',
  view: 'grid',
  zoomLevel: 2,
  annotatedContentIds: [],
  allBoundingBoxes: [],
  filteredBoundingBoxes: [],
  editingPredictionMetadata: [],
  editingTagIds: [],
  editingPrediction: undefined,
}

const updateUniqueTagIds = (tagIds: string[], newTagId: string): string[] => {
  const updatedTagIds = new Set(tagIds)
  updatedTagIds.add(newTagId)
  return Array.from(updatedTagIds)
}

const annotationSlice = createSlice({
  name: 'annotation',
  initialState,
  reducers: {
    setSortKey: (state, action: PayloadAction<string>) => {
      state.sortKey = action.payload
    },

    toggleSortOrder: (state) => {
      state.sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc'
    },

    zoomIn: (state) => {
      state.zoomLevel = Math.min(state.zoomLevel + 1, 5) // Max zoom level
    },

    zoomOut: (state) => {
      state.zoomLevel = Math.max(state.zoomLevel - 1, 1) // Min zoom level
    },

    addAnnotatedContentIds: (state, action: PayloadAction<string[]>) => {
      const newIds = action.payload
      state.annotatedContentIds = Array.from(new Set([...state.annotatedContentIds, ...newIds]))
    },

    clearAnnotatedContentIds: (state) => {
      state.annotatedContentIds = []
    },

    updateAnnotation: (state, action: PayloadAction<AnnotationPostResponse>) => {
      // state.annotations.push(action.payload)
      console.log(`Updating annotation ${action.payload.id}`)
    },

    setEditingPrediction: (state, action: PayloadAction<Prediction>) => {
      state.editingPrediction = action.payload
    },

    clearEditingPrediction: (state) => {
      state.editingPrediction = undefined
    },

    setAllBoundingBoxes: (state, action: PayloadAction<AnnotationDataBoundingBox[]>) => {
      state.allBoundingBoxes = [...action.payload]
    },

    clearAllBoundingBoxes: (state) => {
      state.allBoundingBoxes = []
    },

    setFilteredBoundingBoxes: (state, action: PayloadAction<AnnotationDataBoundingBox[]>) => {
      state.filteredBoundingBoxes = [...action.payload]
    },

    clearFilteredBoundingBoxes: (state) => {
      state.filteredBoundingBoxes = []
    },

    setEditingPredictionMetadata: (state, action: PayloadAction<PredictionMetadata[]>) => {
      state.editingPredictionMetadata = [...action.payload]
    },

    clearEditingPredictionMetadata: (state) => {
      state.editingPredictionMetadata = []
    },

    addEditingPredictionMetadata: (state, action: PayloadAction<PredictionMetadata>) => {
      const newPredictionMetadata = Array.from(new Set([...state.editingPredictionMetadata, action.payload]))
      state.editingPredictionMetadata = [...newPredictionMetadata]
    },

    removeEditingPredictionMetadata: (state, action: PayloadAction<string>) => {
      const newPredictionMetadata = state.editingPredictionMetadata.filter((p: PredictionMetadata) => {
        p.class_name !== action.payload
      })
      state.editingPredictionMetadata = [...newPredictionMetadata]
    },

    addBoundingBox: (state, action: PayloadAction<AnnotationDataBoundingBox>) => {
      if (state.allBoundingBoxes) {
        state.allBoundingBoxes = [
          ...state.allBoundingBoxes,
          { ...action.payload, class_index: state.allBoundingBoxes.length },
        ]
      } else {
        state.allBoundingBoxes = [{ ...action.payload, class_index: 0 }]
      }

      if (state.filteredBoundingBoxes) {
        state.filteredBoundingBoxes = [
          ...state.filteredBoundingBoxes,
          { ...action.payload, class_index: state.filteredBoundingBoxes.length },
        ]
      } else {
        state.filteredBoundingBoxes = [{ ...action.payload, class_index: 0 }]
      }
      state.editingTagIds = updateUniqueTagIds(state.editingTagIds, action.payload.name)
    },

    updateBoundingBox: (state, action: PayloadAction<{ updatedBBox: AnnotationDataBoundingBox }>) => {
      const { updatedBBox } = action.payload
      const index = updatedBBox.class_index
      if (index !== undefined && index !== -1) {
        state.filteredBoundingBoxes[index] = updatedBBox
        state.allBoundingBoxes[index] = updatedBBox
      }
    },

    removeBoundingBox: (state, action: PayloadAction<AnnotationDataBoundingBox>) => {
      if (state.allBoundingBoxes) {
        state.allBoundingBoxes = state.allBoundingBoxes.filter(
          (box) =>
            !(
              box.name === action.payload.name &&
              box.xmax === action.payload.xmax &&
              box.xmin === action.payload.xmin &&
              box.ymax === action.payload.ymax &&
              box.ymin === action.payload.ymin
            ),
        )
      }
      if (state.filteredBoundingBoxes) {
        state.filteredBoundingBoxes = state.filteredBoundingBoxes.filter(
          (box) =>
            !(
              box.name === action.payload.name &&
              box.xmax === action.payload.xmax &&
              box.xmin === action.payload.xmin &&
              box.ymax === action.payload.ymax &&
              box.ymin === action.payload.ymin
            ),
        )

        // Check if the removed tag is still used by any remaining bounding box
        const isTagStillUsed = state.filteredBoundingBoxes.some((box) => box.name === action.payload.name)
        if (!isTagStillUsed) {
          state.editingTagIds = state.editingTagIds.filter((id) => id !== action.payload.name)
        }
      }
    },

    // Create: Add a new tag ID
    addEditingTagId: (state, action: PayloadAction<string>) => {
      if (!state.editingTagIds.includes(action.payload)) {
        state.editingTagIds.push(action.payload)
      }
    },

    // Read: Selector for editingTagIds is defined outside the slice

    // Update: Set all editing tag IDs
    setEditingTagIds: (state, action: PayloadAction<string[]>) => {
      state.editingTagIds = action.payload
    },

    // Delete: Remove a specific tag ID
    removeEditingTagId: (state, action: PayloadAction<string>) => {
      state.editingTagIds = state.editingTagIds.filter((id) => id !== action.payload)
    },

    // Clear all editing tag IDs
    clearEditingTagIds: (state) => {
      state.editingTagIds = []
    },
  },
})

export const {
  setSortKey,
  toggleSortOrder,
  zoomIn,
  zoomOut,
  addAnnotatedContentIds,
  clearAnnotatedContentIds,
  updateAnnotation,
  setAllBoundingBoxes,
  clearAllBoundingBoxes,
  setFilteredBoundingBoxes,
  clearFilteredBoundingBoxes,
  updateBoundingBox,
  removeBoundingBox,
  addBoundingBox,
  setEditingPredictionMetadata,
  clearEditingPredictionMetadata,
  addEditingPredictionMetadata,
  removeEditingPredictionMetadata,
  addEditingTagId,
  setEditingTagIds,
  removeEditingTagId,
  clearEditingTagIds,
  setEditingPrediction,
  clearEditingPrediction,
} = annotationSlice.actions

export const selectSortKey = (state: RootState) => state.annotation.sortKey
export const selectSortOrder = (state: RootState) => state.annotation.sortOrder
export const selectView = (state: RootState) => state.annotation.view
export const selectZoomLevel = (state: RootState) => state.annotation.zoomLevel
export const selectAnnotatedContentIds = (state: RootState) => state.annotation.annotatedContentIds

export const selectAllBoundingBoxes = createSelector(
  [(state: RootState) => state.annotation.allBoundingBoxes],
  (allBoundingBoxes): AnnotationDataBoundingBox[] => allBoundingBoxes,
)

export const selectFilteredBoundingBoxes = createSelector(
  [(state: RootState) => state.annotation.filteredBoundingBoxes],
  (filteredBoundingBoxes): AnnotationDataBoundingBox[] => filteredBoundingBoxes,
)

export const selectEditingTagIds = (state: RootState) => state.annotation.editingTagIds
export const selectEditingPredictionMetadata = (state: RootState) => state.annotation.editingPredictionMetadata

export const selectEditingPrediction = createSelector(
  [(state: RootState) => state.annotation.editingPrediction],
  (editingPrediction): Prediction | undefined => editingPrediction,
)
export default annotationSlice.reducer
