import React from 'react'
import { Star, StarFill, StarHalf } from 'react-bootstrap-icons'

interface StarRatingProps {
  rating: number
}

const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
  const fullStars = Math.floor(rating)
  const hasHalfStar = rating % 1 >= 0.5

  return (
    <span>
      {[...Array(5)].map((_, index) => {
        if (index < fullStars) {
          return <StarFill key={index} className="text-warning" />
        } else if (index === fullStars && hasHalfStar) {
          return <StarHalf key={index} className="text-warning" />
        } else {
          return <Star key={index} className="text-warning" />
        }
      })}
    </span>
  )
}

export default StarRating
