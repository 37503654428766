import React, { useEffect, useRef } from 'react'
import { Card, Button, Nav, Row, Col, Badge } from 'react-bootstrap'
import { Plus } from 'lucide-react'
import { useFetchModelsQuery, useCreateModelMutation } from '../../services/modelsApi'
import { setSelectedModelId, selectSelectedModelId } from '../../store/slices/modelSlice'
import { rateBBoxModel, rateClassificationModel } from '../../utils'
import StarRating from '../StarRating'
import { Model, ModelCreateParams } from '../../types/model'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'

const ModelTools: React.FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)

  const selectedModelId = useAppSelector(selectSelectedModelId)

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const {
    data: modelsData,
    isLoading,
    error,
    refetch: refetchModels,
  } = useFetchModelsQuery({ project_id: project?.id || '' })
  const [createModel] = useCreateModelMutation()

  useEffect(() => {
    const shouldPoll = modelsData?.models?.some((model: Model) => model.deployment?.status !== 'TRAINED')

    if (shouldPoll) {
      pollingIntervalRef.current = setInterval(() => {
        refetchModels()
      }, 3000) // Poll every 5 seconds
    } else if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = null
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
      }
    }
  }, [modelsData, refetchModels])

  useEffect(() => {
    if (modelsData && modelsData.models?.length > 0 && !selectedModelId) {
      setTimeout(() => dispatch(setSelectedModelId(modelsData.models[0].id)), 0)
    }
  }, [dispatch, modelsData, selectedModelId])

  const generateModelName = () => {
    const uid = Math.random().toString(36).substring(2, 4)
    const date = new Date().toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })
    return `${uid} - ${date}`
  }

  const handleGenerateNewModel = async () => {
    if (project) {
      try {
        const newModel = await createModel({
          project_id: project.id,
          name: generateModelName(),
          content_sources: [],
        } as ModelCreateParams).unwrap()
        dispatch(setSelectedModelId(newModel.id))
      } catch (err) {
        console.error('Failed to create new model:', err)
      }
    }
  }

  const bBoxRating = (model: Model) => {
    return rateBBoxModel(model)
  }

  const classificationRating = (model: Model) => {
    return rateClassificationModel(model)
  }

  const handleSelectModelId = (modelId: string) => {
    if (selectedModelId !== modelId) {
      dispatch(setSelectedModelId(modelId))
    }
  }

  if (isLoading) return <div>Loading models...</div>
  if (error) return <div>Error loading models</div>

  return (
    <Card className="model-tools">
      <Card.Body>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3>Models</h3>
        </div>

        <Button className="generate-model-btn w-100 mb-3" onClick={handleGenerateNewModel}>
          <Plus size={16} /> New Model
        </Button>

        <Nav variant="pills" className="flex-column model-list">
          {modelsData?.models?.map((model: Model) => (
            <Nav.Item key={model.id} className={'model-item'}>
              <Nav.Link
                eventKey={model.id}
                active={model.id === selectedModelId}
                onClick={() => handleSelectModelId(model.id)}
              >
                <div className="model-header">
                  {/* Banner for the Model Name */}
                  <div className="model-banner">
                    <span>{model.name}</span>
                  </div>

                  {/* Stars in the Top Right */}
                  {model.state === 'TRAINED' && (
                    <div className="star-rating-container">
                      <div className="rating-label">Rating</div>
                      {project?.annotation_type === 'classification' && (
                        <div className="star-rating">
                          <StarRating rating={classificationRating(model).classificationRating} />
                        </div>
                      )}
                      {project?.annotation_type === 'bounding_box' && (
                        <div className="star-rating">
                          <StarRating rating={bBoxRating(model).bBoxRating} />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Model date directly under the banner and mAP to the right */}
                <div className="d-flex justify-content-between align-items-center">
                  <div className="model-date">{new Date(model.created_at).toLocaleString()}</div>
                </div>

                <Row className="d-flex justify-content-between align-items-center">
                  <Col>
                    <div className="model-state-container d-flex align-items-center">
                      {/* Show nothing if model is in INITIALIZED state */}
                      {model.state === 'TRAINING' && (
                        <Badge pill className="badge-training">
                          Training
                        </Badge>
                      )}

                      {model.state === 'TRAINED' && model.deployment?.status === '' && (
                        <Badge pill className="badge-training">
                          Trained
                        </Badge>
                      )}

                      {model.state === 'ERR' && model.deployment?.status === '' && (
                        <Badge pill className="badge-danger">
                          ERROR
                        </Badge>
                      )}

                      {/* Show "Deploying" if deployment is CREATING */}
                      {model.state === 'TRAINED' && model.deployment?.status === 'CREATING' && (
                        <Badge pill className="badge-deploying">
                          Deploying
                        </Badge>
                      )}

                      {/* Show "Deployed" if deployment is IN_SERVICE */}
                      {model.state === 'TRAINED' && model.deployment?.status === 'IN_SERVICE' && (
                        <Badge pill className="badge-deployed">
                          Deployed
                        </Badge>
                      )}

                      {/* Show "Trained" if model is trained and not in deployment process */}
                      {model.state === 'TRAINED' && model.deployment?.status === 'DELETED' && (
                        <Badge pill className="badge-training">
                          Trained
                        </Badge>
                      )}
                    </div>
                  </Col>

                  <Col className="d-flex justify-content-end">
                    {model.metrics &&
                      model.metrics['validation:mAP'] !== undefined &&
                      model.metrics['validation:mAP'] !== null && (
                      <div className="map-box">
                        <div className="map-label">mAP</div>
                        <div className="map-value">
                          {`${Math.ceil(Number(model.metrics['validation:mAP']) * 100)}%`}
                        </div>
                      </div>
                    )}
                    {model.metrics &&
                      model.metrics['train:smooth_l1'] !== undefined &&
                      model.metrics['validation:mAP'] !== null && (
                      <div className="map-box">
                        <div className="map-label">L1</div>
                        <div className="map-value">
                          {`${Math.ceil(Number(model.metrics['train:smooth_l1']) * 100)}%`}
                        </div>
                      </div>
                    )}
                    {model.metrics &&
                      model.metrics['train:cross_entropy'] !== undefined &&
                      model.metrics['validation:mAP'] !== null && (
                      <div className="map-box">
                        <div className="map-label">Loss</div>
                        <div className="map-value">
                          {`${Math.ceil(Number(model.metrics['train:cross_entropy']) * 100)}%`}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        {/*<PredictionStatisticsTable modelId={selectedModelId || ''} />*/}
      </Card.Body>
    </Card>
  )
}

export default ModelTools
