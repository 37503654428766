import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { AnnotationDataBoundingBox } from '../../types/annotation'
import ClassPills from '../ClassPills'
import { useGetTagsQuery } from '../../services/tagApi'
import { Tag } from '../../types/tag'
import { useAppSelector } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'

interface TagDropdownsProps {
  boundingBoxes: AnnotationDataBoundingBox[]
}

const BBoxTagDropDowns: React.FC<TagDropdownsProps> = ({ boundingBoxes }) => {
  const project = useAppSelector(selectSelectedProject)

  const uniqueTags = Array.from(new Set(boundingBoxes.map((bbox) => bbox.name)))

  const { data: tags } = useGetTagsQuery({
    project_id: project?.id || '',
    dataset_id: project?.datasetid || '',
  })

  return (
    <>
      {uniqueTags.map((tag) => (
        <Dropdown key={tag} className="mb-2">
          <Dropdown.Toggle variant="primary" style={{ width: '100%' }} id={`dropdown-${tag}`}>
            {tags?.tags?.find((t: Tag) => t?.id === tag)?.name || 'unk'} (
            {boundingBoxes.filter((bbox) => bbox.name === tag).length})
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ClassPills tagIds={boundingBoxes.filter((bbox) => bbox.name === tag).map((bbox) => bbox.name)} />
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </>
  )
}

export default BBoxTagDropDowns
