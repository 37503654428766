import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Alert, Row, Col, Form } from 'react-bootstrap'
import { FileText, X } from 'lucide-react'
import {
  selectError,
  selectProgress,
  selectFilesUploading,
  setSelectedFiles,
  selectSelectedFiles,
  setFilesUploading,
  setProgress,
  setError,
  clearSelectedFiles,
  resetUploadStats,
  updateDatasetStats,
  setSelectedTiffFiles,
  selectSelectedTiffFiles,
  setFilesUploaded,
  setSelectedDatasetSource,
  clearSelectedDatasetSource,
  selectSelectedDatasetSource,
} from '../../store/slices/datasetSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { FileInfo } from '../../types/file'
import FileUploader from '../FileUploader'
import { projectsApi, useUploadProjectContentMutation, useUploadTiffMutation } from '../../services/projectsApi'
import { contentApi /*, useGetContentMetadataQuery*/ } from '../../services/contentApi'
import { selectSelectedProject } from '../../store/slices/projectSlice'
import { datasetApi } from '../../services/datasetApi'
// import DatasetSourceDropdown from '../tools/DatasetSourceDropdown'
import { ContentMetadata } from '../../types/content'
import { annotationsApi } from '../../services/annotationApi'
import { tagApi } from '../../services/tagApi'

const UploadView: React.FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const selectedFiles = useAppSelector(selectSelectedFiles)
  const selectedTiffFiles = useAppSelector(selectSelectedTiffFiles)
  const uploading = useAppSelector(selectFilesUploading)
  const progress = useAppSelector(selectProgress)
  const error = useAppSelector(selectError)

  const selectedDatasetSource = useAppSelector(selectSelectedDatasetSource)

  const [uploadProjectContent] = useUploadProjectContentMutation()
  const [uploadTiff] = useUploadTiffMutation()

  const labelsFileInputRef = useRef<HTMLInputElement>(null)

  const [files, setFiles] = useState<File[]>([])
  const [tiffFiles, setTiffFiles] = useState<File[]>([])
  const [labelsFile, setLabelsFile] = useState<File | null>(null)
  const [labelsFileInfo, setLabelsFileInfo] = useState<FileInfo | null>(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [filteredDatasets, setFilteredDatasets] = useState<string[]>([])
  const [inputValue, setInputValue] = useState('')
  const [isFlashing, setIsFlashing] = useState(false)
  const [overwrite, setOverwrite] = useState(false)

  // const { data: metadata } = useGetContentMetadataQuery({
  //   project_id: project?.id || '',
  // })

  useEffect(() => {
    dispatch(setFilesUploaded(false))
  }, [dispatch])

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      let fileInfos: FileInfo[] = []
      let tiffInfos: FileInfo[] = []

      acceptedFiles.forEach((f) => {
        if (f.type === 'image/tiff') {
          setTiffFiles((prevFiles) => [...prevFiles, f])
          tiffInfos = acceptedFiles.map((file) => ({
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
          }))
        } else {
          setFiles((prevFiles) => [...prevFiles, f])
          fileInfos = acceptedFiles.map((file) => ({
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
          }))
        }
      })
      dispatch(setSelectedTiffFiles([...selectedTiffFiles, ...tiffInfos]))
      dispatch(setSelectedFiles([...selectedFiles, ...fileInfos]))
    },
    [dispatch, selectedFiles, selectedTiffFiles],
  )

  const handleLabelButtonClick = () => {
    labelsFileInputRef.current?.click()
  }

  const handleLabelsFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && file.name.endsWith('.json')) {
      setLabelsFile(file)
      setLabelsFileInfo({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
      })
    } else {
      setLabelsFile(null)
      setLabelsFileInfo(null)
      console.error('Invalid file type. Please select a JSON file.')
    }
  }

  const handleDatasetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setShowDropdown(true)
  }

  const handleDatasetNameKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (inputValue.trim().length > 1) {
        dispatch(setSelectedDatasetSource(inputValue.trim()))
        setIsFlashing(true)
        setTimeout(() => setIsFlashing(false), 300) // Duration of the flash effect
      }
      setShowDropdown(false)
    }
  }

  // const handleDatasetNameBlur = () => {
  //   setTimeout(() => {
  //     setShowDropdown(false)
  //   }, 200)
  // }

  // const selectDatasetName = (name: string) => {
  //   setInputValue(name)
  //   dispatch(setSelectedDatasetSource(selectedDatasetSource))
  //   setShowDropdown(false)
  //   setIsFlashing(true)
  //   setTimeout(() => setIsFlashing(false), 300) // Duration of the flash effect
  // }

  const uploadFiles = async () => {
    dispatch(resetUploadStats())
    dispatch(setFilesUploading(true))
    dispatch(setProgress(0))
    dispatch(setError(null))
    dispatch(contentApi.util.invalidateTags([{ type: 'Metadata', id: 'LIST' }]))

    try {
      const totalTiffFiles = tiffFiles.length
      let tiffBatchSize = 100
      if (totalTiffFiles < 100) {
        tiffBatchSize = 10
      }

      const tiffBatches = Math.ceil(totalTiffFiles / tiffBatchSize)

      for (let i = 0; i < tiffBatches; i++) {
        const start = i * tiffBatchSize
        const end = Math.min((i + 1) * tiffBatchSize, totalTiffFiles)
        const batchFiles = tiffFiles.slice(start, end)

        if (project?.id) {
          const resp = await uploadTiff({
            id: project?.id,
            files: batchFiles,
            clip_size: 512,
            metadata: { dataset: selectedDatasetSource },
            overwrite: overwrite,
          }).unwrap()

          dispatch(updateDatasetStats(resp))
          const currentProgress = Math.round(((i + 1) / tiffBatches) * 100)
          dispatch(setProgress(currentProgress))
        }
      }

      const totalFiles = files.length
      let batchSize = 100
      if (totalFiles < 100) {
        batchSize = 10
      }

      const batches = Math.ceil(totalFiles / batchSize)

      for (let i = 0; i < batches; i++) {
        const start = i * batchSize
        const end = Math.min((i + 1) * batchSize, totalFiles)
        const batchFiles = files.slice(start, end)

        if (labelsFile) {
          batchFiles.push(labelsFile)
        }

        const metadata = { dataset: selectedDatasetSource } as ContentMetadata

        if (project?.id) {
          const resp = await uploadProjectContent({
            id: project?.id,
            files: batchFiles,
            labels_file: labelsFile ? labelsFile.name : '',
            metadata: metadata,
            overwrite: overwrite,
          }).unwrap()

          dispatch(updateDatasetStats(resp))
          const currentProgress = Math.round(((i + 1) / batches) * 100)
          dispatch(setProgress(currentProgress))
        } else {
          console.log('No project ID provided')
        }
      }
    } catch (err) {
      dispatch(setError('An error occurred during upload. Please try again.'))
    } finally {
      dispatch(setFilesUploading(false))
      dispatch(setFilesUploaded(true))
      dispatch(clearSelectedFiles())
      dispatch(clearSelectedDatasetSource())
      setFiles([])

      dispatch(annotationsApi.util.invalidateTags([{ type: 'Annotation', id: 'LIST' }]))
      dispatch(tagApi.util.invalidateTags([{ type: 'Tag', id: 'LIST' }]))
      dispatch(
        contentApi.util.invalidateTags([
          { type: 'Content', id: 'LIST' },
          { type: 'AnnotatedContent', id: 'ANNOTATED_LIST' },
          { type: 'AnnotatedContent', id: 'UNANNOTATED_LIST' },
          { type: 'Metadata', id: 'LIST' },
        ]),
      )
      dispatch(projectsApi.util.invalidateTags([{ type: 'Project', id: project?.id }]))
      dispatch(datasetApi.util.invalidateTags([{ type: 'Dataset', id: project?.datasetid }]))
    }
  }

  // const removeDatasetName = (e: React.MouseEvent, name: string) => {
  //   e.stopPropagation()
  //   if (inputValue === name) {
  //     setInputValue('')
  //     dispatch(clearSelectedDatasetSource())
  //   }
  // }

  return (
    <>
      <Row className="my-3">
        {/* Select labels.json */}
        {/*<Col xs={12} sm={3} lg={3} className="d-flex justify-content-center text-start">*/}
        <Col xs={12} md={4} className="d-flex justify-content-center text-start">
          <div className="d-flex align-items-center justify-content-start">
            <input
              ref={labelsFileInputRef}
              type="file"
              accept=".json"
              onChange={handleLabelsFileChange}
              style={{ display: 'none' }}
            />
            <Button
              variant={labelsFileInfo ? 'success' : 'danger'}
              onClick={handleLabelButtonClick}
              className="d-flex align-items-center justify-content-center labels-json-btn me-4"
              size="sm"
            >
              <FileText size={16} />
            </Button>
            <span className="labels-json-text">
              {labelsFileInfo ? `${labelsFileInfo.name} selected` : 'Select labels.json'}
            </span>
          </div>
        </Col>

        {/*/!* Dataset Name selector *!/*/}
        {/*<Col xs={12} sm={3} lg={2} className="d-flex align-items-center justify-content-center">*/}
        {/*  <div className="position-relative w-100">*/}
        {/*    <Form.Label className="small">Select Dataset</Form.Label>*/}
        {/*    <DatasetSourceDropdown />*/}
        {/*  </div>*/}
        {/*</Col>*/}

        {/*/!* Overwrite metadata option *!/*/}
        {/*<Col xs={12} sm={3} lg={2} className="d-flex align-items-center justify-content-center">*/}
        {/*  <div className="position-relative">*/}
        {/*    <Form.Label className="small">Process Duplicates</Form.Label>*/}
        {/*    <Form.Check className="small" type="switch" checked={overwrite} onChange={() => setOverwrite(!overwrite)} />*/}
        {/*  </div>*/}
        {/*</Col>*/}

        {/*# of Files */}
        {/*<Col xs={12} sm={3} lg={2} className="d-flex align-items-center justify-content-center">*/}
        {/*  <div className="d-flex flex-column align-items-center">*/}
        {/*    <div className="d-flex align-items-center justify-content-center">*/}
        {/*      <span className="h4 me-4 mt-2">{files.length + tiffFiles.length}</span>*/}
        {/*      <span style={{ fontSize: '0.9rem' }}>*/}
        {/*        file{files.length + tiffFiles.length !== 1 ? 's' : ''} selected*/}
        {/*      </span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Col>*/}

        {/*<Col xs={12} sm={3} lg={2} className="d-flex align-items-center justify-content-center">*/}
        <Col xs={12} md={4} className="d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex align-items-center justify-content-center">
              <span className="h4 me-4 mt-2">{files.length + tiffFiles.length}</span>
              <span style={{ fontSize: '0.9rem' }}>
                file{files.length + tiffFiles.length !== 1 ? 's' : ''} selected
              </span>
            </div>
          </div>
        </Col>

        {/* Upload files button */}
        {/*<Col xs={12} sm={3} lg={3} className="d-flex justify-content-center text-end">*/}
        <Col xs={12} md={4} className="d-flex justify-content-center text-end">
          <Button
            onClick={uploadFiles}
            disabled={files.length + tiffFiles.length === 0 || uploading} /*|| !selectedDatasetSource}*/
            variant="success"
          >
            {uploading ? 'Uploading...' : 'Upload Files'}
          </Button>
        </Col>
      </Row>
      <div className="file-upload w-full max-w-md mx-auto">
        <Row>
          <Col>
            <div className="bg-gray-900 rounded-lg p-6">
              <FileUploader onFileSelect={onDrop} uploading={uploading} />
            </div>
          </Col>

          <Col xs={12}>
            {uploading && (
              <div className="mt-4">
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <span> Uploading files...</span>
                  <span className="font-weight-bold">{`${progress}%`}</span>
                </div>
                <div style={{ backgroundColor: '#444', borderRadius: '0.25rem', height: '20px', width: '100%' }}>
                  <div
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{
                      width: `${progress}%`,
                      backgroundColor: '#28a745',
                      height: '100%',
                      borderRadius: '0.25rem',
                      transition: 'width 0.6s ease',
                    }}
                  />
                </div>
                <div className="mt-2 text-center">
                  <small>{`${files.length} files selected`}</small>
                </div>
              </div>
            )}

            {error && (
              <Alert variant="danger" className="mt-4">
                {error}
              </Alert>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default UploadView
