// src/services/userApi.ts

import { createApi } from '@reduxjs/toolkit/query/react'
import { User, CreateUserReq, UpdateUserReq, ListUserResp, OkResp } from '../types/user'
import baseQueryWithReauth from './baseQuery'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    createUser: builder.mutation<User, CreateUserReq>({
      query: (userData) => ({
        url: 'users',
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: [{ type: 'User', id: 'LIST' }],
    }),

    getUsers: builder.query<ListUserResp, { limit?: number; page?: number; sort_key?: string; sort_val?: number }>({
      query: (params) => ({
        url: 'users',
        params,
      }),
      providesTags: (result) =>
        result
          ? [...result.users.map(({ id }) => ({ type: 'User' as const, id })), { type: 'User', id: 'LIST' }]
          : [{ type: 'User', id: 'LIST' }],
    }),

    getUserById: builder.query<User, string>({
      query: (id) => `users/${id}`,
      providesTags: (result, error, id) => [{ type: 'User', id }],
    }),

    deleteUser: builder.mutation<OkResp, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'User', id },
        { type: 'User', id: 'LIST' },
      ],
    }),

    updateUser: builder.mutation<User, { id: string; userData: UpdateUserReq }>({
      query: ({ id, userData }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body: userData,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),

    updateUserProfile: builder.mutation<OkResp, { id: string; file: File }>({
      query: ({ id, file }) => {
        const formData = new FormData()
        formData.append('file', file)

        return {
          url: `users/${id}/profile`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),

    deleteUserProfile: builder.mutation<OkResp, string>({
      query: (id) => ({
        url: `users/${id}/profile`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'User', id }],
    }),

    getUserEmailVerificationCode: builder.query<OkResp, { id: string; email: string }>({
      query: ({ id, email }) => ({
        url: `users/${id}/email-verification`,
        params: { email },
      }),
      providesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),

    confirmUserEmailVerificationCode: builder.query<OkResp, { id: string; email: string; code: string }>({
      query: ({ id, email, code }) => ({
        url: `users/${id}/email-verification-confirm`,
        params: { email, code },
      }),
      providesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
  }),
})

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateUserProfileMutation,
  useDeleteUserProfileMutation,
  useGetUserEmailVerificationCodeQuery,
  useConfirmUserEmailVerificationCodeQuery,
} = userApi
