// src/components/FileUploader.tsx

import React from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { Spinner } from 'react-bootstrap'
import { CloudUpload } from 'lucide-react'

interface FileUploaderProps {
  onFileSelect: (files: File[]) => void
  uploading?: boolean
  accept?: DropzoneOptions['accept']
  maxSize?: number
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onFileSelect,
  uploading = false,
  accept = {
    'image/tiff': ['.tiff'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
  },
  maxSize = 5 * 1024 * 1024 * 1024, // 5 GB
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileSelect,
    accept,
    maxSize,
  })

  return (
    <>
      <div className="w-full max-w-md mx-auto">
        <div className="drag-drop-area bg-gray-900 rounded-lg p-6">
          <div
            {...getRootProps()}
            className="border-2 border-dashed border-gray-600 rounded-lg p-8 text-center cursor-pointer"
          >
            <input {...getInputProps()} multiple />
            {uploading ? (
              <Spinner animation="border" variant="light" className="mx-auto mb-4" />
            ) : (
              <CloudUpload className="upload-icon mx-auto mb-4 text-gray-400" size={48} />
            )}
            <p className="text-white text-lg font-semibold">
              {uploading ? 'Uploading...' : isDragActive ? 'Drop the files here' : 'Click or Drag and Drop files'}
            </p>
            {!uploading && (
              <>
                <p className="small">TIFF, JPG, JPEG and PNG images only</p>
                <p className="small">Maximum up to 5 GB per upload</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default FileUploader
