import React from 'react'
import { useFetchUserProfileQuery } from '../services/authApi'
import { Container, Row, Col, Card, Image } from 'react-bootstrap'
import { Person, ArrowLeft } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

const Profile: React.FC = () => {
  const { data: user, isLoading } = useFetchUserProfileQuery()

  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  const profileImage = user?.profile_400 || user?.profile_200 || user?.profile_100

  return (
    <Container className="mt-4">
      <div className="back-arrow-container">
        <ArrowLeft className="back-arrow" onClick={handleGoBack} />
      </div>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card bg="dark" text="light">
            <Card.Body>
              <div className="text-center mb-4">
                {profileImage ? (
                  <Image
                    src={`data:image/;base64,${profileImage}`}
                    roundedCircle
                    width={150}
                    height={150}
                    alt="Profile"
                  />
                ) : (
                  <Person size={150} />
                )}
              </div>
              <h2 className="text-center mb-4">{`${user?.first_name} ${user?.last_name}`}</h2>
              <Row>
                <Col sm={6}>
                  <p>
                    <strong>Username:</strong> {user?.username}
                  </p>
                  <p>
                    <strong>Email:</strong> {user?.email}
                  </p>
                  <p>
                    <strong>User ID:</strong> {user?.id}
                  </p>
                </Col>
                <Col sm={6}>
                  <p>
                    <strong>Created:</strong> {new Date(user?.created_at || '').toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Last Login:</strong> {new Date(user?.last_login || '').toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Last Password Change:</strong>{' '}
                    {new Date(user?.last_password_change || '').toLocaleDateString()}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Profile
