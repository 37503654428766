// src/components/view/ProjectView.tsx

import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Spinner, Button, Form } from 'react-bootstrap'
import { FaTrello, FaTags, FaImages, FaDatabase, FaPencilAlt, FaExclamationTriangle } from 'react-icons/fa'
import {
  useGetProjectQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  projectsApi,
} from '../../services/projectsApi'
import { useFetchDatasetQuery, useDeleteDatasetMutation } from '../../services/datasetApi'
import OverviewCard from '../OverviewCard'

import {} from 'react-icons/fa'
import { useAppDispatch, useAppSelector, useClassDistribution } from '../../store/hooks'
import { selectSelectedProject } from '../../store/slices/projectSlice'
import { useNavigate } from 'react-router-dom'
// import {useGetContentMetadataQuery} from '../../services/contentApi'

const ProjectView: React.FC = () => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(selectSelectedProject)
  const navigate = useNavigate()
  const classDistribution = useClassDistribution(project?.id || '', project?.datasetid || '')

  const totalImages = classDistribution.reduce((sum, item) => sum + item.value, 0)
  const classWithoutUnknown = classDistribution.filter((item) => item.name !== 'Unknown')

  const {
    data: projectData,
    isLoading: isProjectLoading,
    error: projectError,
  } = useGetProjectQuery(project?.id || '', { skip: !project })

  // const {
  //   data: metadata,
  // } = useGetContentMetadataQuery({
  //   project_id: project?.id || '',
  // })

  const {
    data: datasetData,
    isLoading: isDatasetLoading,
    error: datasetError,
  } = useFetchDatasetQuery(project?.datasetid || '', { skip: !project?.datasetid })

  const [deleteProject] = useDeleteProjectMutation()
  const [deleteDataset] = useDeleteDatasetMutation()
  const [updateProject] = useUpdateProjectMutation()

  const [projectName, setProjectName] = useState(projectData?.name || '')
  const [isEditingName, setIsEditingName] = useState(false)

  useEffect(() => {
    if (projectData?.name) {
      setProjectName(projectData.name)
    }
  }, [projectData])

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value)
  }

  const handleNameBlur = async () => {
    if (projectData?.id && projectName !== projectData.name) {
      try {
        await updateProject({ id: projectData.id, data: { name: projectName } }).unwrap()
      } catch (err) {
        console.error('Failed to update project name:', err)
      }
    }
    setIsEditingName(false)
  }

  const handleDeleteProject = async () => {
    try {
      await deleteProject(project?.id || '').unwrap()
      dispatch(projectsApi.util.invalidateTags([{ type: 'Project' }]))
      navigate('/projects}')
    } catch (err) {
      console.error('Failed to delete project:', err)
    }
  }

  const handleDeleteDataset = async () => {
    if (project?.datasetid) {
      try {
        await deleteDataset({
          projectId: project?.id || '',
          datasetId: project?.datasetid || '',
        }).unwrap()
        dispatch(projectsApi.util.invalidateTags([{ type: 'Project', id: 'LIST' }]))
        // Handle post-delete actions
      } catch (err) {
        console.error('Failed to delete dataset:', err)
      }
    }
  }

  if (isProjectLoading || isDatasetLoading) {
    return (
      <Card className="project-view">
        <Card.Body className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Card.Body>
      </Card>
    )
  }

  if (projectError || datasetError) {
    return (
      <Card className="project-view">
        <Card.Body>
          <p className="text-danger">
            {projectError ? 'Error loading project data.' : ''}
            {datasetError ? 'Error loading dataset data.' : ''}
          </p>
        </Card.Body>
      </Card>
    )
  }

  if (!projectData) {
    if (!project) {
      navigate('/projects/')
    }
  }

  return (
    <>
      <Row className="project-view-header">
        <Col xs={8}>
          {isEditingName ? (
            <Form.Control
              type="text"
              value={projectName}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              autoFocus
              style={{ maxWidth: '300px' }}
            />
          ) : (
            <div className="position-relative" style={{ display: 'inline-block' }}>
              <h2 className="project-name" style={{ marginRight: '25px', display: 'inline-block' }}>
                {projectData?.name}
              </h2>
              <FaPencilAlt
                style={{
                  cursor: 'pointer',
                  fontSize: '0.8em',
                  position: 'absolute',
                  top: '0',
                  right: '-20px',
                  color: 'blue',
                }}
                onClick={() => setIsEditingName(true)}
              />
            </div>
          )}
          <div className="project-details-metadata mt-1">
            Created: {new Date(projectData?.created_at || '').toLocaleString()}
          </div>
        </Col>
        <Col xs={4} className="text-right">
          <Button variant="danger" onClick={handleDeleteProject}>
            Delete Project
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="d-flex justify-content-end">
          {/* Classes Card */}
          <OverviewCard
            title={'Classes'}
            value={classWithoutUnknown.length}
            icon={<FaTrello size={35} className="text-primary" style={{ marginRight: '5px' }} />}
          />

          {/* Images Card */}
          <OverviewCard
            title={'Images'}
            value={project?.count || 0}
            icon={<FaImages size={35} className="text-info" style={{ marginRight: '5px' }} />}
          />

          {/* Datasets Card */}
          {/*<OverviewCard*/}
          {/*  title={'Datasets'}*/}
          {/*  value={(metadata?.metadata?.dataset as Array<string>)?.length || 0}*/}
          {/*  icon={<FaDatabase size={35} className="text-success" style={{ marginRight: '10px' }} />}*/}
          {/*/>*/}

          {/* Annotations Card */}
          <OverviewCard
            title={'Annotations'}
            value={totalImages}
            icon={<FaTags size={35} className="text-success" style={{ marginRight: '10px' }} />}
          />
        </Col>
      </Row>
      <Card className="bg-dark text-light border-0 p-2 shadow-sm overview-card me-3" style={{ border: 'none' }}>
        <Card.Body>
          <h3>Project Details</h3>
          <p>Project ID: {projectData?.id}</p>
          <p>{projectData?.description}</p>
          <p>License: {projectData?.license}</p>
          <p>Annotation Type: {projectData?.annotation_type}</p>
          <p>Total Items: {projectData?.count}</p>
          <p>Last Updated: {new Date(projectData?.updated_at || '').toLocaleString()}</p>

          {datasetData ? (
            <>
              <h3>Dataset Details</h3>
              <p>Dataset ID: {datasetData.dataset.id}</p>
              <p>
                Split: Train - {datasetData.dataset.split.train}%, Validation - {datasetData.dataset.split.validation}%,
                Test - {datasetData.dataset.split.test}%
              </p>
              <p>Version: {datasetData.dataset.version}</p>
              {/*<Button variant="danger" onClick={handleDeleteDataset}>*/}
              {/*  Delete Dataset*/}
              {/*</Button>*/}
            </>
          ) : (
            <Card className="text-center shadow mt-4" style={{ border: 'none' }}>
              <Card.Body>
                <FaExclamationTriangle size={60} color="red" className="mb-4" />
                <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>No Dataset Available</Card.Title>
                <Card.Text style={{ fontSize: '16px', color: '#6c757d' }}>
                  This project does not have an associated dataset. You can create one to start training models.
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default ProjectView
