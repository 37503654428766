// src/services/projectsApi.ts
import { createApi } from '@reduxjs/toolkit/query/react'
import { PROJECTS_URL } from '../utils'
import baseQueryWithReauth from './baseQuery'
import { CreateProjectResp, ListProjectsResp, UploadProjectResp } from '../types/responses'
import { Project } from '../types/project'
import { CreateProjectReq, ProjectQueryDateTime, ProjectQueryRegex } from '../types/requests'
import { Content, ContentMetadata } from '../types/content'

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Project'],

  endpoints: (builder) => ({
    fetchProjects: builder.query<
    ListProjectsResp,
    { limit?: number; page?: number; sort_key?: string; sort_val?: number }
    >({
      query: (params) => ({
        url: PROJECTS_URL,
        params,
      }),
      providesTags: ['Project'],
    }),

    createProject: builder.mutation<CreateProjectResp, CreateProjectReq>({
      query: (projectData) => ({
        url: PROJECTS_URL,
        method: 'POST',
        body: projectData,
      }),
      invalidatesTags: ['Project'],
    }),

    getProject: builder.query<Project, string>({
      query: (id) => `${PROJECTS_URL}/${id}`,
      providesTags: (result, error, id) => [{ type: 'Project', id }],
    }),

    updateProject: builder.mutation<Project, { id: string; data: Partial<Project> }>({
      query: ({ id, data }) => ({
        url: `${PROJECTS_URL}/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Project', id }],
    }),

    deleteProject: builder.mutation<string, string>({
      query: (id) => ({
        url: `${PROJECTS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Project' }],
    }),

    queryProjects: builder.query<{ projects: Project[]; total: number }, ProjectQueryRegex | ProjectQueryDateTime>({
      query: (queryParams) => ({
        url: `${PROJECTS_URL}/query`,
        method: 'POST',
        body: queryParams,
      }),
      providesTags: (result) =>
        result
          ? [...result.projects.map(({ id }) => ({ type: 'Project' as const, id })), { type: 'Project', id: 'LIST' }]
          : [{ type: 'Project', id: 'LIST' }],
    }),

    updateProjectProfile: builder.mutation<void, { id: string; file: File; random?: boolean }>({
      query: ({ id, file, random }) => {
        const formData = new FormData()
        formData.append('file', file)
        return {
          url: `${PROJECTS_URL}/${id}/profile`,
          method: 'POST',
          body: formData,
          params: { random },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Project', id }],
    }),

    uploadProjectContent: builder.mutation<
    UploadProjectResp,
    {
      id: string
      files: File[]
      overwrite?: boolean
      labels_file?: string
      metadata?: ContentMetadata
    }
    >({
      query: ({ id, files, labels_file /*, metadata, overwrite*/ }) => {
        const formData = new FormData()
        files.forEach((file) => formData.append('files', file))
        return {
          url: `${PROJECTS_URL}/${id}/upload`,
          method: 'POST',
          body: formData,
          // params: { labels_file, metadata: JSON.stringify(metadata), overwrite },
          params: { labels_file },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Project', id: id }],
    }),

    uploadTiff: builder.mutation<
    UploadProjectResp,
    {
      id: string
      files: File[]
      clip_size: number
      overwrite?: boolean
      metadata?: ContentMetadata
    }
    >({
      query: ({ id, files, clip_size /*, metadata, overwrite*/ }) => {
        const formData = new FormData()
        files.forEach((file) => formData.append('files', file))
        return {
          url: `${PROJECTS_URL}/${id}/uploadTiff`,
          method: 'POST',
          body: formData,
          // params: { clip_size, metadata: JSON.stringify(metadata), overwrite },
          params: { clip_size },
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Project', id }],
    }),
  }),
})

export const {
  useFetchProjectsQuery,
  useCreateProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useQueryProjectsQuery,
  useUpdateProjectProfileMutation,
  useUploadProjectContentMutation,
  useUploadTiffMutation,
} = projectsApi
