// src/pages/Projects.tsx

import React, { useState } from 'react'
import { Button, Container, Form, Row } from 'react-bootstrap'
import { useAppDispatch } from '../store/hooks'
import { useFetchUserProfileQuery } from '../services/authApi'
import ProjectCard from '../components/ProjectCard'
import { Project } from '../types/project'
import { useFetchProjectsQuery } from '../services/projectsApi'
import CreateProjectModal from '../components/modals/CreateProjectModal'

const ProjectsPage: React.FC = () => {
  const { data: user } = useFetchUserProfileQuery()
  const [searchTerm, setSearchTerm] = useState('')
  const [showCreateModal, setShowCreateModal] = useState(false)
  const { data: projectsData, refetch } = useFetchProjectsQuery({})

  const dispatch = useAppDispatch()

  const filteredProjects = projectsData?.projects?.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <Container fluid className="mt-4">
      <h1>Hi {user?.first_name}</h1>
      <p className={'subtitle'}>HERE ARE YOUR PROJECTS</p>
      <div className="search-create-container">
        <Form.Control
          type="text"
          placeholder="Search Projects"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="primary" onClick={() => setShowCreateModal(true)}>
          Create New Project
        </Button>
      </div>

      <Row>
        {filteredProjects &&
          filteredProjects.map((project: Project, index: number) => <ProjectCard key={index} project={project} />)}
      </Row>
      <CreateProjectModal show={showCreateModal} onHide={() => setShowCreateModal(false)} />
    </Container>
  )
}

export default ProjectsPage
